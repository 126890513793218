import {animated, useSpring} from "@react-spring/web";
import TextInput from "../../inputs/textInput";
import {nameValidations} from "../../validations/nameValidation";
import {orgNameValidation} from "../../validations/orgNameValidation";
import {Buttons, Header} from "./contactFormHelper";
import React from "react";

/**
 * This function will render the getting to know you section. This section
 * will handle the following user inputs: User first and last  name, and the
 * organization name
 *
 * @param formActive true if this section is open
 * @param formAnimation the close and open animation
 * @param gettingToKnowYouHandler handler when the user clicks on next
 * @param inputHandler user input handler
 * @param errorAnimation error animation when the user click on next
 * @param error true if there is an error
 * @returns {React.JSX.Element} getting to know you section
 * @constructor
 */
export const GettingToKnowYouRender = (formActive,
                                       formAnimation,
                                       gettingToKnowYouHandler,
                                       inputHandler,
                                       errorAnimation,
                                       error) => (
    <div className={`bg-stone-50 w-[100%] max-w-sm p-2 border border-stone-700 rounded
            flex flex-col gap-7 ${formActive ? 'shadow' : ''}`}>
        {Header('Getting to know you', formActive)}
        <animated.div className='flex-col gap-4'
                      style={useSpring(formAnimation)}>
            <TextInput label={'Your first name'}
                       validations={nameValidations}
                       onComplete={(text) => inputHandler({firstName: text})}/>

            <TextInput label={'Your last name'}
                       validations={nameValidations}
                       onComplete={(text) => inputHandler({lastName: text})}/>

            <TextInput label={'Organisation name'}
                       validations={orgNameValidation}
                       onComplete={(text) => inputHandler({orgName: text})}/>

            {Buttons(gettingToKnowYouHandler,
                'Next',
                error,
                errorAnimation.open,
                errorAnimation.close
            )}
        </animated.div>
    </div>
)