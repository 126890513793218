/**
 * {
 *      year: '',
 *      title: '',
 *      skills: [],
 *      body: ''
 *  }
 *
 * @type {[{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string}]}
 */
export const experienceMentoring = [
    {
        year: '2018',
        title: 'Teaching assistant to first year engineering students at Alfaisal University (Programming for Engineers)',
        skills: ['Java', 'Patience'],
        body: 'Assisted engineering students and helped them to learn and understand JAVA and object-oriented design principles.'
    },
    {
        year: '2021',
        title: 'Coaching new interns - DVLA',
        skills: ['Ruby', 'RoR', 'QA', 'Agile'],
        body: 'Coached new interns in my team/squad. As part of my role as a senior engineer, trained and coached interns in all things related to Ruby, Ruby on Rails and Quality Assurance.'
    },
    {
        year: '2022',
        title: 'Mentoring new joiner engineers - DVLA',
        skills: ['Agile', 'Ruby', 'QA', 'RoR', 'Software engineering'],
        body: "Member of DVLA’s mentorship program. The program lasted for one year and aimed to help new engineers to get up to speed with industrial technologies and Agile environments."
    }

];
