import React from 'react';
import {arrow} from "../icons/arrow";
import {useSpring, animated} from "@react-spring/web";
import PropTypes from "prop-types";
import {balloon} from "../icons/balloon";
import {lineTop} from "../icons/linetop";
import {lineBottom} from "../icons/linebottom";


class CollapsibleMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: 0,
        }

        this.menuHeader = this.menuHeader.bind(this);
        this.menuIcon = this.menuIcon.bind(this);
        this.menuBody = this.menuBody.bind(this);
    }

    /**
     * Render the header and the sub-header of the collapsible
     * menu
     *
     * @returns {JSX.Element} header and the sub-header
     */
    menuHeader = (options) => {
        const {header, subHeader} = options.items;

        return (
            <div className='p-2'>
                <div className='md:text-lg font-bold  text-stone-800 '>
                    {header}
                </div>
                <span className='text-sm  text-sky-600 '>
                {subHeader}
                </span>
            </div>
        )
    }

    /**
     * This function renders the menu icon with arrows. The icons
     * are animated depending on if the menu is selected.
     *
     * @param options should have the ID of the menu
     * @returns {JSX.Element}
     */
    menuIcon = (options) => {
        const {id} = options;

        const menuOpen = {
            from: {backgroundColor: '#EFEFEF', rotate: 0},
            to: {backgroundColor: '#0284C7', rotate: 90},
        }

        const menuClosed = {
            from: {backgroundColor: '#0284C7', rotate: 90},
            to: {backgroundColor: '#EFEFEF', rotate: 0},
        }

        const menuAnimation = this.state.menuOpen === id ? menuOpen : menuClosed

        return (
            <animated.div className='rounded-full bg-[#EFEFEF] w-[2.5rem] h-[2.5rem] border
            border-stone-500 flex justify-center items-center flex-shrink-0'
                          style={{...useSpring(menuAnimation)}}>
                {arrow(this.state.menuOpen !== id)}
            </animated.div>
        )
    }

    /**
     * This function renders the menu body. The body will fit into a gradient (blue and black)
     * background. The text by default it white
     *
     * @returns {JSX.Element}
     */
    menuBody = (options) => {
        const {id} = options;
        const {body} = options.item;

        const menuOpen = {
            from: {display: 'none', y: '-10px'},
            to: {display: `block`, y: '0px'},
        }

        const menuClosed = {
            from: {display: `block`, y: '0px'},
            to: {display: 'none', y: '-10px'},
        }

        const menuAnimation = this.state.menuOpen === id ? menuOpen : menuClosed

        return (
            <animated.div style={{...useSpring(menuAnimation)}}
                          className='md:w-[30vw] md:h-[30vw] w-[80vw] h-[80vw] shadow
                         bg-gradient-to-tl to-sky-900 from-stone-900 rounded
                         max-w-sm max-h-[300px] text-stone-100'>
                <div className="w-full h-full relative flex justify-center items-center">
                    <div className="p-9">
                        {body}
                    </div>

                    <div className="absolute top-0 right-0">
                        {balloon()}
                    </div>

                    <div className='absolute top-0 left-0'>
                        {lineTop()}
                    </div>

                    <div className='absolute bottom-0 right-0'>
                        {lineBottom()}
                    </div>
                </div>

            </animated.div>
        )
    }


    render() {
        const {items} = this.props;

        return (
            <>
                <div className='text-sky-700 font-bold text-lg pb-9 pt-10 pl-[5vw] pr-[5vw]
                    md:pr-[0vw] md:pl-[0vw] flex justify-center'>
                        <span>
                            AWARDS AND RECOMMENDATIONS
                        </span>
                </div>

                <div className='flex flex-row w-full justify-center'>
                    <div className="p-3 pt-7 hidden md:flex">
                        {items.map((item, index) => (
                            <div key={`${index}-body`}>
                                <this.menuBody id={index} item={item}/>
                            </div>
                        ))}
                    </div>

                    <div>
                        {items.map((item, index) => (
                            <div key={index}
                                 onClick={() => this.setState({menuOpen: index})}
                                 className='flex flex-col flex-nowrap w-full'>
                                <div className="flex flex-nowrap items-center gap-3 select-none decoration-sky-200
                                        cursor-pointer rounded p-[1vw] pl-[5vw] pr-[5vw] md:hover:underline
                                        decoration-4 decoration-wavy">
                                    <this.menuIcon id={index}/>
                                    <this.menuHeader items={item}/>
                                </div>
                                <div className="p-3 md:hidden w-full flex h-full items-center justify-center">
                                    <this.menuBody id={index} item={item}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        )
    }

}

CollapsibleMenu.propTypes = {
    items: PropTypes.array
};


export default CollapsibleMenu;