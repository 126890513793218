import React from 'react';
import PropTypes from 'prop-types';

class ClassicButton extends React.Component {
  constructor(props) {
    super(props);
    this.selected_bt = this.selected_bt.bind(this);
    this.unselected_bt = this.unselected_bt.bind(this);
  }

  /**
   * this function will return the selected classic button.
   * The selected classic button will have a blue background
   *
   * @returns {JSX.Element} selected classic button
   */
  selected_bt = () => (
    <button title={`${this.props.text.toLowerCase()} button`}
      className="bg-white rounded p-2 text-sky-600 border border-stone-700
            hover:bg-stone-200"
      type='button'
      onClick={this.props.clickHandler}
    >
      {this.props.text}
    </button>
  );

  /**
   * this function will return the unselected classic button.
   * The selected classic button will not have a background colour
   *
   * @returns {JSX.Element} unselected classic button
   */
  unselected_bt = () => (
    <button title={`${this.props.text.toLowerCase()} button`}
      className="rounded p-2 text-stone-900
            hover:bg-stone-200"
      type='button'
      onClick={this.props.clickHandler}
    >
      {this.props.text}
    </button>
  );

  render() {
    return (
      <>
        {this.props.isSelected ? (
          <this.selected_bt></this.selected_bt>
        ) : (
          <this.unselected_bt></this.unselected_bt>
        )}
      </>
    );
  }
}

ClassicButton.propTypes = {
  text: PropTypes.string,
  isSelected: PropTypes.bool,
  clickHandler: PropTypes.func
};

export default ClassicButton;
