import React from "react";
import {arrow} from "../icons/arrow";

class Footer extends React.Component {

    constructor(props) {
        super(props);

        this.websiteLinks = [
            {text: 'About', link: './'},
            {text: 'CV', link: '/#download-cv'},
            {text: 'Blog', link: '/blog'},
        ]

        this.About = this.About.bind(this);
        this.Links = this.Links.bind(this);
        this.GetInTouch = this.GetInTouch.bind(this);
        this.PageBreaker = this.PageBreaker.bind(this);

    }

    /**
     * This function will render the about section. This section
     * has two paragraphs.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    About = () => (
        <div className='text-stone-200'>
            <div className='text-sky-500'>
                About this website
            </div>
            <div className='flex text-sm gap-5'>
                <p className='max-w-[17rem]'>
                    This website is built by and for Abdullah Janjua.
                    No ads are displayed on this website.
                </p>
                <p className='max-w-[17rem]'>
                    Please note that this website make use of Google Analytics.
                </p>
            </div>
        </div>
    )

    /**
     * This function renders the website map. The sitemap is
     * in the +this.websiteLinks+
     *
     * @note
     * This will be hidden on a small mobile screens
     *
     * @returns {JSX.Element}
     * @constructor
     */
    Links = () => (
        <div className='text-stone-200 hidden md:block'>
            <div className='text-sky-500'>
                Links
            </div>
            <div className="grid grid-rows-2 grid-flow-col gap-3 text-sm">
                {this.websiteLinks.map((link, index) =>
                    <a className='flex flex-nowrap gap-1 items-end' key={index} href={link.link}>
                        {link.text}
                        <span className='text-sky-500 text-[0.7rem]'>
                                0{index}
                            </span>
                    </a>
                )}
            </div>
        </div>
    )

    /**
     * This will render a simple contact me
     * message in the footer
     *
     * @returns {JSX.Element}
     * @constructor
     */
    GetInTouch = () => (
        <div className='text-stone-200'>
            <div className='text-sky-500'>
                Get in touch
            </div>

            <div className='max-w-xs text-sm'>
                You can email me: contact@AbdullahJanjua.com or message me on LinkedIn
            </div>
        </div>
    )

    /**
     * This is a simple function that will render the
     * page breaker for mobile screens
     *
     * @note
     * This will be only displays on a small mobile screens
     *
     * @returns {JSX.Element}
     * @constructor
     */
    PageBreaker = () => (
        <div className='md:hidden w-full grid justify-center pt-5 pb-5'>
            <div className='flex font-bold text-stone-200 gap-3 flex-nowrap'>
                <div className="rotate-90">
                    {arrow(false)}
                </div>
                THANK YOU 💙 /
                <div className="-rotate-90">
                    {arrow(false)}
                </div>
            </div>
        </div>
    )


    render() {
        return (
            <div className='bg-gradient-to-tr from-sky-900 to-stone-900 w-[100vw] p-[5%]
            flex justify-between flex-col md:flex-row gap-5'>
                <this.PageBreaker/>
                <this.About/>
                <this.Links/>
                <this.GetInTouch/>
            </div>
        );
    }
}

export default Footer;