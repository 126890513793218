export const webapp = () => (
    <svg viewBox="0 0 190 122" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[35%] md:w-[30%]'>
        <path d="M190 0.107788H0.0813751V121.74H190V0.107788Z" fill="#8E8E8E"/>
        <path d="M185.19 15.3634H6.13307V114.544H185.19V15.3634Z" fill="#EFEFEF"/>
        <path d="M185.919 0H4C1.79086 0 0 1.82506 0 4.0342C0 6.24334 1.79086 8.06841 4 8.06841H185.919C188.128 8.06841 189.919 6.24334 189.919 4.0342C189.919 1.82506 188.128 0 185.919 0Z" fill="#292323"/>
        <path d="M5.99611 5.62058C6.82207 5.62058 7.49164 4.951 7.49164 4.12505C7.49164 3.29909 6.82207 2.62952 5.99611 2.62952C5.17015 2.62952 4.50058 3.29909 4.50058 4.12505C4.50058 4.951 5.17015 5.62058 5.99611 5.62058Z" fill="#EFEFEF"/>
        <path d="M11.6723 5.62058C12.4982 5.62058 13.1678 4.951 13.1678 4.12505C13.1678 3.29909 12.4982 2.62952 11.6723 2.62952C10.8463 2.62952 10.1768 3.29909 10.1768 4.12505C10.1768 4.951 10.8463 5.62058 11.6723 5.62058Z" fill="#EFEFEF"/>
        <path d="M17.3485 5.62058C18.1744 5.62058 18.844 4.951 18.844 4.12505C18.844 3.29909 18.1744 2.62952 17.3485 2.62952C16.5225 2.62952 15.8529 3.29909 15.8529 4.12505C15.8529 4.951 16.5225 5.62058 17.3485 5.62058Z" fill="#EFEFEF"/>
        <path d="M37.4597 29.2648L25.6156 40.961L37.4597 52.6569L40.1246 49.992L31.1685 41.0346L40.1995 32.0037L37.4597 29.2648Z" fill="#0284C7"/>
        <path d="M47.1643 29.2648L59.0081 40.961L47.1643 52.6569L44.4994 49.992L53.4561 41.0349L44.4245 32.0037L47.1643 29.2648Z" fill="#0284C7"/>
        <path d="M85.4747 32.2C84.506 32.2 83.7208 32.7572 83.7208 33.4443C83.7208 34.1313 84.506 34.6885 85.4747 34.6885H168.16C169.129 34.6885 169.914 34.1313 169.914 33.4443C169.914 32.7572 169.129 32.2 168.16 32.2H85.4747Z" fill="#8E8E8E"/>
        <path d="M167.338 100.37H28.2405C26.8186 100.37 25.6663 99.5489 25.6647 98.5372V73.9869C25.6663 72.9753 26.8186 72.1544 28.2405 72.1544H167.338C168.76 72.1544 169.912 72.9753 169.914 73.9869V98.5369C169.913 99.5486 168.76 100.369 167.338 100.37ZM28.2405 72.8868C27.3873 72.8868 26.6959 73.3794 26.695 73.9863V98.5363C26.695 99.1432 27.3873 99.6352 28.2405 99.6358H167.338C168.191 99.6358 168.883 99.1432 168.884 98.5363V73.9869C168.884 73.38 168.191 72.8881 167.338 72.8874L28.2405 72.8868Z" fill="#8E8E8E"/>
        <path d="M42.5644 92.4747C46.0013 92.4747 48.7875 89.6885 48.7875 86.2515C48.7875 82.8146 46.0013 80.0284 42.5644 80.0284C39.1274 80.0284 36.3412 82.8146 36.3412 86.2515C36.3412 89.6885 39.1274 92.4747 42.5644 92.4747Z" fill="#8E8E8E"/>
        <path d="M60.1163 81.2745C59.7863 81.2741 59.4696 81.4048 59.236 81.6378C59.0023 81.8709 58.8708 82.1872 58.8704 82.5172C58.87 82.8472 59.0006 83.1638 59.2337 83.3975C59.4667 83.6311 59.783 83.7626 60.113 83.763H118.776C119.106 83.763 119.423 83.632 119.656 83.3986C119.889 83.1653 120.02 82.8488 120.02 82.5188C120.02 82.1888 119.889 81.8723 119.656 81.639C119.423 81.4056 119.106 81.2745 118.776 81.2745H60.1163Z" fill="#0284C7"/>
        <path d="M60.1163 88.7404C59.9529 88.7401 59.791 88.7721 59.64 88.8345C59.4889 88.8968 59.3517 88.9883 59.236 89.1036C59.0023 89.3367 58.8708 89.653 58.8704 89.983C58.87 90.313 59.0006 90.6296 59.2337 90.8633C59.4667 91.0969 59.783 91.2284 60.113 91.2289H85.3573C85.6873 91.2293 86.004 91.0986 86.2376 90.8656C86.4713 90.6325 86.6028 90.3162 86.6032 89.9862C86.6036 89.6562 86.4729 89.3396 86.2399 89.1059C86.0069 88.8723 85.6905 88.7408 85.3605 88.7404H60.1163Z" fill="#0284C7"/>
        <path d="M84.8709 39.6544C84.5582 39.6796 84.2665 39.8216 84.0538 40.0521C83.8411 40.2827 83.723 40.5849 83.723 40.8986C83.723 41.2123 83.8411 41.5145 84.0538 41.7451C84.2665 41.9756 84.5582 42.1177 84.8709 42.1429H139.061C139.374 42.1177 139.665 41.9756 139.878 41.7451C140.091 41.5145 140.209 41.2123 140.209 40.8986C140.209 40.5849 140.091 40.2827 139.878 40.0521C139.665 39.8216 139.374 39.6796 139.061 39.6544H84.8709Z" fill="#8E8E8E"/>
        <path d="M85.2275 47.1087C84.3954 47.1087 83.7208 47.6659 83.7208 48.353C83.7208 49.04 84.3954 49.5972 85.2275 49.5972H156.261C157.093 49.5972 157.768 49.04 157.768 48.353C157.768 47.6659 157.093 47.1087 156.261 47.1087H85.2275Z" fill="#8E8E8E"/>
    </svg>


);