export const marketing = () => (
    <svg viewBox="0 0 160 104" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[30%] md:w-[25%]'>
        <path d="M144.618 0H4.53998C3.3365 0.00191462 2.18285 0.480845 1.33185 1.33184C0.480854 2.18283 0.00192138 3.33648 0 4.53996V99.0651C0.00115671 100.269 0.479848 101.423 1.33101 102.274C2.18217 103.125 3.33626 103.604 4.53998 103.605H144.618C145.822 103.604 146.976 103.125 147.827 102.274C148.678 101.423 149.157 100.269 149.158 99.0651V4.53996C149.156 3.33648 148.677 2.18283 147.826 1.33184C146.975 0.480845 145.821 0.00191387 144.618 0Z" fill="#231C1C"/>
        <path d="M144.166 3H6.42797C5.78359 3.0014 5.16608 3.25835 4.7109 3.71447C4.25573 4.17059 4.00006 4.78864 4 5.43303V97.6152C4.00111 98.2588 4.25726 98.8757 4.71236 99.3308C5.16745 99.7859 5.78437 100.042 6.42797 100.043H144.166C144.81 100.042 145.427 99.7859 145.882 99.3308C146.337 98.8757 146.593 98.2588 146.594 97.6152V5.43303C146.594 4.78864 146.338 4.17059 145.883 3.71447C145.428 3.25835 144.811 3.0014 144.166 3Z" fill="#EFEFEF"/>
        <path d="M33.5479 83.6705C33.5479 83.832 33.5435 83.9935 33.535 84.1506C33.4111 86.5146 32.3848 88.7409 30.6677 90.3703C28.9506 91.9998 26.6736 92.9082 24.3064 92.9082C21.9392 92.9082 19.6623 91.9998 17.9452 90.3703C16.2281 88.7409 15.2017 86.5146 15.0779 84.1506C15.0694 83.9935 15.065 83.832 15.065 83.6705C15.065 81.2195 16.0386 78.869 17.7717 77.1359C19.5048 75.4028 21.8553 74.4292 24.3063 74.4292C26.7572 74.4292 29.1077 75.4028 30.8408 77.1359C32.5739 78.869 33.5475 81.2195 33.5475 83.6705H33.5479Z" fill="#0284C7"/>
        <path d="M118.26 77.5084H45.6472C45.2486 77.5235 44.8715 77.6924 44.5948 77.9797C44.3182 78.267 44.1637 78.6503 44.1637 79.0491C44.1637 79.4479 44.3182 79.8312 44.5948 80.1184C44.8715 80.4057 45.2486 80.5746 45.6472 80.5897H118.26C118.668 80.6044 119.065 80.4565 119.364 80.1786C119.664 79.9006 119.84 79.5153 119.856 79.1071C119.871 78.699 119.724 78.3015 119.447 78.0017C119.169 77.702 118.784 77.5246 118.376 77.5084C118.337 77.507 118.299 77.507 118.26 77.5084Z" fill="#0284C7"/>
        <path d="M76.8917 86.7515H45.6469C45.2389 86.7515 44.8476 86.9135 44.5591 87.2021C44.2705 87.4906 44.1085 87.8819 44.1085 88.2899C44.1085 88.6979 44.2705 89.0892 44.5591 89.3777C44.8476 89.6662 45.2389 89.8283 45.6469 89.8283H76.8917C77.2997 89.829 77.6913 89.6676 77.9803 89.3796C78.2693 89.0915 78.432 88.7005 78.4327 88.2925C78.4334 87.8845 78.272 87.4929 77.9839 87.2039C77.6959 86.9149 77.3049 86.7521 76.8968 86.7515H76.8917Z" fill="#808080"/>
        <path d="M156.885 66.0791H34.3739C33.6878 66.0783 33.0301 65.8055 32.5451 65.3204C32.06 64.8353 31.7871 64.1776 31.7863 63.4916V9.72671C31.7871 9.04069 32.06 8.38299 32.5451 7.89789C33.0301 7.4128 33.6878 7.13994 34.3739 7.13916H156.883C157.569 7.13994 158.227 7.4128 158.712 7.89789C159.197 8.38299 159.47 9.04069 159.471 9.72671V63.4901C159.471 64.1761 159.198 64.834 158.713 65.3194C158.228 65.8047 157.571 66.078 156.885 66.0791Z" fill="#0284C7"/>
        <path d="M59.3249 26.1978C58.9164 26.1799 58.5175 26.3251 58.216 26.6013C57.9146 26.8776 57.7352 27.2622 57.7174 27.6708C57.6995 28.0793 57.8447 28.4781 58.1209 28.7796C58.3971 29.0811 58.7818 29.2605 59.1903 29.2783C59.2352 29.2803 59.28 29.2803 59.3249 29.2783H131.936C132.333 29.261 132.708 29.0911 132.982 28.8041C133.257 28.5172 133.411 28.1353 133.411 27.738C133.411 27.3408 133.257 26.9589 132.982 26.6719C132.708 26.3849 132.333 26.2151 131.936 26.1978H59.3249Z" fill="white"/>
        <path d="M59.3249 35.1226C58.9164 35.1047 58.5175 35.2499 58.216 35.5261C57.9146 35.8024 57.7352 36.1871 57.7174 36.5956C57.6995 37.0041 57.8447 37.4029 58.1209 37.7044C58.3971 38.0059 58.7818 38.1853 59.1903 38.2031C59.2352 38.2051 59.28 38.2051 59.3249 38.2031H131.936C132.333 38.1858 132.708 38.0159 132.982 37.729C133.257 37.442 133.411 37.0601 133.411 36.6628C133.411 36.2656 133.257 35.8837 132.982 35.5967C132.708 35.3097 132.333 35.1399 131.936 35.1226H59.3249Z" fill="white"/>
        <path d="M59.3248 43.9387C58.9279 43.9561 58.5531 44.1259 58.2784 44.4129C58.0037 44.6998 57.8503 45.0818 57.8503 45.479C57.8503 45.8762 58.0037 46.2582 58.2784 46.5451C58.5531 46.8321 58.9279 47.0019 59.3248 47.0193H90.5696C90.7719 47.0281 90.9739 46.997 91.1642 46.9278C91.3544 46.8585 91.5292 46.7525 91.6785 46.6157C91.8277 46.4789 91.9486 46.3141 92.0342 46.1306C92.1197 45.9471 92.1683 45.7485 92.1772 45.5463C92.186 45.344 92.1549 45.142 92.0857 44.9517C92.0164 44.7615 91.9104 44.5867 91.7736 44.4374C91.6368 44.2882 91.472 44.1673 91.2885 44.0817C91.105 43.9961 90.9065 43.9476 90.7042 43.9387C90.6594 43.9368 90.6145 43.9368 90.5696 43.9387H59.3248Z" fill="white"/>
    </svg>

);