import React from 'react';
import {arrow} from '../icons/arrow';
import PropTypes from 'prop-types';

class CircleButton extends React.Component {
    constructor(props) {
        super(props);

        this.selected_bt = this.selected_bt.bind(this);
        this.unselected_bt = this.unselected_bt.bind(this);
    }

    /**
     * this function will return the unselected circle button.
     * The unselected circle button will have a blue background
     *
     * @returns {JSX.Element} unselected circle button
     */
    unselected_bt = () => (
        <button title={this.props.title}
                className="rounded-full bg-sky-600 h-9 w-9 hover:bg-sky-700
        grid place-content-center border border-stone-700"
                onClick={this.props.onClick}
        >
            {arrow(false)}
        </button>
    );

    /**
     * this function will return the selected circle button.
     * The selected circle button will have a stone background
     *
     * @returns {JSX.Element} selected circle button
     */
    selected_bt = () => (
        <button title={this.props.title}
                className="rounded-full bg-stone-100 h-9 w-9 hover:bg-stone-200
        grid place-content-center border border-stone-700 rotate-180"
                onClick={this.props.onClick}
        >
            {arrow(true)}
        </button>
    );

    render() {
        return (
            <>
                {this.props.isSelected ? (
                    <this.selected_bt></this.selected_bt>
                ) : (
                    <this.unselected_bt></this.unselected_bt>
                )}
            </>
        );
    }
}

CircleButton.propTypes = {
    title: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
};

export default CircleButton;
