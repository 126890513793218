export const quality = () => (
    <svg viewBox="0 0 199 131" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[40%] md:w-[35%]'>
        <path d="M105.556 0H5C2.23858 0 0 2.23858 0 5V52.967C0 55.7284 2.23857 57.967 5 57.967H105.556C108.317 57.967 110.556 55.7284 110.556 52.967V5C110.556 2.23858 108.317 0 105.556 0Z" fill="#231C1C"/>
        <path d="M108.763 9.56157H2.3904V54.979H108.763V9.56157Z" fill="#EFEFEF"/>
        <path d="M10.458 4.18317C10.953 4.18317 11.3544 3.78184 11.3544 3.28678C11.3544 2.79171 10.953 2.39038 10.458 2.39038C9.9629 2.39038 9.56157 2.79171 9.56157 3.28678C9.56157 3.78184 9.9629 4.18317 10.458 4.18317Z" fill="#EFEFEF"/>
        <path d="M14.6411 4.18317C15.1362 4.18317 15.5375 3.78184 15.5375 3.28678C15.5375 2.79171 15.1362 2.39038 14.6411 2.39038C14.1461 2.39038 13.7447 2.79171 13.7447 3.28678C13.7447 3.78184 14.1461 4.18317 14.6411 4.18317Z" fill="#EFEFEF"/>
        <path d="M5.97597 4.18317C6.63606 4.18317 7.17117 3.78184 7.17117 3.28678C7.17117 2.79171 6.63606 2.39038 5.97597 2.39038C5.31589 2.39038 4.78078 2.79171 4.78078 3.28678C4.78078 3.78184 5.31589 4.18317 5.97597 4.18317Z" fill="#EFEFEF"/>
        <path d="M20.6171 44.2222C21.7723 44.2222 22.7087 43.2858 22.7087 42.1306C22.7087 40.9755 21.7723 40.039 20.6171 40.039C19.462 40.039 18.5255 40.9755 18.5255 42.1306C18.5255 43.2858 19.462 44.2222 20.6171 44.2222Z" fill="#8E8E8E"/>
        <path d="M37.9475 38.8439C39.1026 38.8439 40.039 38.0412 40.039 37.0511C40.039 36.0609 39.1026 35.2583 37.9475 35.2583C36.7923 35.2583 35.8559 36.0609 35.8559 37.0511C35.8559 38.0412 36.7923 38.8439 37.9475 38.8439Z" fill="#8E8E8E"/>
        <path d="M55.2778 44.2222C56.4329 44.2222 57.3694 43.2858 57.3694 42.1306C57.3694 40.9755 56.4329 40.039 55.2778 40.039C54.1226 40.039 53.1862 40.9755 53.1862 42.1306C53.1862 43.2858 54.1226 44.2222 55.2778 44.2222Z" fill="#8E8E8E"/>
        <path d="M72.6081 17.9279C73.7633 17.9279 74.6997 17.1253 74.6997 16.1351C74.6997 15.145 73.7633 14.3423 72.6081 14.3423C71.453 14.3423 70.5165 15.145 70.5165 16.1351C70.5165 17.1253 71.453 17.9279 72.6081 17.9279Z" fill="#8E8E8E"/>
        <path d="M89.9384 17.9279C91.0936 17.9279 92.03 16.9915 92.03 15.8363C92.03 14.6812 91.0936 13.7447 89.9384 13.7447C88.7833 13.7447 87.8468 14.6812 87.8468 15.8363C87.8468 16.9915 88.7833 17.9279 89.9384 17.9279Z" fill="#8E8E8E"/>
        <path d="M55.0568 42.4294L37.7645 37.1124L20.7088 42.2543L20.3183 41.7387L37.7645 36.3075L54.9747 41.5635L72.0765 16.1929L72.1454 16.1651L89.3964 16.1351L89.6396 16.7329L72.4571 16.7356L55.0568 42.4294Z" fill="#8E8E8E"/>
        <path d="M124.081 76.4925H30.0991C27.3377 76.4925 25.0991 78.7311 25.0991 81.4925V125.874C25.0991 128.635 27.3377 130.874 30.0991 130.874H124.081C126.843 130.874 129.081 128.635 129.081 125.874V81.4925C129.081 78.7311 126.843 76.4925 124.081 76.4925Z" fill="#231C1C"/>
        <path d="M127.288 84.8589H27.4895V127.886H127.288V84.8589Z" fill="#EFEFEF"/>
        <path d="M34.9595 80.0781C35.4545 80.0781 35.8559 79.6768 35.8559 79.1817C35.8559 78.6866 35.4545 78.2853 34.9595 78.2853C34.4644 78.2853 34.0631 78.6866 34.0631 79.1817C34.0631 79.6768 34.4644 80.0781 34.9595 80.0781Z" fill="#EFEFEF"/>
        <path d="M38.8438 80.0781C39.5039 80.0781 40.039 79.6768 40.039 79.1817C40.039 78.6866 39.5039 78.2853 38.8438 78.2853C38.1838 78.2853 37.6487 78.6866 37.6487 79.1817C37.6487 79.6768 38.1838 80.0781 38.8438 80.0781Z" fill="#EFEFEF"/>
        <path d="M30.7763 80.0781C31.2713 80.0781 31.6727 79.6768 31.6727 79.1817C31.6727 78.6866 31.2713 78.2853 30.7763 78.2853C30.2812 78.2853 29.8799 78.6866 29.8799 79.1817C29.8799 79.6768 30.2812 80.0781 30.7763 80.0781Z" fill="#EFEFEF"/>
        <path d="M194 23.9039H80.2973C77.5359 23.9039 75.2973 26.1425 75.2973 28.9039V84.042C75.2973 86.8035 77.5359 89.0421 80.2973 89.0421H194C196.761 89.0421 199 86.8035 199 84.0421V28.9039C199 26.1425 196.761 23.9039 194 23.9039Z" fill="#0284C7"/>
        <path d="M196.012 33.4655H78.2853V85.4565H196.012V33.4655Z" fill="#EFEFEF"/>
        <path d="M86.6517 28.6847C87.3117 28.6847 87.8468 28.1496 87.8468 27.4895C87.8468 26.8294 87.3117 26.2943 86.6517 26.2943C85.9916 26.2943 85.4565 26.8294 85.4565 27.4895C85.4565 28.1496 85.9916 28.6847 86.6517 28.6847Z" fill="#EFEFEF"/>
        <path d="M91.4324 28.6847C92.0925 28.6847 92.6276 28.1496 92.6276 27.4895C92.6276 26.8294 92.0925 26.2943 91.4324 26.2943C90.7723 26.2943 90.2372 26.8294 90.2372 27.4895C90.2372 28.1496 90.7723 28.6847 91.4324 28.6847Z" fill="#EFEFEF"/>
        <path d="M82.1697 28.6847C82.9948 28.6847 83.6637 28.1496 83.6637 27.4895C83.6637 26.8294 82.9948 26.2943 82.1697 26.2943C81.3446 26.2943 80.6757 26.8294 80.6757 27.4895C80.6757 28.1496 81.3446 28.6847 82.1697 28.6847Z" fill="#EFEFEF"/>
        <path d="M137.738 44.8198C137.492 44.8582 137.266 45.0006 137.103 45.2205C136.94 45.4404 136.85 45.7227 136.85 46.015C136.85 46.3073 136.94 46.5897 137.103 46.8095C137.266 47.0294 137.492 47.1718 137.738 47.2102H179.587C179.832 47.1718 180.058 47.0294 180.221 46.8095C180.384 46.5897 180.474 46.3073 180.474 46.015C180.474 45.7227 180.384 45.4404 180.221 45.2205C180.058 45.0006 179.832 44.8582 179.587 44.8198H137.738Z" fill="#808080"/>
        <path d="M137.738 68.7237C137.492 68.7622 137.266 68.9046 137.103 69.1244C136.94 69.3443 136.85 69.6266 136.85 69.9189C136.85 70.2112 136.94 70.4936 137.103 70.7134C137.266 70.9333 137.492 71.0757 137.738 71.1141H179.587C179.832 71.0757 180.058 70.9333 180.221 70.7134C180.384 70.4936 180.474 70.2112 180.474 69.9189C180.474 69.6266 180.384 69.3443 180.221 69.1244C180.058 68.9046 179.832 68.7622 179.587 68.7237H137.738Z" fill="#808080"/>
        <path d="M137.735 50.7958C137.49 50.8342 137.265 50.9766 137.102 51.1965C136.94 51.4163 136.85 51.6987 136.85 51.991C136.85 52.2833 136.94 52.5657 137.102 52.7855C137.265 53.0054 137.49 53.1478 137.735 53.1862H155.686C155.931 53.1478 156.155 53.0054 156.318 52.7855C156.481 52.5657 156.571 52.2833 156.571 51.991C156.571 51.6987 156.481 51.4163 156.318 51.1965C156.155 50.9766 155.931 50.8342 155.686 50.7958H137.735Z" fill="#808080"/>
        <path d="M137.738 56.7718C137.492 56.8102 137.266 56.9526 137.103 57.1725C136.94 57.3923 136.85 57.6747 136.85 57.967C136.85 58.2593 136.94 58.5416 137.103 58.7615C137.266 58.9813 137.492 59.1237 137.738 59.1622H179.587C179.832 59.1237 180.058 58.9813 180.221 58.7615C180.384 58.5416 180.474 58.2593 180.474 57.967C180.474 57.6747 180.384 57.3923 180.221 57.1725C180.058 56.9526 179.832 56.8102 179.587 56.7718H137.738Z" fill="#808080"/>
        <path d="M137.735 62.7477C137.49 62.7862 137.265 62.9286 137.102 63.1484C136.94 63.3683 136.85 63.6506 136.85 63.9429C136.85 64.2352 136.94 64.5176 137.102 64.7374C137.265 64.9573 137.49 65.0997 137.735 65.1381H155.686C155.931 65.0997 156.155 64.9573 156.318 64.7374C156.481 64.5176 156.571 64.2352 156.571 63.9429C156.571 63.6506 156.481 63.3683 156.318 63.1484C156.155 62.9286 155.931 62.7862 155.686 62.7477H137.735Z" fill="#808080"/>
        <path d="M113.201 69.9189C113.324 69.9192 113.446 69.8962 113.56 69.8513C113.674 69.8064 113.778 69.7405 113.865 69.6573C113.953 69.574 114.022 69.4752 114.069 69.3663C114.117 69.2575 114.141 69.1408 114.141 69.023C114.141 68.9051 114.117 68.7884 114.07 68.6795C114.023 68.5706 113.953 68.4717 113.866 68.3884C113.779 68.3051 113.675 68.2391 113.561 68.1941C113.447 68.1491 113.325 68.126 113.202 68.1261H94.1657C94.0423 68.1259 93.9201 68.1488 93.8061 68.1937C93.692 68.2386 93.5883 68.3046 93.501 68.3878C93.4136 68.471 93.3443 68.5699 93.297 68.6787C93.2497 68.7876 93.2253 68.9042 93.2252 69.0221C93.2252 69.1399 93.2494 69.2566 93.2966 69.3655C93.3438 69.4744 93.4131 69.5733 93.5003 69.6566C93.5876 69.7399 93.6912 69.806 93.8052 69.851C93.9192 69.896 94.0414 69.9191 94.1648 69.9189H113.201Z" fill="#8E8E8E"/>
        <path d="M113.204 63.9429C113.453 63.9425 113.691 63.8479 113.867 63.6798C114.042 63.5117 114.141 63.284 114.141 63.0465C114.141 62.8091 114.042 62.5813 113.867 62.4133C113.691 62.2452 113.453 62.1506 113.204 62.1501H94.1659C94.0425 62.1499 93.9203 62.1729 93.8062 62.2178C93.6921 62.2627 93.5884 62.3286 93.501 62.4118C93.4137 62.495 93.3443 62.5939 93.297 62.7027C93.2497 62.8116 93.2253 62.9283 93.2252 63.0461C93.2252 63.164 93.2494 63.2807 93.2967 63.3895C93.3439 63.4984 93.4131 63.5973 93.5004 63.6806C93.5877 63.7639 93.6913 63.83 93.8053 63.875C93.9194 63.92 94.0416 63.9431 94.165 63.9429H113.204Z" fill="#8E8E8E"/>
        <path d="M103.683 46.015C104.924 46.015 106.137 46.3655 107.169 47.0221C108.201 47.6788 109.005 48.6121 109.48 49.7041C109.955 50.796 110.08 51.9976 109.837 53.1568C109.595 54.3161 108.998 55.3809 108.12 56.2166C107.243 57.0524 106.125 57.6215 104.907 57.8521C103.69 58.0827 102.428 57.9644 101.282 57.5121C100.135 57.0598 99.1554 56.2938 98.4659 55.3111C97.7764 54.3283 97.4084 53.1729 97.4084 51.991C97.4103 50.4066 98.072 48.8876 99.2483 47.7673C100.425 46.647 102.02 46.0168 103.683 46.015Z" fill="#0284C7"/>
        <path d="M54.979 99.7988C56.2992 99.7988 57.3694 98.7286 57.3694 97.4084C57.3694 96.0882 56.2992 95.018 54.979 95.018C53.6588 95.018 52.5886 96.0882 52.5886 97.4084C52.5886 98.7286 53.6588 99.7988 54.979 99.7988Z" fill="#8E8E8E"/>
        <path d="M92.9926 95.4295C93.2064 95.4292 93.4183 95.4724 93.6159 95.5567C93.8136 95.641 93.9932 95.7647 94.1445 95.9207C94.2959 96.0768 94.4159 96.2621 94.4978 96.4661C94.5797 96.6701 94.6219 96.8888 94.6219 97.1096C94.6219 97.3304 94.5797 97.5491 94.4978 97.7531C94.4159 97.9571 94.2959 98.1424 94.1445 98.2985C93.9932 98.4545 93.8136 98.5782 93.6159 98.6625C93.4183 98.7468 93.2064 98.79 92.9926 98.7897H67.7612C67.5473 98.79 67.3355 98.7468 67.1379 98.6625C66.9402 98.5782 66.7606 98.4545 66.6092 98.2985C66.4579 98.1424 66.3379 97.9571 66.2559 97.7531C66.174 97.5491 66.1319 97.3304 66.1319 97.1096C66.1319 96.8888 66.174 96.6701 66.2559 96.4661C66.3379 96.2621 66.4579 96.0768 66.6092 95.9207C66.7606 95.7647 66.9402 95.641 67.1379 95.5567C67.3355 95.4724 67.5473 95.4292 67.7612 95.4295H92.9926ZM92.9926 95.018H67.7612C67.224 95.018 66.7088 95.2384 66.329 95.6306C65.9491 96.0229 65.7357 96.5549 65.7357 97.1096C65.7357 97.6643 65.9491 98.1963 66.329 98.5886C66.7088 98.9808 67.224 99.2012 67.7612 99.2012H92.9926C93.5298 99.2012 94.0449 98.9808 94.4248 98.5886C94.8046 98.1963 95.018 97.6643 95.018 97.1096C95.018 96.5549 94.8046 96.0229 94.4248 95.6306C94.0449 95.2384 93.5298 95.018 92.9926 95.018Z" fill="#8E8E8E"/>
        <path d="M82.863 99.2012H64.146C63.6167 99.2012 63.109 98.9808 62.7347 98.5886C62.3604 98.1963 62.1501 97.6643 62.1501 97.1096C62.1501 96.5549 62.3604 96.0229 62.7347 95.6306C63.109 95.2384 63.6167 95.018 64.146 95.018H82.863C83.3923 95.018 83.9 95.2384 84.2743 95.6306C84.6486 96.0229 84.8589 96.5549 84.8589 97.1096C84.8589 97.6643 84.6486 98.1963 84.2743 98.5886C83.9 98.9808 83.3923 99.2012 82.863 99.2012Z" fill="#8E8E8E"/>
        <path d="M54.979 109.36C56.2992 109.36 57.3694 108.29 57.3694 106.97C57.3694 105.65 56.2992 104.58 54.979 104.58C53.6588 104.58 52.5886 105.65 52.5886 106.97C52.5886 108.29 53.6588 109.36 54.979 109.36Z" fill="#8E8E8E"/>
        <path d="M92.9926 105.53C93.2064 105.53 93.4183 105.567 93.6159 105.639C93.8136 105.711 93.9932 105.817 94.1445 105.951C94.2959 106.085 94.4159 106.244 94.4978 106.418C94.5797 106.593 94.6219 106.781 94.6219 106.97C94.6219 107.159 94.5797 107.347 94.4978 107.522C94.4159 107.696 94.2959 107.855 94.1445 107.989C93.9932 108.123 93.8136 108.229 93.6159 108.301C93.4183 108.373 93.2064 108.41 92.9926 108.41H67.7612C67.5473 108.41 67.3355 108.373 67.1379 108.301C66.9402 108.229 66.7606 108.123 66.6092 107.989C66.4579 107.855 66.3379 107.696 66.2559 107.522C66.174 107.347 66.1319 107.159 66.1319 106.97C66.1319 106.781 66.174 106.593 66.2559 106.418C66.3379 106.244 66.4579 106.085 66.6092 105.951C66.7606 105.817 66.9402 105.711 67.1379 105.639C67.3355 105.567 67.5473 105.53 67.7612 105.53H92.9926ZM92.9926 105.177H67.7612C67.224 105.177 66.7088 105.366 66.329 105.702C65.9491 106.039 65.7357 106.495 65.7357 106.97C65.7357 107.445 65.9491 107.901 66.329 108.238C66.7088 108.574 67.224 108.763 67.7612 108.763H92.9926C93.5298 108.763 94.0449 108.574 94.4248 108.238C94.8046 107.901 95.018 107.445 95.018 106.97C95.018 106.495 94.8046 106.039 94.4248 105.702C94.0449 105.366 93.5298 105.177 92.9926 105.177Z" fill="#8E8E8E"/>
        <path d="M90.0296 108.763H64.1531C63.6219 108.763 63.1124 108.574 62.7368 108.238C62.3612 107.901 62.1501 107.445 62.1501 106.97C62.1501 106.494 62.3612 106.039 62.7368 105.702C63.1124 105.366 63.6219 105.177 64.1531 105.177H90.0296C90.5604 105.178 91.0692 105.367 91.4443 105.703C91.8194 106.039 92.03 106.495 92.03 106.97C92.03 107.445 91.8194 107.901 91.4443 108.237C91.0692 108.573 90.5604 108.762 90.0296 108.763Z" fill="#8E8E8E"/>
        <path d="M54.979 119.52C56.2992 119.52 57.3694 118.449 57.3694 117.129C57.3694 115.809 56.2992 114.739 54.979 114.739C53.6588 114.739 52.5886 115.809 52.5886 117.129C52.5886 118.449 53.6588 119.52 54.979 119.52Z" fill="#8E8E8E"/>
        <path d="M92.9926 115.15C93.4241 115.15 93.8379 115.327 94.143 115.642C94.4482 115.957 94.6196 116.385 94.6196 116.83C94.6196 117.276 94.4482 117.703 94.143 118.018C93.8379 118.333 93.4241 118.51 92.9926 118.51H67.7612C67.3297 118.51 66.9159 118.333 66.6107 118.018C66.3056 117.703 66.1342 117.276 66.1342 116.83C66.1342 116.385 66.3056 115.957 66.6107 115.642C66.9159 115.327 67.3297 115.15 67.7612 115.15H92.9926ZM92.9926 114.739H67.7612C67.224 114.739 66.7088 114.959 66.329 115.351C65.9491 115.744 65.7357 116.276 65.7357 116.83C65.7357 117.385 65.9491 117.917 66.329 118.309C66.7088 118.702 67.224 118.922 67.7612 118.922H92.9926C93.5298 118.922 94.0449 118.702 94.4248 118.309C94.8046 117.917 95.018 117.385 95.018 116.83C95.018 116.276 94.8046 115.744 94.4248 115.351C94.0449 114.959 93.5298 114.739 92.9926 114.739Z" fill="#8E8E8E"/>
        <path d="M93.0062 118.922H64.162C63.6284 118.922 63.1167 118.702 62.7394 118.309C62.3621 117.917 62.1501 117.385 62.1501 116.83C62.1501 116.276 62.3621 115.744 62.7394 115.351C63.1167 114.959 63.6284 114.739 64.162 114.739H93.0062C93.5398 114.739 94.0515 114.959 94.4288 115.351C94.8061 115.744 95.018 116.276 95.018 116.83C95.018 117.385 94.8061 117.917 94.4288 118.309C94.0515 118.702 93.5398 118.922 93.0062 118.922Z" fill="#8E8E8E"/>
    </svg>

);