import React from 'react';

import {GettingToKnowYouRender} from "./gettingToKnowYou"
import {ContactInfoRender} from "./contactInfo";
import {YourMessageRender} from "./yourMessage";
import {ConformationRender} from "./conformation";
import {contactFormSubmissionHelper} from "../../helpers/contactFormSubmissionHelper";

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.formAnimationOpen = {from: {display: 'none', y: '-20px'}, to: {display: 'flex', y: '0px'}};
        this.formAnimationClosed = {to: {display: 'none', y: '-20px'}, from: {display: 'flex', y: '0px'}};

        this.errorAnimationOpen = {from: {display: 'none', y: '-5px'}, to: {display: 'block', y: '0px'}};
        this.errorAnimationClosed = {to: {display: 'none', y: '-5px'}, from: {display: 'block', y: '0px'}};

        this.steps = ['gettingToKnowYou', 'contactInfo', 'yourMessage', 'conformation'];

        this.state = {
            error: false,
            currentStep: this.steps[0],
            firstName: null,
            lastName: null,
            orgName: null,
            email: null,
            phoneNumber: null,
            message: null,
            formResponse: null
        }

    }

    handleSubmit = (event) =>{
        const {formResponse} = this.state

        if (formResponse === null) contactFormSubmissionHelper(this.state).then(response => {
            if (response) this.setState({formResponse: (response.status === 200)});
        });

        event.preventDefault();
    }


    /**
     * This function will render getting to know you section of the page
     *
     * @returns {React.JSX.Element}
     */
    gettingToKnowYou = () => {
        const {firstName, lastName, orgName, currentStep, error} = this.state
        const formActive = currentStep === this.steps[0]
        const formAnimation = formActive ? this.formAnimationOpen : this.formAnimationClosed;
        const errorAnimation = {open: this.errorAnimationOpen, close: this.errorAnimationClosed}
        const inputHandler = (input) => this.setState(input)
        const gettingToKnowYouHandler = () => {
            let formError = formActive && (!firstName || !lastName || !orgName)
            this.setState({currentStep: this.steps[formError ? 0 : 1], error: formError})
        }

        return (
            GettingToKnowYouRender(formActive,
                formAnimation,
                gettingToKnowYouHandler,
                inputHandler,
                errorAnimation,
                error)
        )
    }

    /**
     * This function will render contact information section on the page
     *
     * @returns {React.JSX.Element}
     */
    contactInfo = () => {
        const {email, phoneNumber, currentStep, error} = this.state
        const formActive = currentStep === this.steps[1]
        const formAnimation = formActive ? this.formAnimationOpen : this.formAnimationClosed;
        const errorAnimation = {open: this.errorAnimationOpen, close: this.errorAnimationClosed}

        const inputHandler = (input) => this.setState(input)
        const contactInfoHandler = () => {
            let formError = formActive && (!email || !phoneNumber)
            this.setState({currentStep: this.steps[formError ? 1 : 2], error: formError})
        }

        return (
            ContactInfoRender(formActive,
                formAnimation,
                contactInfoHandler,
                inputHandler,
                errorAnimation,
                error)
        )
    }

    /**
     * This function will render your message section on the page
     *
     * @returns {React.JSX.Element}
     */
    yourMessage = () => {
        const {message, currentStep, error} = this.state
        const formActive = currentStep === this.steps[2]
        const formAnimation = formActive ? this.formAnimationOpen : this.formAnimationClosed;
        const errorAnimation = {open: this.errorAnimationOpen, close: this.errorAnimationClosed}

        const inputHandler = (input) => this.setState(input)
        const yourMessageHandler = () => {
            let formError = formActive && (!message)
            this.setState({currentStep: this.steps[formError ? 2 : 3], error: formError})
        }

        return (
            YourMessageRender(
                formActive,
                formAnimation,
                yourMessageHandler,
                inputHandler,
                errorAnimation,
                error
            )
        )
    }

    /**
     * This function will render conformation section on the page
     *
     * @returns {React.JSX.Element}
     */
    conformation = () => {
        const {currentStep, formResponse} = this.state
        const formActive = currentStep === this.steps[3]
        const formAnimation = formActive ? this.formAnimationOpen : this.formAnimationClosed;


        return (
            ConformationRender(
                formActive,
                formAnimation,
                formResponse
            )
        )
    }

    render() {
        return (
            <form className='p-2 flex flex-col flex-nowrap gap-2'
                  onSubmit={this.handleSubmit}>
                <this.gettingToKnowYou/>
                <this.contactInfo/>
                <this.yourMessage/>
                <this.conformation/>
            </form>
        )
    }

}

export default Contact;