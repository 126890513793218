import React from 'react';
import ClassicButton from '../buttons/classicButton';
import CornerMenu from "../menu/cornerMenu";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.header_bt = this.header_bt.bind(this);
        this.header_menu = this.header_menu.bind(this);
    }

    /**
     * this function will render the header buttons: blog,
     * about and contact. Note that about button will be
     * selected by default
     *
     * @returns {JSX.Element}
     */
    header_bt = () => (
        <div className="flex gap-1">
            <ClassicButton text={'BLOG'}
                           isSelected={false}
                           clickHandler={() => {
                               window
                                   .open("https://blog.abdullahjanjua.com/", "_self");
                           }}/>
            <ClassicButton text={'ABOUT'} isSelected={true}/>
            <ClassicButton text={'CONTACT'}
                           isSelected={false}
                           clickHandler={() => {
                               document
                                   .getElementById('contact-me')
                                   .scrollIntoView({
                                       behavior: 'smooth'
                                   })
                           }}/>
        </div>
    );

    /**
     * This function will render the top bar menu
     *
     * @returns {JSX.Element}
     */
    header_menu = () => <CornerMenu></CornerMenu>;

    render() {
        return (
            <div
                className="flex justify-between w-full p-3 z-40
            lg:pl-5 lg:pr-6 backdrop-blur backdrop-brightness-100"
            >
                <this.header_bt></this.header_bt>
                <this.header_menu></this.header_menu>
            </div>
        );
    }
}

export default Header;
