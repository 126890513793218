import ClassicButton from "../../buttons/classicButton";
import {animated, useSpring} from "@react-spring/web";
import React from "react";
import {arrow} from "../../icons/arrow";

export const Header = (header, formActive) => {
    const animation = {
        from: {rotate: (formActive ? '315deg' : '90deg')},
        to: {display: (formActive ? '315deg' : '90deg')}
    }

    return (
        <div className='flex flex-row items-center w-full justify-between flex-nowrap'>
                <span className='text-sky-700 font-bold text-lg'>
                    {header}
                </span>
            <animated.span className='mt-1'
                           style={useSpring(animation)}>
                {arrow(true)}
            </animated.span>
        </div>
    )
}


export const Buttons = (handler, text, error, errorAnimationOpen, errorAnimationClosed) => (
    <span className='w-full flex flex-row justify-between'>
                    <ClassicButton text={text}
                                   isSelected={true}
                                   clickHandler={() => handler()}/>
                    <animated.span
                        style={useSpring(error ? errorAnimationOpen : errorAnimationClosed)}
                        className='bg-red-500 text-sm font-bold text-stone-100 p-2 rounded'>
                        🙅 Please fill in all required fields
                    </animated.span>
    </span>
);

export const SubmitButtons = (handler, text, error, errorAnimationOpen, errorAnimationClosed) => (
    <span className='w-full flex flex-row justify-between'>
                    <button className='bg-white rounded p-2 text-sky-600 border
                    border-stone-700 hover:bg-stone-200'
                            title='submit button'
                            onClick={() => handler()}
                            type='submit'>
                        {text}
                    </button>
                    <animated.span
                        style={useSpring(error ? errorAnimationOpen : errorAnimationClosed)}
                        className='bg-red-500 text-sm font-bold text-stone-100 p-2 rounded'>
                        🙅 Please fill in all required fields
                    </animated.span>
    </span>
);
