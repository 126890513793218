import {website} from "../icons/website";
import {marketing} from "../icons/marketing";
import {webapp} from "../icons/webapp";
import {devops} from "../icons/devops";
import {microservices} from "../icons/microservices";
import {serverless} from "../icons/serverless";
import {quality} from "../icons/quality";
import {sdet} from "../icons/sdet";
import {database} from "../icons/database";

/**
 * {
 *     header: '',
 *     body: '',
 *     image: Obj,
 *     link: []
 * }
 *
 * @type {[{image: *, link: number[], header: string, body: string},{image: *, link: number[], header: string, body: string},{image: *, link: number[], header: string, body: string},{image: *, link: number[], header: string, body: string},{image: *, link: number[], header: string, body: string},null,null,null,null]}
 */
export const servicesList = [
    {
        header: 'Database',
        body: 'Set up and manage a relational and non-relational database for your application. Establish a secure connection to your database.',
        image: database(),
        link: [1,2]
    },
    {
        header: 'Static website',
        body: 'Design, build, test and deploy a high quality website that fits your business requirements. ',
        image: website(),
        link: [0,2]
    },
    {
        header: 'Marketing',
        body: 'Flyers, business cards, social posts, and google ads - all you need to market your business and attract new customers.',
        image: marketing(),
        link: [0]
    },
    {
        header: 'Web Application',
        body: 'Build a Ruby on Rails web application from the ground up, tailored to your business needs.',
        image: webapp(),
        link: [1,2]
    },
    {
        header: 'DevOps',
        body: 'Automate software deployment and testing with CI/CD tools such as Drone, Jenkins, Github Workflow and Spinnaker.',
        image: devops(),
        link: [1,2]
    },
    {
        header: 'Microservices',
        body: 'Build, deploy and manage your microservices with Kubernetes. Scale and load balance your microservices.',
        image: microservices(),
        link: [1,2]
    },
    {
        header: 'Serverless Application',
        body: 'Build your project on AWS using serverless framework and deploy by Cloudformation.',
        image: serverless(),
        link: [1,2]
    },
    {
        header: 'Quality Management',
        body: 'Manage the project quality, test engineers, and test processes. Ensure that the project quality meets the software industrial standards.',
        image: quality(),
        link: [0,1,3]
    },
    {
        header: 'SDET',
        body: 'Build and maintain an automation test framework. Perform OWASP, Performance and Accessibility testing',
        image: sdet(),
        link: [1,3]
    }
]