export const myCV = () => {
    return (
        <>
            <svg width="201" height="201" viewBox="0 0 199 313" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className='hidden md:block'>
                <path d="M61.0001 30H59V23H66V24.9999H61.0001V30Z" fill="#0284C7"/>
                <path
                    d="M149 25H138.571V23H149L149 25ZM128.143 25H117.714V23H128.143V25ZM107.286 25H96.857V23H107.285L107.286 25ZM86.4285 25H76V23H86.4285V25Z"
                    fill="#0284C7"/>
                <path d="M165 23H160V25H165V23Z" fill="#0284C7"/>
                <path
                    d="M68.6094 113.126C71.7298 110.011 75.8977 109.065 77.9183 111.013C79.9389 112.962 79.0469 117.066 75.925 120.182C74.6953 121.443 73.1756 122.397 71.4929 122.965L58.1017 136L52 129.705L65.8492 117.523C66.3981 115.862 67.3446 114.354 68.6094 113.126Z"
                    fill="#FFB6B6"/>
                <path d="M199 0H89V41H199V0Z" fill="#E6E6E6"/>
                <path
                    d="M26.3548 102.877L40.1302 133.056L62.1276 121.297L70 125.423C70 125.423 52.0177 155.111 39.7052 151.732C27.3927 148.352 5.52885 117.708 9.46816 108.251C13.4075 98.7931 26.3548 102.877 26.3548 102.877Z"
                    fill="#E6E6E6"/>
                <path d="M62 150H59V156H62V150Z" fill="#0284C7"/>
                <path
                    d="M62 139H59V128H62V139ZM62 117H59V106H62V117ZM62 95.0002H59V83.9998H62V95.0002ZM62 73H59V61.9997H62V73ZM62 50.9998H59V40H62V50.9998Z"
                    fill="#0284C7"/>
                <path d="M21.0022 307L30.4879 306.999L35 268L21 268.001L21.0022 307Z" fill="#FFB6B6"/>
                <path
                    d="M54.525 306.603C54.8359 307.142 55 308.882 55 309.51C55 309.968 54.9123 310.422 54.742 310.845C54.5717 311.269 54.322 311.654 54.0073 311.978C53.6925 312.302 53.3189 312.559 52.9076 312.734C52.4964 312.91 52.0557 313 51.6106 313H20.6838C20.3801 313 20.0794 312.938 19.7989 312.819C19.5183 312.699 19.2634 312.524 19.0487 312.303C18.834 312.081 18.6637 311.819 18.5475 311.53C18.4313 311.241 18.3715 310.932 18.3715 310.619V309.293C18.3715 309.293 16.8416 305.308 19.9914 300.396C19.9914 300.396 23.9062 304.242 29.756 298.218L31.481 295L43.9679 304.404L50.8892 305.282C52.4034 305.473 53.7461 305.252 54.525 306.603Z"
                    fill="#E6E6E6"/>
                <path d="M27.8024 105L28 91.2761L19.8557 86L11 97.2572L27.8024 105Z" fill="#FFB6B6"/>
                <path opacity="0.1" d="M28.4404 105L29 91.6177L20 86L23.0579 102.561L28.4404 105Z" fill="black"/>
                <path
                    d="M28 95C36.2843 95 43 88.2843 43 80C43 71.7157 36.2843 65 28 65C19.7157 65 13 71.7157 13 80C13 88.2843 19.7157 95 28 95Z"
                    fill="#FFB6B6"/>
                <path
                    d="M48 194.541L34.5442 289H17.662L19.9069 235.081L21.0883 206.73L17.662 193.235C16.5741 191.084 15.6129 188.868 14.7838 186.599C13.3004 182.495 12.1288 177.576 13.8682 175.243C14.5081 174.383 16.1721 173.747 18.4333 173.273C19.9963 172.96 21.5736 172.728 23.1593 172.579C33.1428 171.56 47.3436 172.196 47.3436 172.196L47.7046 184.486L47.9048 191.349L48 194.541Z"
                    fill="#7E7D92"/>
                <path d="M3 302.532L12.7903 304L23 266.167L8.5501 264L3 302.532Z" fill="#FFB6B6"/>
                <path
                    d="M36.8481 307.315C37.0827 307.871 36.998 309.546 36.9083 310.14C36.8427 310.575 36.6898 310.993 36.4584 311.37C36.227 311.748 35.9216 312.077 35.5597 312.339C35.1977 312.601 34.7863 312.791 34.3489 312.899C33.9114 313.006 33.4566 313.028 33.0103 312.964L2.00243 308.523C1.38757 308.435 0.833801 308.113 0.462947 307.627C0.0920938 307.141 -0.0654659 306.531 0.0249308 305.932L0.214728 304.675C0.214728 304.675 -0.748774 300.675 3.11243 296.468C3.11243 296.468 6.48698 300.679 13.2143 295.805L15.4046 293L26.5781 303.713L33.3919 305.54C34.8827 305.939 36.2606 305.922 36.8481 307.315Z"
                    fill="#E6E6E6"/>
                <path
                    d="M48 179.058L46.1209 195.319L35.8733 229.585L19.0039 286L2 283.489L17.2608 203.276L15.7498 189.522C15.7498 189.522 15.2577 188.154 14.6936 186.153C13.5739 182.193 12.1696 175.744 13.7146 172.455C13.9072 172.026 14.1765 171.637 14.509 171.306C15.1167 170.714 16.3518 170.354 18.0065 170.163C24.9228 169.378 39.1665 171.624 45.5046 172.727L48 179.058Z"
                    fill="#7E7D92"/>
                <path
                    d="M12.8056 94L29.6893 102.115C29.6893 102.115 47.586 155.201 47.586 157.906C47.586 160.611 52.6511 162.302 49.612 165.345C46.5729 168.388 55.2685 170.965 50.5831 173.396C45.8976 175.827 16.6689 189.123 13.4188 187.924C4.45485 184.617 13.7986 172.465 13.7986 172.465C13.7986 172.465 7.06511 167.035 8.07813 164.33C9.09114 161.625 2 109.892 2 109.892L12.8056 94Z"
                    fill="#E6E6E6"/>
                <path
                    d="M27.4994 83.562L29.4196 87.1873C29.4196 87.1873 31.3398 80.6617 34.4121 79.2115C37.4845 77.7614 37.8685 75.5862 37.8685 75.5862C37.8685 75.5862 41.7089 80.2992 44.3972 79.2115C47.0855 78.1239 48.2376 77.3989 45.1653 73.0485C42.0929 68.698 42.861 70.8732 42.861 70.8732C42.861 70.8732 40.1727 67.2479 40.5568 68.3355C40.9408 69.4231 38.2525 65.4352 38.2525 66.5228C38.2525 67.6104 36.3323 64.3476 36.3323 65.4352C36.3323 66.5228 28.2674 62.8975 29.4196 64.3476C30.5717 65.7978 22.5068 61.4473 18.6664 67.2479C14.826 73.0485 9.50079 71.9758 11.3953 75.5937C13.2898 79.2116 12.5217 89 12.5217 89H17.8182C17.8182 89 23.2749 79.2115 27.4994 83.562Z"
                    fill="#2F2E41"/>
                <path
                    d="M168 147.848L167.836 147.901L158.911 150.645L57 182C67.7937 174.263 70.0836 128.343 67.3662 107.469C83.056 135.829 108.667 118.602 139.976 69.192C148.17 56.2583 156.761 41.1108 165.657 24L167.244 107.958L168 147.848Z"
                    fill="#0284C7"/>
                <path
                    d="M63.9837 140.512C68.114 139.234 72.0916 140.43 72.8677 143.184C73.6439 145.937 70.9249 149.205 66.7929 150.483C65.1491 151.017 63.407 151.131 61.7124 150.816L44.1468 156L42 147.314L59.5445 143.125C60.8038 141.895 62.326 140.999 63.9837 140.512Z"
                    fill="#FFB6B6"/>
                <path
                    d="M32.8345 111.637L30.0328 145.092L55.1293 145.361L60 152.847C60 152.847 29.5815 170.427 20.4477 161.444C11.3139 152.462 7.24986 114.662 15.3706 108.192C23.4913 101.722 32.8345 111.637 32.8345 111.637Z"
                    fill="#E6E6E6"/>
                <path
                    d="M91.1241 97.5913L85 95.7782C87.824 98.6013 90.5883 103.238 92.2604 107C93.5499 103.102 95.8411 98.2341 98.3718 95.1657L92.5372 97.4981C93.6354 79.429 105.786 64.8873 121.868 62.6655L122 62C105.203 64.3208 92.1944 78.8026 91.1241 97.5913Z"
                    fill="#F1F2F2"/>
                <path
                    d="M98.4188 150.305L101.613 145C98.2068 146.96 93.0507 148.419 89 149.068C92.4911 151.232 96.692 154.575 99.0863 157.702L98.1795 151.654C115.502 157.108 126.812 172.074 125.218 187.713L125.835 188C127.499 171.665 116.448 155.909 98.4188 150.305Z"
                    fill="#F1F2F2"/>
                <path
                    d="M167 148.248L158.378 151C151.226 146.583 143.735 142.99 136.29 139.479C133.733 138.275 130.83 136.599 130.146 133.14C129.732 131.078 130.259 128.887 130.972 126.971C134.949 116.244 144.533 110.145 147.458 98.8399C150.146 88.4406 146.146 77.2468 140.086 69.3187C148.001 56.3488 156.301 41.1588 164.895 24L166.429 108.193C166.655 124.201 166.864 138.673 167 148.248Z"
                    fill="#1A3240"/>
            </svg>

            <svg  viewBox="0 0 136 234" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className='md:hidden h-[40vw]'>
                <path
                    d="M126 2H115.571V0H126L126 2ZM105.143 2H94.7145V0H105.143V2ZM84.2855 2H73.857V0H84.2855L84.2855 2ZM63.4285 2H53V0H63.4285V2Z"
                    fill="#0284C7"/>
                <path
                    d="M55.5409 72.1494C58.067 69.6276 61.4409 68.8621 63.0767 70.4394C64.7124 72.0167 63.9904 75.3391 61.4631 77.8614C60.4676 78.8826 59.2373 79.6551 57.8752 80.1142L47.0347 90.6666L42.0952 85.5706L53.3065 75.7088C53.7508 74.3643 54.517 73.1438 55.5409 72.1494Z"
                    fill="#FFB6B6"/>
                <path
                    d="M21.3347 63.8528L32.4863 88.2832L50.2937 78.7645L56.6666 82.1046C56.6666 82.1046 42.1095 106.138 32.1422 103.402C22.1749 100.666 4.47566 75.8591 7.66463 68.2029C10.8536 60.5467 21.3347 63.8528 21.3347 63.8528Z"
                    fill="#E6E6E6"/>
                <path d="M50.191 102H47.762V106.858H50.191V102Z" fill="#0284C7"/>
                <path
                    d="M50.1905 93.0953H47.762V84.1906H50.1905V93.0953ZM50.1905 75.2856H47.762V66.381H50.1905V75.2856ZM50.1905 57.4763H47.762V48.5713H50.1905V57.4763ZM50.1905 39.6667H47.762V30.7617H50.1905V39.6667ZM50.1905 21.857H47.762V12.9524H50.1905V21.857Z"
                    fill="#0284C7"/>
                <path d="M17.0018 229.095L24.6807 229.094L28.3333 197.524L17 197.524L17.0018 229.095Z" fill="#FFB6B6"/>
                <path
                    d="M44.1394 228.774C44.3911 229.21 44.5239 230.619 44.5239 231.127C44.5239 231.498 44.4529 231.865 44.3151 232.208C44.1772 232.551 43.9751 232.862 43.7203 233.125C43.4655 233.387 43.163 233.595 42.8301 233.737C42.4972 233.879 42.1404 233.952 41.7801 233.952H16.7441C16.4983 233.952 16.2549 233.903 16.0278 233.806C15.8007 233.709 15.5943 233.567 15.4205 233.388C15.2467 233.209 15.1088 232.996 15.0147 232.762C14.9207 232.529 14.8722 232.278 14.8722 232.025V230.951C14.8722 230.951 13.6337 227.725 16.1836 223.749C16.1836 223.749 19.3527 226.863 24.0883 221.986L25.4847 219.381L35.5932 226.994L41.1961 227.704C42.4219 227.86 43.5088 227.68 44.1394 228.774Z"
                    fill="#E6E6E6"/>
                <path d="M22.5067 65.5714L22.6667 54.4616L16.0737 50.1904L8.90479 59.3034L22.5067 65.5714Z"
                      fill="#FFB6B6"/>
                <path opacity="0.1" d="M23.0231 65.5714L23.4761 54.7381L16.1904 50.1904L18.6659 63.597L23.0231 65.5714Z"
                      fill="black"/>
                <path
                    d="M22.6668 57.4761C29.3731 57.4761 34.8096 52.0396 34.8096 45.3333C34.8096 38.627 29.3731 33.1904 22.6668 33.1904C15.9605 33.1904 10.5239 38.627 10.5239 45.3333C10.5239 52.0396 15.9605 57.4761 22.6668 57.4761Z"
                    fill="#FFB6B6"/>
                <path
                    d="M38.8572 138.057L27.9644 214.524H14.298L16.1152 170.875L17.0716 147.924L14.298 136.999C13.4172 135.259 12.6391 133.465 11.968 131.628C10.7671 128.306 9.81863 124.324 11.2267 122.435C11.7448 121.739 13.0918 121.224 14.9223 120.84C16.1876 120.586 17.4645 120.399 18.7481 120.278C26.83 119.453 38.3259 119.969 38.3259 119.969L38.6181 129.917L38.7802 135.473L38.8572 138.057Z"
                    fill="#7E7D92"/>
                <path d="M2.42847 225.478L10.3539 226.667L18.6189 196.04L6.92141 194.286L2.42847 225.478Z"
                      fill="#FFB6B6"/>
                <path
                    d="M29.8294 229.35C30.0193 229.8 29.9508 231.156 29.8781 231.638C29.825 231.989 29.7013 232.328 29.514 232.633C29.3266 232.939 29.0794 233.205 28.7864 233.417C28.4934 233.63 28.1603 233.784 27.8062 233.87C27.4521 233.957 27.0839 233.975 26.7226 233.924L1.62102 230.328C1.12327 230.257 0.674981 229.996 0.374767 229.603C0.0745521 229.209 -0.0529962 228.716 0.0201821 228.231L0.173827 227.213C0.173827 227.213 -0.606151 223.975 2.51958 220.57C2.51958 220.57 5.25136 223.978 10.6973 220.032L12.4704 217.762L21.5156 226.435L27.0316 227.913C28.2384 228.236 29.3538 228.222 29.8294 229.35Z"
                    fill="#E6E6E6"/>
                <path
                    d="M38.8572 125.523L37.3361 138.687L29.0403 166.426L15.3842 212.095L1.61914 210.062L13.9732 145.128L12.7499 133.994C12.7499 133.994 12.3515 132.887 11.8949 131.266C10.9885 128.061 9.85165 122.841 11.1024 120.178C11.2583 119.831 11.4763 119.515 11.7455 119.247C12.2374 118.768 13.2372 118.477 14.5768 118.323C20.1757 117.687 31.7063 119.505 36.8372 120.398L38.8572 125.523Z"
                    fill="#7E7D92"/>
                <path
                    d="M10.3665 56.6667L24.0343 63.236C24.0343 63.236 38.5221 106.21 38.5221 108.4C38.5221 110.59 42.6224 111.958 40.1622 114.422C37.702 116.885 44.7413 118.972 40.9483 120.939C37.1553 122.907 13.494 133.671 10.8629 132.7C3.6064 130.023 11.1704 120.186 11.1704 120.186C11.1704 120.186 5.71947 115.79 6.53953 113.601C7.35958 111.411 1.61914 69.5315 1.61914 69.5315L10.3665 56.6667Z"
                    fill="#E6E6E6"/>
                <path
                    d="M22.2614 48.2169L23.8159 51.1517C23.8159 51.1517 25.3703 45.869 27.8575 44.6951C30.3446 43.5212 30.6555 41.7603 30.6555 41.7603C30.6555 41.7603 33.7644 45.5755 35.9406 44.6951C38.1168 43.8146 39.0495 43.2277 36.5624 39.7059C34.0753 36.1842 34.697 37.945 34.697 37.945C34.697 37.945 32.5208 35.0102 32.8317 35.8907C33.1426 36.7711 30.9663 33.5428 30.9663 34.4233C30.9663 35.3037 29.4119 32.6624 29.4119 33.5428C29.4119 34.4233 22.8832 31.4885 23.8159 32.6624C24.7485 33.8363 18.2198 30.3145 15.1109 35.0102C12.002 39.7059 7.69114 38.8376 9.22479 41.7663C10.7584 44.6951 10.1366 52.6191 10.1366 52.6191H14.4243C14.4243 52.6191 18.8416 44.6951 22.2614 48.2169Z"
                    fill="#2F2E41"/>
                <path
                    d="M136 100.258L135.867 100.301L128.642 102.522L46.1428 127.905C54.8806 121.642 56.7343 84.4681 54.5345 67.5697C67.2358 90.5285 87.9684 76.5825 113.314 36.584C119.947 26.1139 126.902 13.8516 134.104 0L135.388 67.9659L136 100.258Z"
                    fill="#0284C7"/>
                <path
                    d="M51.7963 94.3195C55.1399 93.2847 58.3599 94.2531 58.9882 96.4822C59.6165 98.7112 57.4154 101.356 54.0704 102.391C52.7397 102.823 51.3294 102.916 49.9576 102.66L35.7379 106.857L34 99.8253L48.2027 96.4346C49.2221 95.439 50.4544 94.7138 51.7963 94.3195Z"
                    fill="#FFB6B6"/>
                <path
                    d="M26.5804 70.9445L24.3123 98.0269L44.6285 98.245L48.5715 104.305C48.5715 104.305 23.947 118.536 16.5529 111.264C9.15891 103.993 5.86896 73.393 12.4429 68.1552C19.0168 62.9175 26.5804 70.9445 26.5804 70.9445Z"
                    fill="#E6E6E6"/>
                <path
                    d="M73.7672 59.574L68.8096 58.1062C71.0957 60.3916 73.3335 64.1449 74.6871 67.1905C75.7309 64.0351 77.5857 60.0943 79.6344 57.6104L74.9111 59.4985C75.8001 44.8712 85.6366 33.0993 98.6547 31.3007L98.762 30.762C85.1642 32.6407 74.6336 44.3641 73.7672 59.574Z"
                    fill="#F1F2F2"/>
                <path
                    d="M79.6724 102.247L82.2579 97.9524C79.5007 99.5388 75.3267 100.72 72.0476 101.245C74.8737 102.998 78.2745 105.703 80.2127 108.235L79.4786 103.339C93.5016 107.754 102.657 119.869 101.367 132.529L101.866 132.762C103.214 119.538 94.2673 106.783 79.6724 102.247Z"
                    fill="#F1F2F2"/>
                <path
                    d="M135.19 100.582L128.21 102.81C122.421 99.234 116.357 96.3254 110.33 93.483C108.26 92.5087 105.91 91.1513 105.356 88.3515C105.021 86.682 105.447 84.9084 106.025 83.3572C109.244 74.6738 117.003 69.7362 119.371 60.5847C121.546 52.1662 118.308 43.1045 113.403 36.6866C119.811 26.1872 126.53 13.8905 133.487 0L134.728 68.1566C134.911 81.1154 135.081 92.8303 135.19 100.582Z"
                    fill="#1A3240"/>
            </svg>
        </>
    )
}