import React from 'react';
import RoundedButton from "../buttons/roundedButton";
import {experienceFreelancing} from "../contents/experienceFreelancing";
import {experienceGovernment} from "../contents/experienceGovernment";
import {experienceMentoring} from "../contents/experienceMentoring";
import {experiencePrivate} from "../contents/experiencePrivate";

class Experience extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedExperience: 'Private',
            experience: experiencePrivate
        }

        this.card = this.card.bind(this);
        this.buttonClickHandler = this.buttonClickHandler.bind(this);
    }

    /**
     * This is the event handler for clicking on any experience buttons. When
     * this function is called, it will update the selectedExperience and the
     * experience list
     *
     * @param buttonText experience
     */
    buttonClickHandler = (buttonText) => {
        let experienceCards = null;

        switch (buttonText) {
            case 'Freelancing':
                experienceCards = experienceFreelancing;
                break;
            case 'Coaching':
                experienceCards = experienceMentoring;
                break;
            case 'Private':
                experienceCards = experiencePrivate;
                break;
            case 'Governments':
                experienceCards = experienceGovernment;
                break;
        }

        this.setState({
            selectedExperience: buttonText,
            experience: experienceCards
        })
    }


    /**
     * This function renders all the experiences in a card to be displayed
     * on the screen.
     *
     * @returns {JSX.Element} experiences cards
     */
    card = () => {
        const firstArray = this.state.experience.slice(0, 2);
        const secondArray = this.state.experience.slice(2);


        return (
            <div className="md:w-[100%] grid md:grid-rows-2 gap-2
                justify-center w-full p-2 md:p-0">

                {[firstArray, secondArray].map((card, index) => (
                    <div className="flex flex-col md:flex-row gap-2 w-full" key={`cards-group-${index}`}>
                        {card.map((experience) => (
                            <div className='bg-stone-900 text-stone-100 rounded max-w-sm p-4
                                    flex flex-nowrap flex-col h-fit'
                                 key={experience.title}>

                                <span className='text-lg'>{experience.title}</span>
                                <div className='h-[0.9rem]'/>
                                <div className='flex flex-row gap-1 flex-wrap'>
                                    {experience.skills.map((skill) => (
                                        <div key={skill} className='text-sm'>
                                            <span className='text-sky-600'>#</span>
                                            <span className='text-stone-400'>{skill}</span>
                                        </div>
                                    ))}
                                </div>
                                <br/>
                                <p>{experience.body}</p>
                                <span className='text-sm text-sky-600'>{experience.year}</span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        )
    };


    render() {
        return (
            <>
                <div className='text-sky-700 font-bold text-lg pb-12 md:pt-10 pl-[5vw] pr-[5vw]
                    md:pr-[0vw] md:pl-[0vw] flex justify-center'>
                        <span>
                            RELEVANT WORK AND EXPERIENCE
                        </span>
                </div>

                <div className='flex flex-col md:flex-row w-full justify-center gap-3'>
                    <div>
                        <div className="max-w-[99vw] md:max-w-[20rem] flex flex-nowrap md:flex-wrap h-fit
                                    gap-2 overflow-x-scroll pl-1 pr-1 md:p-0 md:overflow-hidden">

                            {['Private', 'Freelancing', 'Coaching', 'Governments'].map((experience) =>
                                <div key={experience} className=''>
                                    <RoundedButton text={experience}
                                                   onClick={this.buttonClickHandler}
                                                   isSelected={this.state.selectedExperience === experience}/>
                                </div>
                            )}
                        </div>
                    </div>

                    <div>
                        <this.card/>
                    </div>
                </div>
            </>
        )
    }
}

export default Experience;