export const sdet = () => (
    <svg viewBox="0 0 132 147" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[30%] md:w-[25%]'>
        <path d="M17.5631 111.853C14.8055 111.881 12.05 111.69 9.32251 111.282C6.78294 110.853 5.49521 110.243 5.49521 109.469L5.49615 109.455L10.2338 73.3844L7.84769 66.5444C7.74752 66.2572 7.71796 65.9501 7.76149 65.6491C7.80502 65.3481 7.92037 65.0619 8.0978 64.8149C8.27523 64.5678 8.50951 64.3671 8.78087 64.2297C9.05222 64.0923 9.35266 64.0222 9.65681 64.0254L25.3479 64.1888C25.6465 64.1919 25.9401 64.2655 26.2048 64.4037C26.4696 64.5418 26.6979 64.7406 26.8712 64.9837C27.0446 65.2269 27.158 65.5075 27.2022 65.8028C27.2465 66.0981 27.2203 66.3997 27.1259 66.683L24.8924 73.3835L29.6301 109.455C29.6311 111.351 22.0454 111.853 17.5631 111.853Z" fill="#CCCCCC"/>
        <path d="M16.2846 106.775H8.61993V107.206H16.2846V106.775Z" fill="#231C1C"/>
        <path d="M16.2846 104.189H8.61993V104.62H16.2846V104.189Z" fill="#231C1C"/>
        <path d="M16.2846 101.603H8.61993V102.034H16.2846V101.603Z" fill="#231C1C"/>
        <path d="M16.2846 99.0174H8.61993V99.4484H16.2846V99.0174Z" fill="#231C1C"/>
        <path d="M16.2846 96.4314H8.61993V96.8624H16.2846V96.4314Z" fill="#231C1C"/>
        <path d="M86.5227 111.853C83.7651 111.881 81.0095 111.69 78.2821 111.282C75.7425 110.853 74.4548 110.243 74.4548 109.469L74.4557 109.455L79.1933 73.3844L76.8073 66.5444C76.7071 66.2572 76.6775 65.9501 76.7211 65.6491C76.7646 65.3481 76.8799 65.0619 77.0574 64.8149C77.2348 64.5678 77.4691 64.3671 77.7404 64.2297C78.0118 64.0923 78.3122 64.0222 78.6164 64.0254L94.3075 64.1888C94.6061 64.1919 94.8997 64.2655 95.1644 64.4037C95.4291 64.5418 95.6575 64.7406 95.8308 64.9837C96.0041 65.2269 96.1175 65.5075 96.1618 65.8028C96.206 66.0981 96.1799 66.3997 96.0855 66.683L93.852 73.3835L98.5897 109.455C98.5906 111.351 91.0049 111.853 86.5227 111.853Z" fill="#CCCCCC"/>
        <path d="M85.2442 106.775H77.5795V107.206H85.2442V106.775Z" fill="#231C1C"/>
        <path d="M85.2442 104.189H77.5795V104.62H85.2442V104.189Z" fill="#231C1C"/>
        <path d="M85.2442 101.603H77.5795V102.034H85.2442V101.603Z" fill="#231C1C"/>
        <path d="M85.2442 99.0174H77.5795V99.4484H85.2442V99.0174Z" fill="#231C1C"/>
        <path d="M85.2442 96.4314H77.5795V96.8624H85.2442V96.4314Z" fill="#231C1C"/>
        <path d="M54.1979 111.853C51.4403 111.881 48.6848 111.69 45.9573 111.282C43.4177 110.853 42.13 110.243 42.13 109.469L42.131 109.455L46.8686 73.3844L44.4825 66.5444C44.3823 66.2572 44.3528 65.9501 44.3963 65.6491C44.4398 65.3481 44.5552 65.0619 44.7326 64.8149C44.91 64.5678 45.1443 64.3671 45.4157 64.2297C45.687 64.0923 45.9875 64.0222 46.2916 64.0254L61.9827 64.1888C62.2813 64.1919 62.5749 64.2655 62.8397 64.4037C63.1044 64.5418 63.3327 64.7406 63.506 64.9837C63.6794 65.2269 63.7928 65.5075 63.837 65.8028C63.8813 66.0981 63.8551 66.3997 63.7607 66.683L61.5272 73.3835L66.2649 109.455C66.2659 111.351 58.6802 111.853 54.1979 111.853Z" fill="#CCCCCC"/>
        <path d="M52.9193 106.775H45.2547V107.206H52.9193V106.775Z" fill="#231C1C"/>
        <path d="M52.9193 104.189H45.2547V104.62H52.9193V104.189Z" fill="#231C1C"/>
        <path d="M52.9193 101.603H45.2547V102.034H52.9193V101.603Z" fill="#231C1C"/>
        <path d="M52.9193 99.0174H45.2547V99.4484H52.9193V99.0174Z" fill="#231C1C"/>
        <path d="M52.9193 96.4314H45.2547V96.8624H52.9193V96.4314Z" fill="#231C1C"/>
        <path d="M105.163 114.533H90.5094V89.9665H105.163C105.964 89.9665 106.731 89.6486 107.297 89.0828C107.862 88.517 108.18 87.7496 108.18 86.9495C108.18 86.1493 107.862 85.382 107.297 84.8162C106.731 84.2504 105.964 83.9325 105.163 83.9325H3.01698C2.21683 83.9325 1.44945 84.2504 0.883655 84.8162C0.317861 85.382 0 86.1493 0 86.9495C0 87.7496 0.317861 88.517 0.883655 89.0828C1.44945 89.6486 2.21683 89.9665 3.01698 89.9665H15.5159V114.533H3.01698C2.21683 114.533 1.44945 114.851 0.883655 115.417C0.317862 115.983 0 116.75 0 117.55C0 118.35 0.317862 119.118 0.883655 119.684C1.44945 120.249 2.21683 120.567 3.01698 120.567H105.163C105.56 120.568 105.952 120.49 106.319 120.339C106.685 120.187 107.018 119.965 107.299 119.685C107.579 119.405 107.802 119.072 107.954 118.706C108.105 118.339 108.184 117.947 108.184 117.55C108.184 117.154 108.105 116.761 107.954 116.395C107.802 116.029 107.579 115.696 107.299 115.416C107.018 115.136 106.685 114.913 106.319 114.762C105.952 114.611 105.56 114.533 105.163 114.533ZM21.5499 114.533V89.9665H84.4755V114.533H21.5499Z" fill="#231C1C"/>
        <path d="M102.79 146.341C95.2952 146.341 88.3047 145.857 83.1073 144.978C76.6954 143.893 73.5784 142.317 73.5784 140.159L73.5819 140.091L84.8359 54.3985L79.2016 38.2468C78.9312 37.4869 78.8502 36.6726 78.9656 35.8743C79.081 35.076 79.3894 34.3179 79.8639 33.6657C80.3275 33.0034 80.9475 32.4659 81.6689 32.1011C82.3903 31.7363 83.1907 31.5555 83.9989 31.5747L121.309 31.9633C122.1 31.9715 122.878 32.1665 123.579 32.5324C124.28 32.8983 124.885 33.4247 125.344 34.0687C125.803 34.7127 126.103 35.456 126.22 36.2382C126.338 37.0203 126.268 37.8191 126.018 38.5694L120.744 54.3925L132 140.093V140.126C132.001 142.452 128.472 144.104 121.212 145.175C114.079 146.228 105.953 146.341 102.79 146.341Z" fill="#EFEFEF"/>
        <path d="M102.79 146.341C95.2952 146.341 88.3047 145.857 83.1073 144.978C76.6954 143.893 73.5784 142.317 73.5784 140.159L73.5819 140.091L84.8359 54.3985L79.2016 38.2468C78.9312 37.4869 78.8502 36.6726 78.9656 35.8743C79.081 35.076 79.3894 34.3179 79.8639 33.6657C80.3275 33.0034 80.9475 32.4659 81.6689 32.1011C82.3903 31.7363 83.1907 31.5555 83.9989 31.5747L121.309 31.9633C122.1 31.9715 122.878 32.1665 123.579 32.5324C124.28 32.8983 124.885 33.4247 125.344 34.0687C125.803 34.7127 126.103 35.456 126.22 36.2382C126.338 37.0203 126.268 37.8191 126.018 38.5694L120.744 54.3925L132 140.093V140.126C132.001 142.452 128.472 144.104 121.212 145.175C114.079 146.228 105.953 146.341 102.79 146.341ZM83.8958 32.5987C83.2678 32.5981 82.649 32.7491 82.092 33.039C81.535 33.3289 81.0562 33.7491 80.6965 34.2638C80.3188 34.7827 80.0735 35.3859 79.9816 36.0211C79.8898 36.6563 79.9543 37.3044 80.1695 37.909L85.8839 54.2904L85.8681 54.411L74.6014 140.193C74.6034 141.033 75.7305 142.69 83.2784 143.967C88.4211 144.837 95.3505 145.316 102.79 145.316C109.33 145.316 116.161 144.884 121.062 144.161C127.324 143.237 130.931 141.779 130.975 140.157L119.697 54.2924L125.046 38.2453C125.245 37.6483 125.3 37.0127 125.207 36.3903C125.113 35.7679 124.874 35.1763 124.509 34.6639C124.144 34.1514 123.663 33.7325 123.105 33.4413C122.547 33.1501 121.928 32.9949 121.299 32.9883L83.9826 32.5997C83.9536 32.5989 83.9246 32.5987 83.8958 32.5987Z" fill="#231C1C"/>
        <path opacity="0.5" d="M124.059 129.675H81.522L88.2651 84.0782L88.4487 82.8215L91.2579 63.8395C91.4293 62.6816 92.7623 63.5808 93.7049 62.8867C99.5568 58.5773 105.479 58.4524 111.452 62.6059C112.375 63.248 113.651 62.4966 113.823 63.6079L116.774 82.6475L117.059 84.4891L124.059 129.675Z" fill="#0284C7"/>
        <path d="M118.809 136.06H86.7265C85.8854 136.06 85.0542 135.878 84.2895 135.528C83.5248 135.178 82.8445 134.667 82.295 134.03C81.7455 133.394 81.3397 132.646 81.1052 131.838C80.8708 131.03 80.8131 130.182 80.9362 129.35L90.3096 65.9646C90.358 65.6405 90.4912 65.3349 90.6956 65.0788C90.9 64.8227 91.1684 64.6251 91.4737 64.5061C91.7791 64.3871 92.1104 64.3509 92.4342 64.4011C92.758 64.4513 93.0628 64.5861 93.3178 64.7919C99.0305 69.3683 105.257 69.2652 111.823 64.4872C112.078 64.301 112.376 64.184 112.689 64.1475C113.003 64.111 113.32 64.1563 113.611 64.279C113.907 64.4007 114.167 64.5973 114.365 64.8494C114.564 65.1016 114.693 65.4008 114.741 65.7178L124.593 129.31C124.723 130.145 124.67 130.997 124.438 131.809C124.207 132.621 123.803 133.374 123.253 134.015C122.704 134.656 122.022 135.171 121.255 135.524C120.488 135.877 119.653 136.06 118.809 136.06Z" fill="#0284C7"/>
        <path d="M105.9 128.628H87.6719V129.653H105.9V128.628Z" fill="#EFEFEF"/>
        <path d="M105.9 122.479H87.6719V123.504H105.9V122.479Z" fill="#EFEFEF"/>
        <path d="M105.9 116.329H87.6719V117.354H105.9V116.329Z" fill="#EFEFEF"/>
        <path d="M105.9 110.179H87.6719V111.204H105.9V110.179Z" fill="#EFEFEF"/>
        <path d="M105.9 104.029H87.6719V105.054H105.9V104.029Z" fill="#EFEFEF"/>
        <path d="M117.919 87.8957C116.505 89.2996 114.805 90.3809 112.934 91.0656C111.063 91.7503 109.066 92.0221 107.08 91.8624C104.815 91.6574 102.749 90.8118 100.761 89.7458C98.7058 88.644 96.7173 87.3269 94.4419 86.7119C92.4483 86.1738 90.0857 86.102 88.374 87.4345C88.2341 87.5429 88.0632 87.6039 87.8862 87.6085C87.7092 87.6131 87.5354 87.5611 87.39 87.4601L87.5848 86.1277C89.4912 84.7952 91.8077 84.5902 94.078 85.0463C98.6494 85.9688 102.119 89.751 106.803 90.2942C108.824 90.5217 110.869 90.2437 112.756 89.4851C114.642 88.7265 116.311 87.5112 117.612 85.9483L117.617 85.9431L117.919 87.8957Z" fill="#EFEFEF"/>
        <path d="M101.298 8.32585C103.597 8.32585 105.461 6.46205 105.461 4.16293C105.461 1.86381 103.597 0 101.298 0C98.9988 0 97.1349 1.86381 97.1349 4.16293C97.1349 6.46205 98.9988 8.32585 101.298 8.32585Z" fill="#0284C7"/>
        <path d="M113.787 15.7266C115.319 15.7266 116.562 14.4841 116.562 12.9513C116.562 11.4186 115.319 10.176 113.787 10.176C112.254 10.176 111.011 11.4186 111.011 12.9513C111.011 14.4841 112.254 15.7266 113.787 15.7266Z" fill="#0284C7"/>
        <path d="M103.359 29.1207C104.52 29.1207 105.461 28.1795 105.461 27.0185C105.461 25.8575 104.52 24.9163 103.359 24.9163C102.198 24.9163 101.257 25.8575 101.257 27.0185C101.257 28.1795 102.198 29.1207 103.359 29.1207Z" fill="#0284C7"/>
    </svg>

);