import React from 'react';
import CollapsibleMenu from "../menu/collapsibleMenu";
import {awardsList} from "../contents/awardsList";

class Awards extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        const items = awardsList

        return (
            <div className=''>
                <CollapsibleMenu items={items}/>
            </div>
        )
    }
}

export default Awards;