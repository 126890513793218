import React from 'react';

import {useSpring, animated} from '@react-spring/web'

class About extends React.Component {
    constructor(props) {
        super(props);

        this.animatedBackground = this.animatedBackground.bind(this);
        this.aboutSectionHeader = this.aboutSectionHeader.bind(this);
        this.aboutSectionParagraph = this.aboutSectionParagraph.bind(this);
    }

    /**
     * This function will render the page header of the about section
     *
     * @returns {JSX.Element}
     */
    aboutSectionHeader = () => {
        return (
            <h1 className='text-xl font-sans font-bold filter invert-0 text-stone-800'>
                Hi, I’m Abdullah - a software engineer based in the UK.
            </h1>)
    };

    /**
     * This function is to render the paragraph on the about section
     * on the page
     *
     * @returns {JSX.Element}
     */
    aboutSectionParagraph = () => {
        const highlight = 'bg-sky-600 rounded text-stone-50'

        return (
            <p>
                I specialise in <span className={highlight}> web application development</span> and <span
                className={highlight}>software quality assurance</span> , but I love all things related to software
                engineering - from product design to <span className={highlight}>software delivery and maintenance</span>.
                <span className='hidden md:block pt-2'>
                I’m always on the lookout for <span className={highlight}>new technologies</span>, and I love to hear about <span
                    className={highlight}>new ideas, products or projects</span>. So, please don’t hesitate to contact me anytime for any related enquiries.
            </span>

            </p>
        )
    };

    /**
     * This function will render the background animation for the
     * about section
     *
     * @returns {JSX.Element}
     */
    animatedBackground = () => {
        const springs = useSpring({
            config: {mass: 50, tension: 90, friction: 15},
            from: {borderRadius: '54% 46% 44% 56% / 47% 41% 59% 53% '},
            to: [{borderRadius: '40% 60% 56% 44% / 52% 49% 51% 48%'},
                {borderRadius: '54% 46% 44% 56% / 47% 41% 59% 53% '}],
            loop: true
        });

        return (
            <animated.div className="absolute bg-sky-100 md:h-[25vw] md:w-[25vw] h-[60vw] w-[60vw]
            left-[9vw] top-[10vw] md:top-[5vw] bg-gradient-radial from-sky-100 via-sky-200 to-sky-300 rounded-full
            __about-section-animation"
                          style={{...springs}}>
            </animated.div>
        )
    }

    render() {
        return (
            <div className="relative w-full h-[100%] z-10 overflow-hidden">
                <section className="absolute max-w-[60vw] md:max-w-[50vw] z-10 top-[30vw]
                md:top-[13vw] left-[20vw] ___about-section-text">
                    <this.aboutSectionHeader/>
                    <br/>
                    <this.aboutSectionParagraph/>
                </section>
                <this.animatedBackground/>
            </div>
        )
    }
}

export default About;