import React from 'react';
import GlassMenu from "../menu/glassMenu";
import {help} from "../icons/help";
import {servicesList} from "../contents/servicesList";
import {camelize} from "../helpers/stringHelper";

class Services extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedService: 'Select a Service',
            services: ['build a website', 'build a web app', 'hire a software engineer', 'hire a quality engineer']
        }

        this.header = this.header.bind(this);
        this.card = this.card.bind(this);
        this.selectServiceHandler = this.selectServiceHandler.bind(this);
    }

    /**
     * This function will handle user clicking on a manu item. When
     * the event is fired, the selected service will be stored in
     * the state
     *
     * @param service
     */
    selectServiceHandler = (service) => {
        this.setState({selectedService: service})
    }

    /**
     * This function will render the card for each service. The param
     * should be an object with a header, body, image and link
     *
     * @param content card content
     * @returns {JSX.Element}
     */
    card = (content) => (
        <div className='max-w-sm max-h-sm flex flex-col
        bg-stone-200 border border-stone-400 rounded p-3'
        key={camelize(content.header)}>

            <span className='block text-sky-600 font-bold flex-none'>
                {content.header}
            </span>

            <span className='block text-stone-800 flex-auto text-sm'>
                {content.body}
            </span>
            <span className='h-3'></span>
            <span className='flex-1 w-full flex justify-center'>
                {content.image}
            </span>
        </div>
    );

    /**
     * This function will render the page header and the
     * dropdown menu
     *
     * @returns {JSX.Element}
     */
    header = () => (
        <div className='flex flex-col'>
            {help()}
            <section className='flex gap-1 text-xl'>
                <h3>Do you need to</h3>
                <GlassMenu items={this.state.services}
                           onClick={(item) => this.selectServiceHandler(item)}
                           text={this.state.selectedService}/>
            </section>
        </div>

    )

    render() {
        const {selectedService, services} = this.state;

        return (
            <>
                <div className='w-full flex justify-center pt-[5rem] pb-5'>
                    <this.header/>
                </div>

                <div className="flex w-full justify-center">
                    <div className='grid-cols-2 md:grid-cols-3 w-fit grid
                gap-2 md:gap-4 p-2 md:p-7 content-evenly'>
                        {servicesList.map((service) => {
                            if (services.includes(selectedService) > 0) {
                                const index = services.indexOf(selectedService);
                                if (service.link.includes(index)) return this.card(service);
                                return null;
                            } else {
                                return this.card(service);
                            }
                        })}
                    </div>
                </div>
            </>
        )
    }
}

export default Services;