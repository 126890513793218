import React from "react";
import PropTypes from "prop-types";

class GlassDownloadButton extends React.Component {

    constructor(props) {
        super(props);
        this.glassBt = this.glassBt.bind(this);
    }

    /**
     * This function will render a glass button. The button
     * will have a text from the props
     * @returns {JSX.Element}
     */
    glassBt = () => (
        <a title={`${this.props.text.toLowerCase()} button`}
           download={`${this.props.text.toLowerCase()} download`}
           href={this.props.fileLink}
           className='backdrop-blur bg-white/30 hover:bg-white/40 p-2 rounded-full
                 border-2 border-stone-100 text-stone-100 pl-3 pr-3 cursor-pointer'>
            {this.props.text}
        </a>
    )

    render() {
        return <this.glassBt/>
    }
}

GlassDownloadButton.propTypes = {
    text: PropTypes.string,
    fileLink: PropTypes.string
};

export default GlassDownloadButton;