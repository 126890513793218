import React from 'react';
import Contact from "../forms/contact/contact";
import {LineLong} from "../icons/linelong";

class ContactMe extends React.Component {

    constructor(props) {
        super(props);

        this.header = this.header.bind(this);
        this.body = this.body.bind(this);
        this.line = this.line.bind(this);
    }


    /**
     * render the header of contact me section. The header has a
     * sub header
     *
     * @returns {React.JSX.Element}
     */
    header = () => (<span className='text-sky-700 font-bold text-lg'>
            LEAVE ME A MESSAGE
               <div className='md:max-w-sm pb-3 text-sm  text-sky-600'>
                Ask me a question or leave me a message
                </div>
            </span>);


    /**
     * render the body text of the contact me section
     *
     * @returns {React.JSX.Element}
     */
    body = () => (<div>
        <div className='max-w-[60vw] md:max-w-[25rem] md:pr-3'>
            You will get a confirmation email upon submitting your query.
            And a personal replay within a day
        </div>
    </div>);


    /**
     * renders the arrow image on the page.
     *
     * @returns {React.JSX.Element}
     */
    line = () => (<div className='md:-rotate-180 pt-7 pl-1'>
        {LineLong()}
    </div>);


    render() {
        return (<div className='flex flex-col md:flex-row w-full
        md:relative justify-center items-center md:items-baseline'
        id='contact-me'>
            <div className='p-3 w-[100%] max-w-sm'>
                <this.header/>
                <div className='md:block flex flex-row'>
                    <this.body/>
                    <this.line/>
                </div>
            </div>
            <div className="w-[100%] max-w-sm">
                <Contact/>
            </div>
        </div>)
    }
}


export default ContactMe;