/**
 * {
 *      year: '',
 *      title: '',
 *      skills: [],
 *      body: ''
 *  }
 *
 * @type {[{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string}]}
 */
export const experiencePrivate = [
    {
        year: '2017',
        title: 'Active Driver Distraction Mitigation System - sponsored by Boeing',
        skills: ['Java', 'AI', 'Android', 'Sqlite'],
        body: 'To reduce driver distraction due to operation of in-vehicle systems, control interfaces should be as intuitive and easy to use as possible. I designed and implemented a driver distraction mitigation system to support driver’s in-vehicle interactions.'
    },
    {
        year: '2018',
        title: 'ZOPA - ACUTEST',
        skills: ['Java', 'Jenkins', 'Kafka', 'ReactJS', 'NodeJS', 'Postgresql', 'Jmeter', 'Cucumber', 'Selenium', 'Applenium', 'Jira', 'X-Ray'],
        body: 'Built and refactored the company’s website and mobile app. Built skeleton automation test framework and internal tools & software. Organized development life cycles and managed the QE Team.'
    },
    {
        year: '2018',
        title: 'Financial Software LTD (FSL)',
        skills: ['ASP.Net', 'C#', 'SQL', 'Jquery', 'Selenium grid', 'Selenium server', 'TeamCity', 'Team Foundation Server'],
        body: 'Developed data compression libraries in C#. Maintained the current automation test framework while working closely with the BAs to gather requirements.'
    },
    {
        year: '2017',
        title: 'A web application to manage the warehouse of a network company in Saudi',
        skills: ['ASP.Net', 'C#', 'Networking', 'SQL', 'Jquery', 'Cisco', 'Routers', 'Cisco Packet Tracer'],
        body: 'Developed a web application (.net) that allows network engineers in ACES (Riyadh, KSA) to manage their warehouse network equipment, such as routers and switches.'
    }
];
