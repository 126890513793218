import React from "react";
import PropTypes from "prop-types";

class RoundedButton extends React.Component {

    constructor(props) {
        super(props);

        this.rounded_bt_selected = this.rounded_bt_selected.bind(this);
        this.rounded_bt_unselected = this.rounded_bt_unselected.bind(this);
    }

    rounded_bt_selected = () => {
        const {text, onClick} = this.props;

        return (
            <button className='bg-stone-800 text-stone-100 p-2 rounded-full
            pl-5 pr-5 md:hover:bg-stone-900 md:hover:shadow border-2 border-stone-800'
                    onClick={() => onClick(text)}
                    title={`${(text || '').toLowerCase()} button`}>
                {text}
            </button>
        )
    };

    rounded_bt_unselected = () => {
        const {text, onClick} = this.props;

        return (
            <button className='border-stone-800 text-stone-800 p-2
            rounded-full pl-5 pr-5 border-2 md:hover:bg-stone-200 md:hover:shadow'
                    onClick={() => onClick(text)}
                    title={`${(text || '').toLowerCase()} button`}>
                {text}
            </button>
        )
    };


    render() {
        return (
            <>
                {this.props.isSelected ?
                    <this.rounded_bt_selected/>
                    : <this.rounded_bt_unselected/>}
            </>
        )
    }
}

RoundedButton.propTypes = {
    isSelected: PropTypes.bool,
    text: PropTypes.string,
    onClick: PropTypes.func
};

export default RoundedButton;