/**
 * {
 *      year: '',
 *      title: '',
 *      skills: [],
 *      body: ''
 *  }
 *
 * @type {[{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string}]}
 */
export const experienceGovernment = [
    {
        year: '2019',
        title: 'DVLA - Tacho card',
        skills: ['Java', 'Ruby', 'SQS', 'S3', 'MongoDB', 'Postgresql', 'ATF', 'Jira', 'RabbitMQ', 'kubernetes', 'Docker', 'RoR', 'Drone.io', 'Spinnaker'],
        body: 'The digital service, which is quicker and easier for drivers, is available 24/7. Drivers will receive their new tachograph card within 24 hours from applying. I worked with different squads/teams using a variety of technologies to deliver the service to a high standard.'
    },
    {
        year: '2019',
        title: 'DVLA - Tacho migration service',
        skills: ['Java', 'Ruby', 'S3', 'ATF', 'Postgresql', 'kubernetes', 'Jira', 'Docker', 'Drone.io', 'Spinnaker', 'AWS'],
        body: "I was a senior engineer in a large the team that designed and built the Tacho migration service, which migrates drivers’ data to ensure data backward compatibility with new services. This service which has a very short lifespan, played a crucial role in securing drivers’ data around the UK."
    },
    {
        year: '2020',
        title: 'DVLA – D90/driver domain',
        skills: ['Java', 'Ruby', 'Drone.io', 'Spinnaker', 'ATF', 'AWS Cloudwatch', 'AWS Cloudformation', 'Jira', 'Serverless'],
        body: 'Worked on one of the UK government’s largest databases with millions of drivers’ records. The project\'s goal was to upgrade the core system with a future-proof system that can be broken down into many robust microservices.'
    },
    {
        year: '2023',
        title: 'Microsoft/DVLA - Casework',
        skills: ['Dynamics 365', 'Azure EventHub', 'AWS serverless', 'Ruby', 'RoR', 'ATF', 'Drone.io', 'Spinnaker', 'Jira', 'X-Ray', 'MS DevOps'],
        body: 'Worked on a service that provides an easy-to-use platform for drivers to report and manage their medical conditions. Moreover, Casework allows doctors to review each case and reach a final decision. This project utilises Dynamics 365 alongside in-house built services.'
    },
];


