export const serverless = () => (
    <svg viewBox="0 0 197 148" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[35%] md:w-[30%]'>
            <rect x="1" y="11" width="195" height="135" fill="#EFEFEF"/>
            <path d="M0 3.28149C0 1.62464 1.34315 0.281494 3 0.281494H194C195.657 0.281494 197 1.62464 197 3.28149V11.2815H0V3.28149Z" fill="#231C1C"/>
            <path d="M194.248 0H2.75249C2.02274 0.000809725 1.3231 0.291061 0.807079 0.807079C0.291061 1.3231 0.000809725 2.02274 0 2.75249V144.309C0.000809725 145.039 0.291061 145.739 0.807079 146.255C1.3231 146.771 2.02274 147.061 2.75249 147.062H194.248C194.977 147.061 195.677 146.771 196.193 146.255C196.709 145.739 196.999 145.039 197 144.309V2.75249C196.999 2.02274 196.709 1.3231 196.193 0.807079C195.677 0.291061 194.977 0.000809725 194.248 0ZM196.214 144.309C196.213 144.831 196.006 145.331 195.637 145.699C195.269 146.068 194.769 146.275 194.248 146.275H2.75249C2.23113 146.275 1.73117 146.068 1.3625 145.699C0.993838 145.331 0.786636 144.831 0.786427 144.309V2.75249C0.786636 2.23113 0.993838 1.73117 1.3625 1.3625C1.73117 0.993838 2.23113 0.786636 2.75249 0.786427H194.248C194.769 0.786636 195.269 0.993838 195.637 1.3625C196.006 1.73117 196.213 2.23113 196.214 2.75249V144.309Z" fill="#3F3D56"/>
            <path d="M196.607 11.0256H0.393188V11.8121H196.607V11.0256Z" fill="#3F3D56"/>
            <path d="M7.07785 8.25738C8.38084 8.25738 9.43713 7.20109 9.43713 5.8981C9.43713 4.5951 8.38084 3.53882 7.07785 3.53882C5.77485 3.53882 4.71857 4.5951 4.71857 5.8981C4.71857 7.20109 5.77485 8.25738 7.07785 8.25738Z" fill="#EFEFEF"/>
            <path d="M13.8608 8.25738C15.1638 8.25738 16.2201 7.20109 16.2201 5.8981C16.2201 4.5951 15.1638 3.53882 13.8608 3.53882C12.5578 3.53882 11.5015 4.5951 11.5015 5.8981C11.5015 7.20109 12.5578 8.25738 13.8608 8.25738Z" fill="#EFEFEF"/>
            <path d="M20.6437 8.25738C21.9467 8.25738 23.003 7.20109 23.003 5.8981C23.003 4.5951 21.9467 3.53882 20.6437 3.53882C19.3407 3.53882 18.2844 4.5951 18.2844 5.8981C18.2844 7.20109 19.3407 8.25738 20.6437 8.25738Z" fill="#EFEFEF"/>
            <path d="M118.681 122.409C118.577 122.409 118.477 122.368 118.403 122.294C118.329 122.22 118.288 122.12 118.288 122.016V105.894C118.288 105.79 118.329 105.69 118.403 105.616C118.477 105.542 118.577 105.501 118.681 105.501C118.785 105.501 118.885 105.542 118.959 105.616C119.033 105.69 119.074 105.79 119.074 105.894V122.016C119.074 122.12 119.033 122.22 118.959 122.294C118.885 122.368 118.785 122.409 118.681 122.409Z" fill="#3F3D56"/>
            <path d="M118.142 99.6029C118.072 99.603 118.004 99.5845 117.944 99.5495L95.6038 86.5326C95.5139 86.48 95.4485 86.3939 95.422 86.2931C95.3955 86.1923 95.4101 86.0852 95.4625 85.9951C95.5149 85.9051 95.601 85.8396 95.7017 85.8129C95.8024 85.7862 95.9096 85.8006 95.9997 85.8529L118.34 98.8699C118.415 98.9134 118.473 98.9803 118.506 99.0603C118.539 99.1403 118.544 99.2289 118.522 99.3124C118.499 99.3958 118.45 99.4695 118.381 99.522C118.312 99.5746 118.228 99.603 118.142 99.6029Z" fill="#3F3D56"/>
            <path d="M73.4617 99.6029C73.3752 99.603 73.2911 99.5745 73.2224 99.522C73.1537 99.4695 73.1043 99.3958 73.0817 99.3123C73.0591 99.2289 73.0647 99.1403 73.0975 99.0603C73.1303 98.9803 73.1886 98.9134 73.2633 98.8698L95.6037 85.8529C95.6938 85.8009 95.8009 85.7868 95.9014 85.8135C96.0019 85.8403 96.0877 85.9058 96.1401 85.9956C96.1924 86.0855 96.207 86.1925 96.1807 86.2931C96.1544 86.3937 96.0893 86.4798 95.9996 86.5326L73.6592 99.5495C73.5992 99.5844 73.5311 99.6029 73.4617 99.6029Z" fill="#3F3D56"/>
            <path d="M141.341 119.264C141.272 119.264 141.204 119.245 141.144 119.21L118.803 106.193C118.713 106.141 118.648 106.055 118.622 105.954C118.595 105.853 118.61 105.746 118.662 105.656C118.715 105.566 118.801 105.5 118.901 105.474C119.002 105.447 119.109 105.461 119.199 105.514L141.54 118.531C141.615 118.574 141.673 118.641 141.706 118.721C141.738 118.801 141.744 118.89 141.721 118.973C141.699 119.056 141.649 119.13 141.581 119.183C141.512 119.235 141.428 119.264 141.341 119.264Z" fill="#3F3D56"/>
            <path d="M96.6612 119.264C96.5748 119.264 96.4907 119.235 96.422 119.183C96.3533 119.13 96.3039 119.056 96.2813 118.973C96.2587 118.89 96.2643 118.801 96.2971 118.721C96.3299 118.641 96.3882 118.574 96.4629 118.53L118.803 105.514C118.893 105.462 119 105.447 119.101 105.474C119.201 105.501 119.287 105.566 119.34 105.656C119.392 105.746 119.407 105.853 119.38 105.954C119.354 106.054 119.289 106.14 119.199 106.193L96.8588 119.21C96.7988 119.245 96.7306 119.264 96.6612 119.264Z" fill="#3F3D56"/>
            <path d="M95.8745 79.5489C95.7704 79.549 95.6705 79.5077 95.5968 79.4341C95.523 79.3606 95.4815 79.2608 95.4813 79.1567L95.4449 64.8488C95.4448 64.7971 95.4548 64.746 95.4744 64.6982C95.4941 64.6505 95.5229 64.607 95.5594 64.5704C95.5958 64.5338 95.6391 64.5048 95.6867 64.4849C95.7344 64.465 95.7855 64.4547 95.8371 64.4546H95.8381C95.9422 64.4546 96.0421 64.4959 96.1158 64.5694C96.1895 64.6429 96.2311 64.7427 96.2313 64.8468L96.2677 79.1548C96.2678 79.2064 96.2578 79.2576 96.2381 79.3054C96.2185 79.3531 96.1896 79.3965 96.1532 79.4331C96.1168 79.4697 96.0735 79.4988 96.0259 79.5187C95.9782 79.5385 95.9271 79.5488 95.8754 79.549L95.8745 79.5489Z" fill="#3F3D56"/>
            <path d="M100.96 86.9869H90.7162C90.247 86.9864 89.7973 86.7998 89.4656 86.4681C89.1338 86.1364 88.9472 85.6866 88.9467 85.2175V79.9557C88.9472 79.4866 89.1338 79.0369 89.4656 78.7051C89.7973 78.3734 90.247 78.1868 90.7162 78.1863H100.96C101.429 78.1868 101.879 78.3734 102.211 78.7051C102.543 79.0369 102.729 79.4866 102.73 79.9557V85.2175C102.729 85.6866 102.543 86.1364 102.211 86.4681C101.879 86.7998 101.429 86.9864 100.96 86.9869Z" fill="#CCCCCC"/>
            <path d="M84.0052 60.2405H62.208C62.1037 60.2405 62.0037 60.1991 61.93 60.1254C61.8562 60.0516 61.8148 59.9516 61.8148 59.8473C61.8148 59.743 61.8562 59.643 61.93 59.5693C62.0037 59.4955 62.1037 59.4541 62.208 59.4541H84.0052C84.1095 59.4541 84.2095 59.4955 84.2833 59.5693C84.357 59.643 84.3984 59.743 84.3984 59.8473C84.3984 59.9516 84.357 60.0516 84.2833 60.1254C84.2095 60.1991 84.1095 60.2405 84.0052 60.2405Z" fill="#3F3D56"/>
            <path d="M95.8381 54.6242C95.7338 54.6242 95.6338 54.5828 95.5601 54.509C95.4863 54.4353 95.4449 54.3353 95.4449 54.231V40.6208C95.4449 40.5165 95.4863 40.4165 95.56 40.3427C95.6338 40.269 95.7338 40.2275 95.8381 40.2275C95.9424 40.2275 96.0424 40.269 96.1161 40.3427C96.1899 40.4165 96.2313 40.5165 96.2313 40.6208V54.231C96.2313 54.3353 96.1899 54.4353 96.1161 54.509C96.0424 54.5828 95.9424 54.6242 95.8381 54.6242Z" fill="#3F3D56"/>
            <path d="M53.7275 60.2405C53.6571 60.2405 53.5879 60.2215 53.5274 60.1856L32.3666 47.6436C32.2771 47.5903 32.2125 47.5037 32.1868 47.4028C32.1612 47.3019 32.1766 47.1949 32.2296 47.1054C32.2827 47.0158 32.3692 46.9509 32.47 46.925C32.5708 46.8991 32.6778 46.9142 32.7676 46.967L53.9284 59.509C54.0025 59.5529 54.0602 59.6201 54.0925 59.7C54.1248 59.7799 54.1299 59.8682 54.107 59.9514C54.0842 60.0345 54.0347 60.1078 53.9661 60.1601C53.8975 60.2123 53.8137 60.2406 53.7275 60.2405Z" fill="#3F3D56"/>
            <path d="M32.5675 72.7825C32.4813 72.7826 32.3974 72.7543 32.3288 72.7021C32.2603 72.6499 32.2108 72.5765 32.1879 72.4934C32.1651 72.4103 32.1702 72.3219 32.2025 72.242C32.2348 72.1621 32.2924 72.095 32.3666 72.051L53.5274 59.5091C53.6171 59.4559 53.7243 59.4405 53.8253 59.4664C53.9264 59.4922 54.013 59.5571 54.0662 59.6469C54.1194 59.7366 54.1347 59.8437 54.1089 59.9448C54.083 60.0458 54.0181 60.1325 53.9284 60.1857L32.7676 72.7276C32.707 72.7636 32.6379 72.7825 32.5675 72.7825Z" fill="#3F3D56"/>
            <path d="M60.6352 66.5831H55.3735C54.9043 66.5826 54.4546 66.396 54.1228 66.0643C53.7911 65.7326 53.6045 65.2828 53.604 64.8137V54.5698C53.6045 54.1006 53.7911 53.6509 54.1228 53.3191C54.4546 52.9874 54.9043 52.8008 55.3735 52.8003H60.6352C61.1044 52.8008 61.5541 52.9874 61.8859 53.3191C62.2176 53.6509 62.4042 54.1006 62.4047 54.5698V64.8137C62.4042 65.2828 62.2176 65.7326 61.8859 66.0643C61.5541 66.396 61.1044 66.5826 60.6352 66.5831Z" fill="#CCCCCC"/>
            <path d="M100.96 41.679H90.7162C90.247 41.6785 89.7973 41.4919 89.4656 41.1602C89.1338 40.8285 88.9472 40.3787 88.9467 39.9096V34.6476C88.9472 34.1785 89.1338 33.7288 89.4656 33.397C89.7973 33.0653 90.247 32.8787 90.7162 32.8782H100.96C101.429 32.8787 101.879 33.0653 102.211 33.397C102.543 33.7288 102.729 34.1785 102.73 34.6476V39.9096C102.729 40.3787 102.543 40.8285 102.211 41.1602C101.879 41.4919 101.429 41.6785 100.96 41.679Z" fill="#CCCCCC"/>
            <path d="M31.1442 54.3934H25.8824C25.4132 54.3929 24.9635 54.2063 24.6317 53.8746C24.3 53.5429 24.1134 53.0931 24.1129 52.624V42.3801C24.1134 41.9109 24.3 41.4612 24.6317 41.1294C24.9635 40.7977 25.4132 40.6111 25.8824 40.6106H31.1442C31.6133 40.6111 32.063 40.7977 32.3948 41.1294C32.7265 41.4612 32.9131 41.9109 32.9136 42.3801V52.624C32.9131 53.0931 32.7265 53.5429 32.3948 53.8746C32.063 54.2063 31.6133 54.3929 31.1442 54.3934Z" fill="#CCCCCC"/>
            <path d="M31.1442 79.1659H25.8824C25.4132 79.1654 24.9635 78.9788 24.6317 78.647C24.3 78.3153 24.1134 77.8656 24.1129 77.3964V67.1525C24.1134 66.6834 24.3 66.2336 24.6317 65.9019C24.9635 65.5702 25.4132 65.3836 25.8824 65.3831H31.1442C31.6133 65.3836 32.063 65.5702 32.3948 65.9019C32.7265 66.2336 32.9131 66.6834 32.9136 67.1525V77.3964C32.9131 77.8656 32.7265 78.3153 32.3948 78.647C32.063 78.9788 31.6133 79.1654 31.1442 79.1659Z" fill="#CCCCCC"/>
            <path d="M129.468 60.2405H107.671C107.567 60.2405 107.467 60.1991 107.393 60.1254C107.319 60.0516 107.278 59.9516 107.278 59.8473C107.278 59.743 107.319 59.643 107.393 59.5693C107.467 59.4955 107.567 59.4541 107.671 59.4541H129.468C129.572 59.4541 129.672 59.4955 129.746 59.5693C129.82 59.643 129.861 59.743 129.861 59.8473C129.861 59.9516 129.82 60.0516 129.746 60.1254C129.672 60.1991 129.572 60.2405 129.468 60.2405Z" fill="#3F3D56"/>
            <path d="M137.949 60.2405C137.862 60.2406 137.779 60.2123 137.71 60.1601C137.641 60.1078 137.592 60.0345 137.569 59.9514C137.546 59.8682 137.551 59.7799 137.584 59.7C137.616 59.62 137.674 59.5529 137.748 59.509L158.909 46.967C158.953 46.9407 159.002 46.9234 159.053 46.9161C159.104 46.9088 159.157 46.9116 159.207 46.9244C159.257 46.9372 159.304 46.9597 159.345 46.9907C159.386 47.0217 159.421 47.0605 159.447 47.1049C159.474 47.1493 159.491 47.1985 159.498 47.2496C159.506 47.3007 159.503 47.3528 159.49 47.4028C159.477 47.4528 159.455 47.4999 159.424 47.5412C159.393 47.5825 159.354 47.6173 159.31 47.6436L138.149 60.1856C138.088 60.2216 138.019 60.2405 137.949 60.2405Z" fill="#3F3D56"/>
            <path d="M159.109 72.7825C159.038 72.7825 158.969 72.7635 158.909 72.7276L137.748 60.1856C137.658 60.1325 137.593 60.0458 137.567 59.9448C137.542 59.8438 137.557 59.7366 137.61 59.6469C137.663 59.5572 137.75 59.4922 137.851 59.4664C137.952 59.4405 138.059 59.4559 138.149 59.509L159.31 72.051C159.384 72.0949 159.441 72.162 159.474 72.242C159.506 72.3219 159.511 72.4102 159.488 72.4934C159.466 72.5765 159.416 72.6499 159.347 72.7021C159.279 72.7543 159.195 72.7826 159.109 72.7825Z" fill="#3F3D56"/>
            <path d="M136.303 66.5831H131.041C130.572 66.5826 130.122 66.396 129.79 66.0643C129.459 65.7326 129.272 65.2828 129.271 64.8137V54.5698C129.272 54.1006 129.459 53.6509 129.79 53.3191C130.122 52.9874 130.572 52.8008 131.041 52.8003H136.303C136.772 52.8008 137.222 52.9874 137.553 53.3191C137.885 53.6509 138.072 54.1006 138.072 54.5698V64.8137C138.072 65.2828 137.885 65.7326 137.553 66.0643C137.222 66.396 136.772 66.5826 136.303 66.5831Z" fill="#CCCCCC"/>
            <path d="M165.794 54.3934H160.532C160.063 54.3929 159.613 54.2063 159.281 53.8746C158.95 53.5429 158.763 53.0931 158.763 52.624V42.3801C158.763 41.9109 158.95 41.4612 159.281 41.1294C159.613 40.7977 160.063 40.6111 160.532 40.6106H165.794C166.263 40.6111 166.713 40.7977 167.044 41.1294C167.376 41.4612 167.563 41.9109 167.563 42.3801V52.624C167.563 53.0931 167.376 53.5429 167.044 53.8746C166.713 54.2063 166.263 54.3929 165.794 54.3934Z" fill="#CCCCCC"/>
            <path d="M165.794 79.1659H160.532C160.063 79.1654 159.613 78.9788 159.281 78.647C158.95 78.3153 158.763 77.8656 158.763 77.3964V67.1525C158.763 66.6834 158.95 66.2336 159.281 65.9019C159.613 65.5702 160.063 65.3836 160.532 65.3831H165.794C166.263 65.3836 166.713 65.5702 167.044 65.9019C167.376 66.2336 167.563 66.6834 167.563 67.1525V77.3964C167.563 77.8656 167.376 78.3153 167.044 78.647C166.713 78.9788 166.263 79.1654 165.794 79.1659Z" fill="#CCCCCC"/>
            <path d="M78.1537 107.041H67.9097C67.4406 107.04 66.9908 106.854 66.6591 106.522C66.3274 106.19 66.1408 105.741 66.1403 105.271V100.01C66.1408 99.5406 66.3274 99.0908 66.6591 98.7591C66.9908 98.4273 67.4406 98.2408 67.9097 98.2402H78.1537C78.6229 98.2408 79.0726 98.4273 79.4043 98.7591C79.7361 99.0908 79.9227 99.5406 79.9232 100.01V105.271C79.9227 105.741 79.7361 106.19 79.4043 106.522C79.0726 106.854 78.6229 107.04 78.1537 107.041Z" fill="#CCCCCC"/>
            <path d="M123.694 107.041H113.45C112.981 107.04 112.531 106.854 112.199 106.522C111.867 106.19 111.681 105.741 111.68 105.271V100.01C111.681 99.5406 111.867 99.0908 112.199 98.7591C112.531 98.4274 112.981 98.2408 113.45 98.2402H123.694C124.163 98.2408 124.613 98.4274 124.944 98.7591C125.276 99.0908 125.463 99.5406 125.463 100.01V105.271C125.463 105.741 125.276 106.19 124.944 106.522C124.613 106.854 124.163 107.04 123.694 107.041Z" fill="#CCCCCC"/>
            <path d="M102.853 126.308H92.6094C92.1403 126.308 91.6905 126.121 91.3588 125.79C91.0271 125.458 90.8405 125.008 90.84 124.539V119.277C90.8405 118.808 91.0271 118.358 91.3588 118.027C91.6905 117.695 92.1403 117.508 92.6094 117.508H102.853C103.323 117.508 103.772 117.695 104.104 118.027C104.436 118.358 104.622 118.808 104.623 119.277V124.539C104.622 125.008 104.436 125.458 104.104 125.79C103.772 126.121 103.323 126.308 102.853 126.308Z" fill="#CCCCCC"/>
            <path d="M146.5 126.308H136.256C135.787 126.308 135.337 126.121 135.006 125.79C134.674 125.458 134.487 125.008 134.487 124.539V119.277C134.487 118.808 134.674 118.358 135.006 118.027C135.337 117.695 135.787 117.508 136.256 117.508H146.5C146.969 117.508 147.419 117.695 147.751 118.027C148.082 118.358 148.269 118.808 148.27 119.277V124.539C148.269 125.008 148.082 125.458 147.751 125.79C147.419 126.121 146.969 126.308 146.5 126.308Z" fill="#CCCCCC"/>
            <path d="M123.694 126.308H113.45C112.981 126.308 112.531 126.121 112.199 125.79C111.867 125.458 111.681 125.008 111.68 124.539V119.277C111.681 118.808 111.867 118.358 112.199 118.027C112.531 117.695 112.981 117.508 113.45 117.508H123.694C124.163 117.508 124.613 117.695 124.944 118.027C125.276 118.358 125.463 118.808 125.463 119.277V124.539C125.463 125.008 125.276 125.458 124.944 125.79C124.613 126.121 124.163 126.308 123.694 126.308Z" fill="#CCCCCC"/>
            <path d="M108.291 66.6878H83.3853C82.9162 66.6872 82.4664 66.5006 82.1347 66.1689C81.803 65.8372 81.6164 65.3874 81.6158 64.9183V54.7765C81.6164 54.3074 81.803 53.8577 82.1347 53.5259C82.4664 53.1942 82.9162 53.0076 83.3853 53.0071H108.291C108.76 53.0076 109.21 53.1942 109.541 53.5259C109.873 53.8577 110.06 54.3074 110.06 54.7765V64.9183C110.06 65.3874 109.873 65.8372 109.541 66.1689C109.21 66.5006 108.76 66.6872 108.291 66.6878Z" fill="#0284C7"/>
    </svg>

);