export const devops = () => (
    <svg  viewBox="0 0 184 125" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[40%] md:w-[35%]'>
            <path d="M108.5 102C134.181 102 155 81.1812 155 55.5C155 29.8188 134.181 9 108.5 9C82.8188 9 62 29.8188 62 55.5C62 81.1812 82.8188 102 108.5 102Z" fill="#EFEFEF"/>
            <path d="M99 29H22V30H99V29Z" fill="#3F3D56"/>
            <path d="M127 94C101.084 94 80 72.9158 80 47C80 21.0842 101.084 0 127 0C152.916 0 174 21.0842 174 47C174 72.9158 152.916 94 127 94ZM127 0.505376C101.363 0.505376 80.5054 21.3628 80.5054 47C80.5054 72.6372 101.363 93.4946 127 93.4946C152.637 93.4946 173.495 72.6372 173.495 47C173.495 21.3628 152.637 0.505376 127 0.505376Z" fill="#3F3D56"/>
            <path d="M80.5 48C82.9853 48 85 45.9853 85 43.5C85 41.0147 82.9853 39 80.5 39C78.0147 39 76 41.0147 76 43.5C76 45.9853 78.0147 48 80.5 48Z" fill="#231C1C"/>
            <path d="M175.5 52C180.194 52 184 47.9706 184 43C184 38.0294 180.194 34 175.5 34C170.806 34 167 38.0294 167 43C167 47.9706 170.806 52 175.5 52Z" fill="#0284C7"/>
            <path d="M47.3744 79H19.7842V79.5375H47.3744C48.7327 79.5375 50.0355 80.113 50.996 81.1374C51.9565 82.1618 52.4961 83.5512 52.4961 85C52.4961 86.4488 51.9565 87.8382 50.996 88.8626C50.0355 89.887 48.7327 90.4625 47.3744 90.4625H5.6256C4.26725 90.4625 2.96454 89.887 2.00404 88.8626C1.04354 87.8382 0.503936 86.4488 0.503936 85C0.503936 83.5512 1.04354 82.1618 2.00404 81.1374C2.96454 80.113 4.26725 79.5375 5.6256 79.5375H7.18576V79H5.6256C4.1336 79 2.70271 79.6321 1.6477 80.7574C0.592698 81.8826 0 83.4087 0 85C0 86.5913 0.592698 88.1174 1.6477 89.2426C2.70271 90.3679 4.1336 91 5.6256 91H47.3744C48.8664 91 50.2973 90.3679 51.3523 89.2426C52.4073 88.1174 53 86.5913 53 85C53 83.4087 52.4073 81.8826 51.3523 80.7574C50.2973 79.6321 48.8664 79 47.3744 79Z" fill="#3F3D56"/>
            <path d="M47.3744 96H19.7842V96.5375H47.3744C48.7327 96.5375 50.0355 97.113 50.996 98.1374C51.9565 99.1618 52.4961 100.551 52.4961 102C52.4961 103.449 51.9565 104.838 50.996 105.863C50.0355 106.887 48.7327 107.463 47.3744 107.463H5.6256C4.26725 107.463 2.96454 106.887 2.00404 105.863C1.04354 104.838 0.503936 103.449 0.503936 102C0.503936 100.551 1.04354 99.1618 2.00404 98.1374C2.96454 97.113 4.26725 96.5375 5.6256 96.5375H7.18576V96H5.6256C4.1336 96 2.70271 96.6321 1.6477 97.7574C0.592698 98.8826 0 100.409 0 102C0 103.591 0.592698 105.117 1.6477 106.243C2.70271 107.368 4.1336 108 5.6256 108H47.3744C48.8664 108 50.2973 107.368 51.3523 106.243C52.4073 105.117 53 103.591 53 102C53 100.409 52.4073 98.8826 51.3523 97.7574C50.2973 96.6321 48.8664 96 47.3744 96Z" fill="#3F3D56"/>
            <path d="M47.3744 114H19.7842V114.493H47.3744C48.7327 114.493 50.0355 115.02 50.996 115.959C51.9565 116.898 52.4961 118.172 52.4961 119.5C52.4961 120.828 51.9565 122.102 50.996 123.041C50.0355 123.98 48.7327 124.507 47.3744 124.507H5.6256C4.26725 124.507 2.96454 123.98 2.00404 123.041C1.04354 122.102 0.503936 120.828 0.503936 119.5C0.503936 118.172 1.04354 116.898 2.00404 115.959C2.96454 115.02 4.26725 114.493 5.6256 114.493H7.18576V114H5.6256C4.1336 114 2.70271 114.579 1.6477 115.611C0.592698 116.642 0 118.041 0 119.5C0 120.959 0.592698 122.358 1.6477 123.389C2.70271 124.421 4.1336 125 5.6256 125H47.3744C48.8664 125 50.2973 124.421 51.3523 123.389C52.4073 122.358 53 120.959 53 119.5C53 118.041 52.4073 116.642 51.3523 115.611C50.2973 114.579 48.8664 114 47.3744 114Z" fill="#3F3D56"/>
            <path d="M10.3864 77.0003C10.0166 77.009 9.66402 77.2236 9.40468 77.5977C9.14534 77.9717 9 78.4754 9 79C9 79.5247 9.14534 80.0284 9.40468 80.4024C9.66402 80.7765 10.0166 80.9911 10.3864 80.9998H16.5712C16.9444 81.0069 17.3043 80.8038 17.5721 80.4351C17.8399 80.0663 17.9937 79.5621 17.9998 79.0328C18.0059 78.5035 17.8639 77.9923 17.6048 77.6113C17.3457 77.2303 16.9907 77.0106 16.6175 77.0003C16.6021 76.9999 16.5867 76.9999 16.5712 77.0003H10.3864Z" fill="#0284C7"/>
            <path d="M10.3864 96.0001C10.0166 96.0045 9.66402 96.1118 9.40468 96.2988C9.14534 96.4859 9 96.7377 9 97C9 97.2624 9.14534 97.5142 9.40468 97.7012C9.66402 97.8883 10.0166 97.9955 10.3864 97.9999H16.5712C16.9444 98.0034 17.3043 97.9019 17.5721 97.7175C17.8399 97.5332 17.9937 97.281 17.9998 97.0164C18.0059 96.7517 17.8639 96.4962 17.6048 96.3057C17.3457 96.1152 16.9907 96.0053 16.6175 96.0001C16.6021 96 16.5867 96 16.5712 96.0001H10.3864Z" fill="#231C1C"/>
            <path d="M10.3863 112C10.0165 112.009 9.66399 112.223 9.40466 112.597C9.14533 112.972 9 113.475 9 114C9 114.525 9.14533 115.028 9.40466 115.403C9.66399 115.777 10.0165 115.991 10.3863 116H16.5689C16.9424 116.008 17.3029 115.805 17.5712 115.436C17.8395 115.068 17.9937 114.563 17.9998 114.033C18.0059 113.503 17.8636 112.991 17.6039 112.61C17.3443 112.229 16.9887 112.01 16.6152 112C16.5998 112 16.5843 112 16.5689 112H10.3863Z" fill="#3F3D56"/>
            <path d="M7.48932 84.0001C7.09207 84.0045 6.71332 84.1118 6.43472 84.2988C6.15612 84.4858 6 84.7376 6 85C6 85.2623 6.15612 85.5141 6.43472 85.7012C6.71332 85.8882 7.09207 85.9955 7.48932 85.9998H45.4569C45.656 86.0023 45.854 85.9788 46.0394 85.9307C46.2248 85.8826 46.394 85.8108 46.5373 85.7195C46.6807 85.6281 46.7953 85.519 46.8746 85.3984C46.954 85.2777 46.9965 85.1479 46.9998 85.0164C47.0031 84.8849 46.967 84.7543 46.8937 84.632C46.8204 84.5097 46.7113 84.3981 46.5725 84.3037C46.4338 84.2093 46.2683 84.1339 46.0854 84.0818C45.9025 84.0297 45.7058 84.002 45.5066 84.0001C45.49 84 45.4734 84 45.4569 84.0001H7.48932Z" fill="#E6E6E6"/>
            <path d="M7.48932 101C7.09207 101.007 6.71332 101.168 6.43472 101.448C6.15612 101.729 6 102.106 6 102.5C6 102.893 6.15612 103.271 6.43472 103.552C6.71332 103.832 7.09207 103.993 7.48932 104H45.4569C45.656 104.003 45.854 103.968 46.0394 103.896C46.2248 103.824 46.394 103.716 46.5373 103.579C46.6807 103.442 46.7953 103.279 46.8746 103.098C46.954 102.917 46.9965 102.722 46.9998 102.525C47.0031 102.327 46.967 102.131 46.8937 101.948C46.8204 101.765 46.7113 101.597 46.5725 101.456C46.4338 101.314 46.2683 101.201 46.0854 101.123C45.9025 101.045 45.7058 101.003 45.5066 101C45.49 101 45.4734 101 45.4569 101H7.48932Z" fill="#E6E6E6"/>
            <path d="M7.48932 118C7.09207 118.004 6.71332 118.112 6.43472 118.299C6.15612 118.486 6 118.738 6 119C6 119.262 6.15612 119.514 6.43472 119.701C6.71332 119.888 7.09207 119.995 7.48932 120H45.4569C45.656 120.002 45.854 119.979 46.0394 119.931C46.2248 119.883 46.394 119.811 46.5373 119.72C46.6807 119.628 46.7953 119.519 46.8746 119.398C46.954 119.278 46.9965 119.148 46.9998 119.016C47.0031 118.885 46.967 118.754 46.8937 118.632C46.8204 118.51 46.7113 118.398 46.5725 118.304C46.4338 118.209 46.2683 118.134 46.0854 118.082C45.9025 118.03 45.7058 118.002 45.5066 118C45.49 118 45.4734 118 45.4569 118H7.48932Z" fill="#E6E6E6"/>
    </svg>

);