export const microservices = () =>(
    <svg viewBox="0 0 194 122" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[40%] md:w-[35%]'>
        <path d="M150.29 54.1036H52.5167C50.1602 54.1009 47.9009 53.1636 46.2346 51.4973C44.5683 49.831 43.6309 47.5717 43.6283 45.2151V8.88845C43.631 6.53191 44.5683 4.27265 46.2346 2.60632C47.9009 0.939999 50.1602 0.00267983 52.5167 0H150.29C152.646 0.00267881 154.905 0.939998 156.572 2.60632C158.238 4.27265 159.175 6.53191 159.178 8.88845V45.2151C159.175 47.5717 158.238 49.831 156.572 51.4973C154.905 53.1636 152.646 54.1009 150.29 54.1036Z" fill="#EFEFEF"/>
        <path d="M150.29 54.1036H52.5167C50.1602 54.1009 47.9009 53.1636 46.2346 51.4973C44.5683 49.831 43.6309 47.5717 43.6283 45.2151V8.88845C43.631 6.53191 44.5683 4.27265 46.2346 2.60632C47.9009 0.939999 50.1602 0.00267983 52.5167 0H150.29C152.646 0.00267881 154.905 0.939998 156.572 2.60632C158.238 4.27265 159.175 6.53191 159.178 8.88845V45.2151C159.175 47.5717 158.238 49.831 156.572 51.4973C154.905 53.1636 152.646 54.1009 150.29 54.1036ZM52.5167 0.772908C50.3651 0.775321 48.3023 1.63112 46.7809 3.15256C45.2594 4.674 44.4036 6.73681 44.4012 8.88845V45.2151C44.4036 47.3668 45.2595 49.4296 46.7809 50.951C48.3023 52.4724 50.3651 53.3282 52.5167 53.3307H150.29C152.441 53.3282 154.504 52.4724 156.025 50.951C157.547 49.4296 158.403 47.3668 158.405 45.2151V8.88845C158.403 6.73681 157.547 4.674 156.026 3.15256C154.504 1.63112 152.441 0.775321 150.29 0.772908H52.5167Z" fill="#231C1C"/>
        <path d="M187.286 59.0474H135.281C134.953 59.0436 134.626 59.0022 134.307 58.924C133.356 58.6954 132.51 58.1525 131.905 57.3831C131.3 56.6137 130.973 55.6628 130.976 54.6843V37.0078C130.978 35.8516 131.438 34.7433 132.256 33.9261C133.074 33.1089 134.183 32.6495 135.339 32.6487H187.286C187.986 32.6469 188.677 32.8158 189.297 33.1408C189.544 33.2689 189.777 33.4213 189.994 33.5958C190.512 34.0009 190.93 34.5189 191.217 35.1102C191.503 35.7015 191.651 36.3506 191.649 37.0078V54.6843C191.648 55.8411 191.188 56.9501 190.37 57.7681C189.552 58.5861 188.443 59.0461 187.286 59.0474Z" fill="#EFEFEF"/>
        <path d="M183.8 58.661C186.162 52.9221 188.649 47.24 191.263 41.6145V51.1522C189.481 53.8636 187.419 56.3804 185.111 58.661H183.8Z" fill="#8E8E8E"/>
        <path d="M189.752 33.897C185.08 42.2792 180.191 50.5338 175.086 58.661H174.197C179.387 50.4063 184.361 42.0138 189.118 33.4835C189.343 33.5999 189.555 33.7384 189.752 33.897Z" fill="#8E8E8E"/>
        <path d="M155.466 43.0482C152.412 48.4465 149.043 53.6598 145.375 58.661H140.077C143.547 54.4332 147.02 50.2054 150.496 45.9775C151.493 44.7602 152.575 43.4849 154.071 42.9902L155.466 43.0482Z" fill="#8E8E8E"/>
        <path d="M148.424 42.1826C147.872 42.7584 147.342 43.3574 146.844 43.9796C145.746 45.3013 144.664 46.6345 143.578 47.9639C142.902 48.7909 142.226 49.6179 141.557 50.445C141.345 50.3086 141.143 50.1561 140.954 49.9889C139.887 49.0621 139.224 47.7539 139.109 46.3446C138.994 44.9353 139.436 43.5371 140.34 42.4495C141.243 41.362 142.537 40.6716 143.943 40.5264C145.35 40.3811 146.757 40.7925 147.864 41.6725C148.064 41.828 148.251 41.9986 148.424 42.1826Z" fill="#0284C7"/>
        <path d="M178.309 45.9389L168.153 42.2251L163.11 40.3817C161.467 39.7827 159.829 39.0986 158.132 38.6658C156.657 38.2442 155.09 38.2643 153.626 38.7238C152.321 39.2069 151.126 39.9482 150.113 40.9034C149.665 41.3169 149.231 41.7433 148.811 42.1826C148.258 42.7584 147.729 43.3574 147.23 43.9796C146.133 45.3012 145.051 46.6345 143.965 47.9639C143.288 48.7909 142.612 49.6179 141.944 50.4449C139.71 53.1772 137.489 55.9158 135.281 58.661C134.984 58.6575 134.688 58.6199 134.4 58.5489C136.703 55.6891 139.017 52.8358 141.341 49.9889C141.607 49.6566 141.882 49.3204 142.152 48.988C143.285 47.6006 144.418 46.2146 145.553 44.8298C146.43 43.7593 147.3 42.6811 148.251 41.6724C148.336 41.5797 148.421 41.4908 148.509 41.3981C150.438 39.404 152.845 37.6417 155.728 37.5953C157.425 37.5683 159.064 38.1055 160.64 38.6735C162.337 39.288 164.029 39.9141 165.726 40.5363L175.952 44.2771L178.51 45.2124C178.962 45.3747 178.769 46.1089 178.309 45.9389Z" fill="#8E8E8E"/>
        <path d="M187.286 59.0474H135.281C134.953 59.0436 134.626 59.0022 134.307 58.924C133.356 58.6954 132.51 58.1525 131.905 57.3831C131.3 56.6137 130.973 55.6628 130.976 54.6843V37.0078C130.978 35.8516 131.438 34.7433 132.256 33.9261C133.074 33.1089 134.183 32.6495 135.339 32.6487H187.286C187.986 32.6469 188.677 32.8158 189.297 33.1408C189.544 33.2689 189.777 33.4213 189.994 33.5958C190.512 34.0009 190.93 34.5189 191.217 35.1102C191.503 35.7015 191.651 36.3506 191.649 37.0078V54.6843C191.648 55.8411 191.188 56.9501 190.37 57.7681C189.552 58.5861 188.443 59.0461 187.286 59.0474ZM135.339 33.4216C134.388 33.4222 133.476 33.8001 132.802 34.4724C132.129 35.1447 131.751 36.0565 131.749 37.0078V54.6843C131.747 55.4896 132.016 56.2721 132.513 56.9053C133.011 57.5385 133.708 57.9853 134.491 58.1734C134.751 58.2372 135.018 58.2711 135.286 58.2745H187.286C188.238 58.2734 189.151 57.8948 189.824 57.2218C190.497 56.5487 190.875 55.6362 190.876 54.6844V37.0078C190.878 36.467 190.756 35.9329 190.52 35.4464C190.283 34.9599 189.939 34.5339 189.513 34.2008C189.335 34.0573 189.143 33.932 188.941 33.8268C188.43 33.5592 187.862 33.4201 187.286 33.4216H135.339Z" fill="#231C1C"/>
        <path d="M83.8606 75.9337H8.88845C6.5319 75.931 4.27262 74.9937 2.60629 73.3274C0.939956 71.661 0.00264717 69.4018 0 67.0452V53.9058C0.00267881 51.5492 0.939998 49.29 2.60632 47.6237C4.27265 45.9573 6.53191 45.02 8.88845 45.0173H83.8606C86.2171 45.02 88.4763 45.9573 90.1427 47.6237C91.809 49.29 92.7463 51.5492 92.749 53.9058V67.0452C92.7464 69.4018 91.809 71.661 90.1427 73.3274C88.4764 74.9937 86.2171 75.931 83.8606 75.9337Z" fill="#EFEFEF"/>
        <path d="M83.8606 75.9337H8.88845C6.5319 75.931 4.27262 74.9937 2.60629 73.3274C0.939956 71.661 0.00264717 69.4018 0 67.0452V53.9058C0.00267881 51.5492 0.939998 49.29 2.60632 47.6237C4.27265 45.9573 6.53191 45.02 8.88845 45.0173H83.8606C86.2171 45.02 88.4763 45.9573 90.1427 47.6237C91.809 49.29 92.7463 51.5492 92.749 53.9058V67.0452C92.7464 69.4018 91.809 71.661 90.1427 73.3274C88.4764 74.9937 86.2171 75.931 83.8606 75.9337ZM8.88845 45.7902C6.73681 45.7927 4.674 46.6485 3.15256 48.1699C1.63112 49.6913 0.775321 51.7541 0.772908 53.9058V67.0452C0.775353 69.1968 1.63117 71.2596 3.15259 72.7811C4.67402 74.3025 6.73682 75.1583 8.88845 75.1608H83.8606C86.0122 75.1583 88.075 74.3025 89.5964 72.7811C91.1178 71.2596 91.9736 69.1968 91.9761 67.0452V53.9058C91.9737 51.7541 91.1179 49.6913 89.5964 48.1699C88.075 46.6485 86.0122 45.7927 83.8606 45.7902H8.88845Z" fill="#231C1C"/>
        <path d="M30.9021 68.5431H18.8492C18.2049 68.5424 17.5873 68.2862 17.1317 67.8306C16.6762 67.3751 16.4199 66.7574 16.4192 66.1131V54.8379C16.4199 54.1936 16.6762 53.576 17.1317 53.1204C17.5873 52.6648 18.2049 52.4086 18.8492 52.4079H30.9021C31.5463 52.4086 32.164 52.6648 32.6196 53.1204C33.0751 53.576 33.3314 54.1936 33.3321 54.8379V66.1131C33.3314 66.7574 33.0751 67.3751 32.6196 67.8306C32.164 68.2862 31.5463 68.5424 30.9021 68.5431Z" fill="#0284C7"/>
        <path d="M52.401 68.5431H40.3481C39.7038 68.5424 39.0862 68.2862 38.6306 67.8306C38.1751 67.3751 37.9188 66.7574 37.9181 66.1131V54.8379C37.9188 54.1936 38.1751 53.576 38.6306 53.1204C39.0862 52.6648 39.7038 52.4086 40.3481 52.4079H52.401C53.0452 52.4086 53.6629 52.6648 54.1185 53.1204C54.574 53.576 54.8303 54.1936 54.831 54.8379V66.1131C54.8303 66.7574 54.574 67.3751 54.1185 67.8306C53.6629 68.2862 53.0452 68.5424 52.401 68.5431Z" fill="#8E8E8E"/>
        <path d="M73.8999 68.5431H61.847C61.2028 68.5424 60.5851 68.2862 60.1295 67.8306C59.674 67.3751 59.4177 66.7574 59.417 66.1131V54.8379C59.4177 54.1936 59.674 53.576 60.1295 53.1204C60.5851 52.6648 61.2028 52.4086 61.847 52.4079H73.8999C74.5441 52.4086 75.1618 52.6648 75.6174 53.1204C76.0729 53.576 76.3292 54.1936 76.3299 54.8379V66.1131C76.3292 66.7574 76.0729 67.3751 75.6174 67.8306C75.1618 68.2862 74.5441 68.5424 73.8999 68.5431Z" fill="#8E8E8E"/>
        <path d="M106.17 21.4437H90.7117C90.3017 21.4437 89.9086 21.2808 89.6187 20.9909C89.3288 20.701 89.1659 20.3078 89.1659 19.8978C89.1659 19.4879 89.3288 19.0947 89.6187 18.8048C89.9086 18.5149 90.3017 18.352 90.7117 18.352H106.17C106.58 18.352 106.973 18.5149 107.263 18.8048C107.553 19.0947 107.716 19.4879 107.716 19.8978C107.716 20.3078 107.553 20.701 107.263 20.9909C106.973 21.2808 106.58 21.4437 106.17 21.4437Z" fill="#3A3A3A"/>
        <path d="M139.405 29.9457H90.3254C89.9154 29.9457 89.5222 29.7828 89.2323 29.4929C88.9424 29.203 88.7795 28.8098 88.7795 28.3998C88.7795 27.9899 88.9424 27.5967 89.2323 27.3068C89.5222 27.0169 89.9154 26.854 90.3254 26.854H139.405C139.815 26.854 140.208 27.0169 140.498 27.3068C140.788 27.5967 140.951 27.9899 140.951 28.3998C140.951 28.8098 140.788 29.203 140.498 29.4929C140.208 29.7828 139.815 29.9457 139.405 29.9457Z" fill="#3A3A3A"/>
        <path d="M72.3537 34.5131C78.0777 34.5131 82.7179 29.8729 82.7179 24.1488C82.7179 18.4248 78.0777 13.7846 72.3537 13.7846C66.6296 13.7846 61.9894 18.4248 61.9894 24.1488C61.9894 29.8729 66.6296 34.5131 72.3537 34.5131Z" fill="#0284C7"/>
        <path d="M185.112 121.149H87.3386C84.9821 121.146 82.7228 120.209 81.0565 118.543C79.3902 116.876 78.4528 114.617 78.4502 112.26V75.9337C78.4529 73.5771 79.3902 71.3179 81.0565 69.6516C82.7228 67.9852 84.9821 67.0479 87.3386 67.0452H185.112C187.468 67.0479 189.727 67.9852 191.394 69.6516C193.06 71.3179 193.997 73.5771 194 75.9337V112.26C193.997 114.617 193.06 116.876 191.394 118.543C189.727 120.209 187.468 121.146 185.112 121.149Z" fill="#EFEFEF"/>
        <path d="M185.112 121.149H87.3386C84.9821 121.146 82.7228 120.209 81.0565 118.543C79.3902 116.876 78.4528 114.617 78.4502 112.26V75.9337C78.4529 73.5771 79.3902 71.3179 81.0565 69.6516C82.7228 67.9852 84.9821 67.0479 87.3386 67.0452H185.112C187.468 67.0479 189.727 67.9852 191.394 69.6516C193.06 71.3179 193.997 73.5771 194 75.9337V112.26C193.997 114.617 193.06 116.876 191.394 118.543C189.727 120.209 187.468 121.146 185.112 121.149ZM87.3386 67.8181C85.187 67.8205 83.1242 68.6764 81.6028 70.1978C80.0813 71.7192 79.2255 73.782 79.2231 75.9337V112.26C79.2255 114.412 80.0814 116.475 81.6028 117.996C83.1242 119.518 85.187 120.373 87.3386 120.376H185.112C187.263 120.373 189.326 119.518 190.847 117.996C192.369 116.475 193.225 114.412 193.227 112.26V75.9337C193.225 73.782 192.369 71.7192 190.847 70.1978C189.326 68.6764 187.263 67.8205 185.112 67.8181H87.3386Z" fill="#231C1C"/>
        <path d="M125.419 81.4382H108.996C108.79 81.4385 108.586 81.3982 108.395 81.3195C108.205 81.2408 108.032 81.1254 107.886 80.9797C107.74 80.834 107.624 80.661 107.545 80.4706C107.466 80.2802 107.426 80.076 107.426 79.8698C107.426 79.6637 107.466 79.4596 107.545 79.2691C107.624 79.0787 107.74 78.9057 107.886 78.76C108.032 78.6143 108.205 78.4989 108.395 78.4202C108.586 78.3415 108.79 78.3012 108.996 78.3015H125.419C125.835 78.3022 126.233 78.4677 126.527 78.7617C126.82 79.0558 126.985 79.4543 126.985 79.8698C126.985 80.2854 126.82 80.6839 126.527 80.978C126.233 81.272 125.835 81.4375 125.419 81.4382Z" fill="#0284C7"/>
        <path d="M162.234 87.8527H108.996C108.58 87.8527 108.181 87.6875 107.887 87.3933C107.593 87.0992 107.428 86.7003 107.428 86.2844C107.428 85.8684 107.593 85.4695 107.887 85.1754C108.181 84.8813 108.58 84.716 108.996 84.716H162.234C162.65 84.716 163.049 84.8813 163.343 85.1754C163.637 85.4695 163.803 85.8684 163.803 86.2844C163.803 86.7003 163.637 87.0992 163.343 87.3933C163.049 87.6875 162.65 87.8527 162.234 87.8527Z" fill="#8E8E8E"/>
        <path d="M162.234 95.2157H108.996C108.79 95.2157 108.586 95.1751 108.396 95.0963C108.206 95.0175 108.033 94.9019 107.887 94.7563C107.742 94.6107 107.626 94.4378 107.547 94.2475C107.469 94.0572 107.428 93.8533 107.428 93.6473C107.428 93.4414 107.469 93.2374 107.547 93.0472C107.626 92.8569 107.742 92.684 107.887 92.5384C108.033 92.3927 108.206 92.2772 108.396 92.1984C108.586 92.1196 108.79 92.079 108.996 92.079H162.234C162.65 92.079 163.049 92.2442 163.343 92.5384C163.637 92.8325 163.803 93.2314 163.803 93.6473C163.803 94.0633 163.637 94.4622 163.343 94.7563C163.049 95.0504 162.65 95.2157 162.234 95.2157Z" fill="#8E8E8E"/>
        <path d="M162.234 102.579H108.996C108.79 102.579 108.586 102.539 108.395 102.46C108.205 102.381 108.032 102.266 107.886 102.12C107.74 101.975 107.624 101.802 107.545 101.611C107.466 101.421 107.426 101.216 107.426 101.01C107.426 100.804 107.466 100.6 107.545 100.41C107.624 100.219 107.74 100.046 107.886 99.9005C108.032 99.7548 108.205 99.6393 108.395 99.5607C108.586 99.482 108.79 99.4417 108.996 99.442H162.234C162.65 99.4426 163.048 99.6082 163.342 99.9022C163.635 100.196 163.8 100.595 163.8 101.01C163.8 101.426 163.635 101.824 163.342 102.118C163.048 102.412 162.65 102.578 162.234 102.579Z" fill="#8E8E8E"/>
        <path d="M162.234 109.942H108.996C108.58 109.942 108.181 109.776 107.887 109.482C107.593 109.188 107.428 108.789 107.428 108.373C107.428 107.957 107.593 107.558 107.887 107.264C108.181 106.97 108.58 106.805 108.996 106.805H162.234C162.65 106.805 163.049 106.97 163.343 107.264C163.637 107.558 163.803 107.957 163.803 108.373C163.803 108.789 163.637 109.188 163.343 109.482C163.049 109.776 162.65 109.942 162.234 109.942Z" fill="#8E8E8E"/>
    </svg>

);