export const arrow = (is_primary) => {
  const colour = is_primary ? 'rgb(2 132 199)' : 'rgb(245 245 244)';

  return (
    <svg width="20" height="10" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.54244 1.28102C2.30824 0.56093 3.50192 0.560281 4.26851 1.27954L10.308 6.94624C11.4623 8.02921 13.2593 8.02921 14.4135 6.94624L20.453 1.27954C21.2196 0.560281 22.4133 0.56093 23.1791 1.28102C24.0152 2.06721 24.0152 3.39561 23.1791 4.1818L14.4159 12.422C13.261 13.5079 11.4605 13.5079 10.3057 12.422L1.54244 4.1818C0.706359 3.39561 0.706358 2.06721 1.54244 1.28102Z"
        fill={colour}
      />
    </svg>
  );
};
