import './App.css';
import Header from './components/sections/header';
import About from "./components/sections/about";
import CVCard from "./components/sections/CVCard";
import Services from "./components/sections/services";
import Awards from "./components/sections/awards";
import Experience from "./components/sections/experience";
import ContactMe from "./components/sections/contactMe";
import Footer from "./components/sections/footer";

function App() {
    return (
        <div className="flex flex-col bg-stone-100 w-[vw100]">

            {/* ----------- Page Harder -----------*/}
            <header className='z-50'>
                <Header/>
            </header>

            {/* ----------- Page Sections -----------*/}
            <div className="flex-1 z-40 selection:text-stone-100 bg-stone-100
                             selection:bg-sky-600 selection:underline w-full h-full
                             items-center flex flex-col gap-10 md:gap-12">

                <div className="h-[65vh] md:h-[70vh] w-full">
                    <About/>
                </div>

                <div className="md:h-[200px] w-full">
                    <CVCard/>
                </div>

                <div className='w-full'>
                    <Services/>
                </div>

                <div className='w-full'>
                    <Awards/>
                </div>

                <div className='w-full'>
                    <Experience/>
                </div>

                <div className='w-full pt-5 bg-gradient-radial from-sky-200
                                via-stone-100 to-stone-100'>
                    <ContactMe/>
                </div>
            </div>

            {/* ----------- Page Footer -----------*/}
            <footer className="flex pt-16">
                <Footer/>
            </footer>
        </div>
    );
}

export default App;
