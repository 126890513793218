export const Flower = () => (
    <svg viewBox="0 0 62 80" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[20%] md:w-[15%] max-w-lg'>
        <path
            d="M61.4055 26.4927C60.9094 25.6678 58.7631 26.551 58.3685 25.8024C57.9753 25.0564 59.9694 23.9198 61.1088 21.3234C61.3142 20.8551 62.609 17.9044 61.6505 16.994C59.8337 15.2681 51.52 22.3901 48.6105 20.0595C47.972 19.548 47.5259 18.5269 45.5935 16.8691C44.8247 16.2096 44.3598 15.9119 43.9099 16.0226C43.2715 16.1798 43.221 17.0223 42.5364 18.6788C41.5098 21.1631 40.9026 21.0131 40.3045 22.9951C39.8605 24.4665 39.9619 25.3219 39.4394 25.4886C38.7 25.7244 38.0195 24.1638 37.0824 24.3035C36.1262 24.4461 35.5481 26.2632 35.3656 27.6237C35.0229 30.1768 35.9738 31.8615 36.5008 33.633C37.0735 35.5581 37.4133 38.4858 36.0908 42.6304L23 79C25.89 71.537 34.2581 51.0791 37.7743 43.4768C38.7894 41.2822 39.9102 39.0562 42.2115 38.1776C44.4271 37.3318 47.47 37.8368 51.3494 36.9152C51.8024 36.8076 53.0597 36.4922 53.2179 35.763C53.3488 35.1602 52.6092 34.8237 52.7229 34.2591C52.8753 33.5017 54.3295 33.4924 56.1166 32.6187C57.1251 32.1071 58.0437 31.4389 58.837 30.6397C59.0405 30.4497 62.066 27.5908 61.4055 26.4927Z"
            fill="#F2F2F2"/>
        <path
            d="M10.1424 44.9869C9.59584 44.989 9.41857 46.3316 8.9384 46.3056C8.45996 46.2797 8.46949 44.9377 7.51842 43.588C7.34692 43.3446 6.26608 41.8107 5.54583 42.0195C4.18065 42.4152 5.31182 48.7236 3.33834 49.4871C2.51992 49.6258 1.70979 49.8128 0.911881 50.0471C0.368964 50.2358 0.0903074 50.3801 0.0166038 50.6393C-0.0879663 51.007 0.311493 51.2847 0.930324 52.1245C1.85838 53.384 1.6118 53.6441 2.41512 54.538C3.01149 55.2016 3.46061 55.4069 3.39367 55.7192C3.29899 56.1612 2.3385 56.0357 2.1403 56.5482C1.93807 57.0712 2.6661 57.9056 3.28245 58.4047C4.4391 59.3414 5.53747 59.3682 6.5578 59.634C8.24347 60.1241 9.74475 61.1359 10.8611 62.5342L25 80C22.1568 76.3137 14.4897 65.9846 11.7564 61.942C10.9673 60.775 10.1929 59.5455 10.4167 58.1265C10.6321 56.7603 11.7468 55.3832 12.3988 53.1588C12.4749 52.899 12.678 52.173 12.3649 51.8752C12.106 51.629 11.7301 51.8997 11.485 51.6735C11.1564 51.3702 11.5659 50.637 11.6456 49.4777C11.6815 48.818 11.6148 48.1565 11.4481 47.5188C11.4127 47.3596 10.8699 44.984 10.1424 44.9869Z"
            fill="#F2F2F2"/>
        <path
            d="M21.327 48L21 45.0112L21.1494 44.7846C21.7862 43.8997 22.1511 42.8406 22.1973 41.7424C22.198 41.588 22.191 41.4336 22.1839 41.2763C22.1031 40.4927 22.217 39.7009 22.5151 38.9745C22.7364 38.4708 23.358 37.3047 24.2924 37.4491C24.5131 37.4811 24.7183 37.5836 24.8787 37.742C24.8999 37.7074 24.9217 37.6727 24.945 37.6352C25.1437 37.3143 25.3952 37.0311 25.6884 36.7982C25.9202 36.6091 26.1293 36.3925 26.3114 36.1531C26.4326 35.9987 26.5271 35.863 26.6067 35.749C26.7148 35.5374 26.8739 35.3576 27.0689 35.2265C27.2638 35.0954 27.4882 35.0175 27.7208 35C27.9621 35.0378 28.1904 35.1369 28.3847 35.2881C28.5791 35.4393 28.7334 35.638 28.8336 35.8659C29.0918 36.2781 29.3084 36.7162 29.4798 37.1734C29.511 37.2613 29.5475 37.347 29.589 37.4303C29.7807 37.7189 31.3388 37.449 31.9259 37.3509C33.2444 37.1258 34.3853 36.9309 34.8554 37.7824C35.1923 38.3914 34.9421 39.1967 34.0893 40.2401C33.8525 40.5256 33.5844 40.7823 33.2902 41.005C33.406 41.0715 33.5073 41.1614 33.588 41.2694C33.6687 41.3773 33.7271 41.5009 33.7595 41.6328C33.9371 42.3572 33.329 43.0831 31.9527 43.7932C31.5008 44.0467 31.0124 44.225 30.5059 44.3214C30.2376 44.3657 29.9665 44.3903 29.6947 44.395C29.6898 44.5502 29.6489 44.702 29.5756 44.838C29.4143 45.0833 29.1848 45.2734 28.9169 45.3838C28.6489 45.4941 28.3548 45.5197 28.0725 45.4571C27.3543 45.407 26.6422 45.2896 25.9449 45.1064C25.508 44.9778 25.0563 44.9089 24.6017 44.9015C23.9379 44.9564 23.4263 45.5004 22.8259 46.2061L21.327 48Z"
            fill="#F2F2F2"/>
        <path
            d="M22.9999 30.7751L20.2418 31.9185L19.9883 31.8228C18.9844 31.3935 17.8843 31.2955 16.8284 31.5415C16.6814 31.5821 16.5364 31.6309 16.3887 31.6804C15.6643 31.975 14.8824 32.068 14.1156 31.9508C13.5803 31.8542 12.313 31.5162 12.2117 30.4995C12.1857 30.2599 12.2308 30.0177 12.3405 29.8073C12.3022 29.7945 12.2637 29.7809 12.2221 29.7666C11.8662 29.6446 11.5328 29.4572 11.2365 29.2128C10.9975 29.0209 10.7383 28.8601 10.4641 28.7337C10.2864 28.6482 10.1333 28.5858 10.0046 28.533C9.77579 28.4765 9.56419 28.3582 9.38981 28.1893C9.21543 28.0204 9.08403 27.8064 9.00805 27.5676C8.9824 27.3049 9.0183 27.0395 9.11247 26.7956C9.20665 26.5516 9.35611 26.3369 9.54724 26.1709C9.87322 25.7901 10.2345 25.4461 10.6254 25.1441C10.7009 25.0879 10.7732 25.0267 10.8417 24.9609C11.0672 24.683 10.4129 23.1245 10.1697 22.5364C9.61908 21.2168 9.14263 20.0749 9.83215 19.3547C10.3252 18.8389 11.1547 18.8849 12.3644 19.4977C12.6963 19.669 13.0087 19.8809 13.2956 20.1291C13.3292 19.9901 13.3889 19.8599 13.4709 19.7465C13.5529 19.6331 13.6556 19.5389 13.7726 19.4696C14.4161 19.0896 15.2615 19.5315 16.2879 20.7816C16.6442 21.1866 16.9384 21.65 17.1594 22.1543C17.2701 22.4232 17.3626 22.7003 17.4364 22.9835C17.5853 22.9471 17.7401 22.9491 17.8881 22.9894C18.382 23.1205 18.7163 23.5891 18.8604 24.3967C18.9961 25.1618 19.0663 25.9386 19.0701 26.7174C19.0594 27.2092 19.1092 27.7004 19.2182 28.1781C19.4398 28.8582 20.0876 29.2479 20.9119 29.6871L22.9999 30.7751Z"
            fill="#F2F2F2"/>
        <path
            d="M21.327 22L21 19.0112L21.1494 18.7846C21.7862 17.8997 22.1511 16.8406 22.1973 15.7424C22.198 15.588 22.191 15.4336 22.1839 15.2763C22.1031 14.4927 22.217 13.7009 22.5151 12.9745C22.7364 12.4708 23.358 11.3047 24.2924 11.4491C24.5131 11.4811 24.7183 11.5836 24.8787 11.742C24.8999 11.7074 24.9217 11.6727 24.945 11.6352C25.1437 11.3143 25.3952 11.0311 25.6884 10.7982C25.9202 10.6091 26.1293 10.3925 26.3114 10.1531C26.4326 9.99868 26.5271 9.86302 26.6067 9.74901C26.7148 9.53741 26.8739 9.35756 27.0689 9.22649C27.2638 9.09542 27.4882 9.01747 27.7208 9C27.9621 9.03781 28.1904 9.13685 28.3847 9.28809C28.5791 9.43933 28.7334 9.63796 28.8336 9.8659C29.0918 10.2781 29.3084 10.7162 29.4798 11.1734C29.511 11.2613 29.5475 11.347 29.589 11.4303C29.7807 11.7189 31.3388 11.449 31.9259 11.3509C33.2444 11.1258 34.3853 10.9309 34.8554 11.7824C35.1923 12.3914 34.9421 13.1967 34.0893 14.2401C33.8525 14.5256 33.5844 14.7823 33.2902 15.005C33.406 15.0715 33.5073 15.1614 33.588 15.2694C33.6687 15.3773 33.7271 15.5009 33.7595 15.6328C33.9371 16.3572 33.329 17.0831 31.9527 17.7932C31.5008 18.0467 31.0124 18.225 30.5059 18.3214C30.2376 18.3657 29.9665 18.3903 29.6947 18.395C29.6898 18.5502 29.6489 18.702 29.5756 18.838C29.4143 19.0833 29.1848 19.2734 28.9169 19.3838C28.6489 19.4941 28.3548 19.5197 28.0725 19.4571C27.3543 19.407 26.6422 19.2896 25.9449 19.1064C25.508 18.9778 25.0563 18.9089 24.6017 18.9015C23.9379 18.9564 23.4263 19.5004 22.8259 20.2061L21.327 22Z"
            fill="#F2F2F2"/>
        <path
            d="M23.8928 79L23.3128 78.611L22.6366 78.694C22.6259 78.6268 22.5891 78.4724 22.5337 78.2307C21.6597 74.3752 20.9935 70.4692 20.5384 66.5319C19.9972 62.0414 19.6953 57.5215 19.6343 52.9943C19.5715 48.4332 19.7733 44.9801 19.935 42.2048C20.0573 40.1116 20.2057 38.1184 20.3513 36.1767C20.7389 30.9893 21.1044 26.0893 20.8324 20.6645C20.7857 18.4254 20.2505 16.2289 19.2695 14.2503C18.4462 12.663 17.3346 11.2678 16 10.1467L16.8526 9C18.329 10.2431 19.5586 11.7889 20.4696 13.5469C21.5525 15.7168 22.145 18.1281 22.1996 20.5873C22.4762 26.1078 22.1067 31.055 21.7151 36.2925C21.5708 38.227 21.4224 40.2116 21.3008 42.2963C21.1405 45.0459 20.9407 48.4689 21.0028 52.9714C21.0627 57.4431 21.3606 61.9076 21.8949 66.3431C22.3435 70.2266 23.0007 74.0792 23.8634 77.8819C24.0224 78.5782 24.0558 78.724 23.8928 79Z"
            fill="#F2F2F2"/>
        <path
            d="M13.007 13C12.9534 13 12.8992 12.9985 12.8443 12.9942C11.7313 12.9256 10.7007 12.1642 9.78052 10.7317C9.34913 10.0578 9.12833 9.2891 8.688 7.75602C8.4114 6.74895 8.21008 5.71687 8.08621 4.6709C7.9541 3.59292 7.9707 3.1422 8.15768 2.74398C8.36683 2.31928 8.68957 1.98238 9.07854 1.78271C9.06083 1.63329 9.07846 1.48117 9.1296 1.34217C9.3523 0.719289 9.94452 0.801011 10.2649 0.840393C10.4576 0.874203 10.6526 0.887873 10.8475 0.88123C11.1358 0.860607 11.4143 0.753432 11.6548 0.570521C11.9934 0.339998 12.3626 0.173973 12.7479 0.0789447C13.4355 -0.0710239 14.1458 -0.00653213 14.8041 0.26565C16.2176 0.803894 16.9144 2.05984 17.7211 3.51414C18.1892 4.39091 18.5515 5.33593 18.7983 6.32356C19.0016 6.91628 19.0526 7.56308 18.945 8.18776C18.7842 8.90106 18.4045 9.30949 17.4403 10.211C16.8271 10.8301 16.1767 11.3994 15.4939 11.9147C14.4837 12.5813 13.8494 13 13.007 13Z"
            fill="#F2F2F2"/>
    </svg>
);