/**
 * This function will camelize string. For example; this is string
 * will be thisIsSting.
 *
 * @param string string to camelize
 * @returns {string}
 */
 export const camelize = (string) => {
    return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
        if (+match === 0) return "";
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}