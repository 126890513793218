/**
 * {
 *      year: '',
 *      title: '',
 *      skills: [],
 *      body: ''
 *  }
 *
 * @type {[{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string},{skills: string[], year: string, title: string, body: string}]}
 */
export const experienceFreelancing = [
    {
        year: '2022',
        title: 'Roda IPShield plugin',
        skills: ['Ruby', 'github workflows', 'RSpec', 'Gem'],
        body: 'Designed Roda plugin that helps secure Roda applications by only allowing authorised IP addresses an access to your app/page/resources. This gem helps defend your Roda application from unwanted requests by only granting authorised IP addresses and access to the app, page, or any resources.'
    },
    {
        year: '2022',
        title: 'Static website for WWICA',
        skills: ['REACT', 'bootstrap', 'jest', 'adobe xd', 'Figma'],
        body: 'Built and maintained the website for West Wales Islamic Cultural Association. The website is used as a hub to keep the community up to date on COVID rules, gatherings, and news.'
    },
    {
        year: '2021',
        title: 'Web application for managing orders and sales',
        skills: ['postgresql', 'React', 'Material UI', 'jest', 'Figma'],
        body: 'I built an internal web application for one of the largest construction companies in Wales to manage their orders and sales and to keep a full record of their inventory. '
    },


];
