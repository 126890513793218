import React from 'react';
import PropTypes from 'prop-types';
import {camelize} from "../helpers/stringHelper";
import {animated, useSpring} from "@react-spring/web";

class ParagraphInput extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isValid: true,
            errorMessage: ''
        }

        this.inputHandler = this.inputHandler.bind(this);
        this.validator = this.validator.bind(this);
        this.input = this.input.bind(this);
        this.label = this.label.bind(this);
        this.error = this.error.bind(this);
    }

    /**
     * This is the input handler for the field. upon
     * calling this function, the input value will be
     * passed to the onComplete function from the props
     *
     * @param value the input value
     */
    inputHandler = (value) => {
        const {onComplete} = this.props;
        onComplete(value);
    }

    /**
     * This is the function validator, use this to validate
     * the user input. The validator will return true if all
     * the validations roles passes
     *
     * @param event
     * @returns {boolean}
     */
    validator = (event) => {
        const {validations} = this.props;
        let isValid = true, errorMessage = '';

        validations.forEach((validator) => {
            let error;
            if ((error = validator(event.target.value)) != null) {
                isValid = false;
                errorMessage = error;
            }
        })

        this.setState({isValid: isValid, errorMessage: errorMessage});
        event.preventDefault();

        return isValid;
    }


    /**
     * This function will render the label for the input
     * filed. The label should be provided in the props
     *
     * @returns {JSX.Element}
     */
    label = () => {
        const {label} = this.props

        return (
            <label htmlFor={camelize(label)} className='text-stone-700'>
                {label}
            </label>
        )
    }


    /**
     * This function is used to display the input filed
     * on the page. The input filed will change it colour
     * to red if the input doesn't match the validations
     *
     * @returns {JSX.Element}
     */
    input = () => {
        const animationInvalid = {
            to: {backgroundColor: 'rgb(248,183,191)'}
        }

        const animationValid = {
            to: {backgroundColor: 'rgb(225,222,220)'}
        }

        const {label, rows, cols} = this.props
        const {isValid} = this.state
        const animation = isValid ? animationValid : animationInvalid

        return (
            <animated.textarea className='p-2 md:p-1 bg-stone-200 rounded-sm text-stone-800
                                        focus:ring focus:ring-sky-600 outline-0'
                               style={{...useSpring(animation)}}
                               rows={rows}
                               cols={cols}
                               name={camelize(label)}
                               id={camelize(label)}
                               onChange={(input) => {
                                   const value = input.target.value;
                                   const isValid = this.validator(input);

                                   this.inputHandler(isValid ? value : null);
                               }}/>
        )
    }

    /**
     * This function will render the error message when the
     * input fails the validations. The error message is
     * animated
     *
     * @returns {JSX.Element}
     */
    error = () => {
        const animationInvalid = {
            from: {opacity: 0, y: -3, display: 'none'},
            to: {opacity: 1, y: 0, display: 'block'},
        }

        const animationValid = {
            from: {opacity: 1, y: 0, display: 'block'},
            to: {opacity: 0, y: -3, display: 'none'},
        }

        const {isValid, errorMessage} = this.state
        const animation = isValid ? animationValid : animationInvalid


        return (
            <animated.span className='text-sm font-bold text-rose-800'
                           style={{...useSpring(animation)}}>
                {errorMessage}
            </animated.span>
        )
    }


    render() {
        return (
            <span className='flex flex-col'>
                <this.label/>
                <this.input/>
                <this.error/>
            </span>
        )
    }
}

ParagraphInput.propTypes = {
    label: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    validations: PropTypes.array,
    onComplete: PropTypes.func
}

export default ParagraphInput;