import axios from 'axios';

export const contactFormSubmissionHelper = async ({
                                                      firstName,
                                                      lastName,
                                                      orgName,
                                                      email,
                                                      phoneNumber,
                                                      message
                                                  }) => {

    const url = 'https://abdullah-janjua-service-jrv2i.ondigitalocean.app/inquiry'
    let response = null;

    const data = {
        "fname": firstName,
        "lname": lastName,
        "org": orgName,
        "email": email,
        "phone": phoneNumber,
        "message": message
    }


    await axios
        .post(url, data)
        .then(res => response = res)
        .catch(error => response = {status: 500, error: error});

    return response;
}