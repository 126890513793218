import {Header} from "./contactFormHelper";
import {animated, useSpring} from "@react-spring/web";

import React from "react";
import {loading} from "../../icons/loading";
import {check} from "../../icons/check";
import {error} from "../../icons/error";


const submittingForm = () =>(
    <div className='w-full h-full flex flex-col justify-center items-center p-5'>
        {loading()}
        <div className='font-bold text-sky-700'>
            Sending your inquiry
        </div>
        <div>
            Please wait
        </div>
    </div>
)


const successfulSubmission = () =>(
    <div className='w-full h-full flex flex-col justify-center items-center p-5'>
        {check()}
        <div className='font-bold text-sky-700'>
            Your message is submitted successfully
        </div>
        <div>
            I will get back to you ASAP
        </div>
    </div>
)


const failedSubmission = () =>(
    <div className='w-full h-full text-red-900 flex flex-col justify-center items-center p-5'>
        {error()}
        <div className='font-bold text-red-500'>
            Oh no! something went wrong
        </div>
        <div>
            Please try again later
        </div>
    </div>
)


const conformationStatus = (status) => {
    switch (status){
        case false:
            return failedSubmission();
        case true:
            return successfulSubmission();
        case null:
        default:
            return submittingForm();
    }
}


/**
 * This function will render the conformation section of the
 * form
 *
 * @param formActive should be true for this section to be open
 * @param formAnimation opening animation when its active
 * @returns {JSX.Element}
 */
export const ConformationRender = (formActive,
                                   formAnimation,
                                   formResponse) => (
    <div className={`bg-stone-50 w-[100%] max-w-sm p-2 border border-stone-700 rounded
            flex flex-col gap-7 ${formActive ? 'shadow' : ''}`}>
        {Header('Confirmation', formActive)}
        <animated.div className='flex-col gap-4'
                      style={useSpring(formAnimation)}>
            {conformationStatus(formResponse)}
        </animated.div>
    </div>
)


