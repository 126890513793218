import React from 'react';
import CircleButton from "../buttons/circleButton";

import {useSpring, animated} from '@react-spring/web'

import {email} from "../icons/email";
import {linkedin} from "../icons/linkedin";
import {github} from "../icons/github";

class CornerMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false
        }

        this.menuOpen = this.menuOpen.bind(this);
        this.menuClosed = this.menuClosed.bind(this);
        this.setMenu = this.setMenu.bind(this);
    }

    /**
     * This is a controller function for closing and opening the menu.
     * this function will set the isMenuOpen state
     *
     * @param isOpen boolean
     */
    setMenu = (isOpen) => {
        this.setState({isMenuOpen: isOpen});
    }

    /**
     * This function will display the menu opened. Note this function
     * is using react-spring for animation
     *
     * @returns {JSX.Element}
     */
    menuOpen = () => {
        const springs = useSpring({
            from: {opacity: 0,  zIndex: 0},
            to: {opacity: 1, zIndex: 100},
        })

        return (
            <animated.div className='absolute right-0 h-[23rem] w-[18rem] rounded-md select-none
                            bg-gradient-to-bl from-sky-900 to-stone-900 shadow mr-[-0.3rem] md:mr-[-1rem] mt-[-0.3rem]'
                          style={{...springs}}>
                <div className='w-full h-full relative z-50'>
                    <div className='absolute right-0 mr-[0.3rem] md:mr-[1rem] mt-[0.3rem]'>
                        <CircleButton
                            title={'menu button'}
                            isSelected={true}
                            onClick={() => this.setMenu(false)}/>
                    </div>

                    <menu className='absolute bottom-0 text-stone-100 h-fit w-full'>
                        <li className='flex w-full border-b border-gray-600 gap-3 p-3'>
                            <div className="grid items-center">{email()}</div>
                            <div className="__email-corner-menu">
                                <span className='block font-extrabold font-sans'>Email</span>
                                <span className='block mt-[-.3rem]'>contact@AbdullahJanjua.com</span>
                            </div>
                        </li>

                        <li className='flex w-full border-b border-gray-600 gap-3 p-3'>
                            <div className="grid items-center">{linkedin()}</div>
                            <div className="__linkedin-corner-menu">
                                <span className='block font-extrabold font-sans'>LinkedIn</span>
                                <span className='block mt-[-.3rem]'>in/abdullah-janjua-aj</span>
                            </div>
                        </li>

                        <li className='flex w-full gap-3 p-3'>
                            <div className="grid items-center">{github()}</div>
                            <div className="__github-corner-menu">
                                <span className='block font-extrabold font-sans'>Github</span>
                                <span className='block mt-[-.3rem]'>DevAbdullahUk</span>
                            </div>
                        </li>
                    </menu>
                </div>
            </animated.div>)
    }

    /**
     * This function will render the menu closed on the screen.
     *
     * @returns {JSX.Element}
     */
    menuClosed = () => (
        <div className='absolute right-0'>
            <CircleButton
                title={'menu button'}
                isSelected={false}
                onClick={() => this.setMenu(true)}/>
        </div>
    )

    render() {
        return (
            <div className='relative z-50'>
                {this.state.isMenuOpen ? (
                    <this.menuOpen></this.menuOpen>
                ) : (
                    <this.menuClosed></this.menuClosed>
                )}
            </div>

        )
    }
}

export default CornerMenu;