import React from 'react';
import GlassDownloadButton from "../buttons/glassButton";
import {myCV} from "../icons/mycv";
import {Flower} from "../icons/flower";
import {cvDownloadLink} from "../helpers/CVDownloadLink";

class CVCard extends React.Component {

    constructor(props) {
        super(props);

        this.cardHeader = this.cardHeader.bind(this);
        this.cardBody = this.cardBody.bind(this);
    }

    /**
     * This function will build the CVCard header h3
     *
     * @returns {JSX.Element}
     */
    cardHeader = () => (
        <h3 className='text-lg font-sans font-bold text-stone-100'>
            Get a PDF copy of my CV <span className='text-sm font-light'>
                - Last Updated: 12/03/2023
            </span>
        </h3>
    )

    /**
     * This function will render the CVCard context - which is a
     * text to remind the user to consider the env before printing
     *
     * @returns {JSX.Element}
     */
    cardBody = () => (
        <div className='text-sky-500'>
            Please consider the environment before printing.
        </div>
    )

    render() {
        return (
            <div className='flex justify-center w-full' id='download-cv'>
                <div className="relative rounded-md select-none bg-gradient-to-bl from-sky-900
            to-stone-900 p-9 w-[90vw] h-[90vh] max-h-[20rem] md:max-h-[100rem] md:w-[80%] md:h-[175px]">
                    <section className='h-full w-[50vw] md:w-full'>
                        <this.cardHeader/>
                        <this.cardBody/>
                    </section>

                    <div className='absolute bottom-5'>
                        <GlassDownloadButton text={'Download'}
                                             fileLink={cvDownloadLink}/>
                    </div>

                    <div className="grid items-center absolute left-[10rem] bottom-0">
                        {Flower()}
                    </div>

                    <div className="grid items-center absolute right-0 bottom-0 md:top-[-26px]">
                        {myCV()}
                    </div>
                </div>
            </div>
        )
    }

}

export default CVCard;