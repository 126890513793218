export const database = () => (
    <svg viewBox="0 0 174 133" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-[30%] md:w-[25%]'>
        <path d="M150.6 113.796L141.101 116.344L150.668 110.461C150.463 101.081 147.189 91.3549 147.189 91.3549C147.189 91.3549 126.741 97.2787 121.374 107.537C116.008 117.795 120.232 124.567 127.754 128.502C135.277 132.438 143.249 132.046 148.616 121.788C149.81 119.505 150.401 116.73 150.6 113.796Z" fill="#8E8E8E"/>
        <path d="M139.284 115.801L139.02 116.307C135.915 122.169 132.525 126.236 128.89 128.447C128.838 128.48 128.784 128.51 128.732 128.543L128.584 128.296L128.444 128.057C132.426 125.699 135.768 121.17 138.477 116.058C138.566 115.894 138.657 115.728 138.742 115.559C139.87 113.385 140.885 111.12 141.788 108.876C141.86 108.703 141.93 108.526 142 108.349C143.177 105.381 144.158 102.47 144.944 99.9071C145 99.7262 145.054 99.544 145.107 99.3642C146.42 95.0202 147.148 91.7571 147.304 91.0321C147.325 90.944 147.335 90.8949 147.337 90.8836L147.611 90.9404L147.614 90.9392L147.89 90.9974C147.887 91.0087 147.855 91.1736 147.787 91.4756C147.536 92.607 146.824 95.6619 145.65 99.5688C145.596 99.7447 145.543 99.927 145.486 100.108C144.85 102.189 144.09 104.488 143.203 106.855C142.981 107.45 142.755 108.033 142.526 108.605C142.459 108.784 142.388 108.96 142.317 109.134C141.341 111.559 140.33 113.782 139.284 115.801Z" fill="#F5F5F5"/>
        <path d="M145.65 99.5687C145.471 99.5035 145.289 99.437 145.107 99.3642C144.613 99.1671 144.129 98.9448 143.657 98.6982C141.646 97.6496 139.896 96.1631 138.536 94.3484C138.362 94.426 138.187 94.5037 138.009 94.5826C139.429 96.5116 141.272 98.0905 143.395 99.1988C143.899 99.462 144.416 99.6984 144.944 99.9071C145.123 99.9786 145.305 100.045 145.486 100.108C146.974 100.633 148.534 100.93 150.111 100.988C150.076 100.794 150.041 100.603 150.008 100.417C148.522 100.35 147.053 100.065 145.65 99.5687Z" fill="#F5F5F5"/>
        <path d="M142.526 108.605C142.348 108.525 142.173 108.44 142 108.349C141.946 108.324 141.891 108.296 141.839 108.268C139.661 107.129 137.792 105.48 136.389 103.462C134.987 101.445 134.093 99.1175 133.784 96.6796C133.607 96.7749 133.429 96.8702 133.253 96.9693C133.603 99.4559 134.541 101.823 135.989 103.875C137.436 105.927 139.352 107.605 141.577 108.769C141.647 108.805 141.717 108.842 141.788 108.876C141.965 108.965 142.14 109.051 142.317 109.134C145.061 110.395 148.111 110.839 151.101 110.413C151.098 110.224 151.095 110.034 151.09 109.843C148.176 110.273 145.199 109.842 142.526 108.605Z" fill="#F5F5F5"/>
        <path d="M139.285 115.801C139.101 115.725 138.921 115.643 138.742 115.559C138.55 115.468 138.36 115.375 138.172 115.277C135.248 113.743 132.9 111.303 131.478 108.322C130.057 105.342 129.639 101.981 130.288 98.7433C130.069 98.8872 129.852 99.0285 129.638 99.1749C129.097 102.461 129.605 105.833 131.09 108.814C132.575 111.794 134.961 114.231 137.91 115.777C138.098 115.876 138.287 115.971 138.477 116.058C138.658 116.146 138.838 116.228 139.02 116.307C142.704 117.882 146.849 117.998 150.615 116.631C150.659 116.418 150.695 116.204 150.732 115.988C147.037 117.416 142.931 117.35 139.285 115.801Z" fill="#F5F5F5"/>
        <path d="M25.8554 114.686L35.3546 117.234L25.7867 111.35C25.9925 101.971 29.2664 92.2446 29.2664 92.2446C29.2664 92.2446 49.7143 98.1684 55.0809 108.426C60.4475 118.684 56.2232 125.456 48.7006 129.392C41.1782 133.327 33.2062 132.936 27.8396 122.678C26.6451 120.395 26.0541 117.62 25.8554 114.686Z" fill="#8E8E8E"/>
        <path d="M37.1706 116.691L37.4352 117.197C40.54 123.058 43.9303 127.125 47.5646 129.337C47.617 129.37 47.6706 129.399 47.723 129.433L47.8714 129.186L48.011 128.947C44.0292 126.589 40.6872 122.06 37.9777 116.948C37.8888 116.784 37.7985 116.618 37.7133 116.449C36.5854 114.274 35.5696 112.01 34.6666 109.766C34.5952 109.593 34.525 109.416 34.4548 109.239C33.2782 106.271 32.2973 103.36 31.511 100.797C31.4546 100.616 31.4008 100.434 31.3482 100.254C30.0352 95.9098 29.3068 92.6467 29.1507 91.9217C29.1301 91.8337 29.1204 91.7846 29.1176 91.7733L28.8445 91.8301L28.8407 91.8289L28.565 91.887C28.5677 91.8983 28.5998 92.0633 28.6686 92.3653C28.9193 93.4966 29.6312 96.5515 30.8048 100.458C30.8586 100.634 30.9125 100.817 30.9689 100.998C31.6048 103.078 32.3647 105.378 33.2522 107.745C33.4742 108.34 33.6997 108.923 33.9288 109.495C33.9965 109.673 34.0667 109.85 34.1382 110.023C35.1139 112.449 36.1248 114.672 37.1706 116.691Z" fill="#F5F5F5"/>
        <path d="M26.4471 101.307C26.4138 101.493 26.3794 101.683 26.3438 101.877C27.9211 101.82 29.4806 101.523 30.9688 100.998C31.1496 100.935 31.3316 100.868 31.511 100.797C32.0394 100.588 32.5563 100.352 33.0598 100.089C35.1835 98.9803 37.0259 97.4014 38.4463 95.4723C38.2679 95.3935 38.0934 95.3158 37.9189 95.2382C36.5587 97.0529 34.8087 98.5393 32.7979 99.5879C32.3263 99.8345 31.8424 100.057 31.3481 100.254C31.1662 100.327 30.9842 100.393 30.8047 100.458C29.4019 100.954 27.9335 101.24 26.4471 101.307Z" fill="#F5F5F5"/>
        <path d="M25.3655 110.733C25.3601 110.924 25.3573 111.113 25.3544 111.303C28.3445 111.729 31.3939 111.285 34.1381 110.023C34.3148 109.94 34.4901 109.855 34.6666 109.766C34.738 109.732 34.8081 109.695 34.8782 109.658C37.1032 108.494 39.0187 106.817 40.4663 104.765C41.9138 102.713 42.8516 100.346 43.202 97.859C43.0259 97.7599 42.8485 97.6646 42.6711 97.5693C42.3622 100.007 41.4684 102.334 40.0659 104.352C38.6634 106.37 36.7937 108.019 34.6163 109.158C34.5637 109.185 34.5086 109.214 34.4548 109.239C34.282 109.329 34.1067 109.415 33.9288 109.495C31.2556 110.732 28.2795 111.162 25.3655 110.733Z" fill="#F5F5F5"/>
        <path d="M25.7234 116.877C25.7603 117.094 25.7958 117.308 25.8401 117.521C29.6063 118.887 33.7511 118.771 37.4351 117.197C37.6169 117.118 37.7973 117.036 37.9777 116.948C38.1681 116.861 38.3572 116.765 38.5449 116.667C41.4938 115.12 43.8802 112.684 45.3652 109.703C46.8503 106.723 47.3583 103.35 46.8172 100.065C46.6035 99.9182 46.3861 99.7769 46.1674 99.6331C46.8159 102.871 46.3982 106.232 44.9769 109.212C43.5556 112.193 41.2071 114.632 38.283 116.167C38.0953 116.265 37.9049 116.358 37.7133 116.449C37.5341 116.533 37.3537 116.614 37.1706 116.691C33.5243 118.239 29.4182 118.306 25.7234 116.877Z" fill="#F5F5F5"/>
        <path d="M70.391 121.869L76.0462 123.386L70.3501 119.883C70.4727 114.299 72.4217 108.509 72.4217 108.509C72.4217 108.509 84.5949 112.036 87.7897 118.142C90.9846 124.249 88.4698 128.281 83.9914 130.624C79.5131 132.967 74.7672 132.734 71.5723 126.627C70.8612 125.268 70.5093 123.616 70.391 121.869Z" fill="#8E8E8E"/>
        <path d="M77.1273 123.062L77.2848 123.364C79.1332 126.853 81.1516 129.274 83.3151 130.591C83.3463 130.611 83.3782 130.628 83.4095 130.648L83.4978 130.501L83.5809 130.359C81.2104 128.955 79.2208 126.259 77.6078 123.215C77.5549 123.118 77.5011 123.019 77.4504 122.918C76.779 121.624 76.1742 120.276 75.6366 118.94C75.5941 118.837 75.5523 118.731 75.5105 118.626C74.8101 116.859 74.2261 115.126 73.758 113.6C73.7245 113.493 73.6924 113.384 73.6611 113.277C72.8794 110.691 72.4458 108.748 72.3529 108.317C72.3406 108.264 72.3348 108.235 72.3332 108.228L72.1706 108.262L72.1683 108.261L72.0042 108.296C72.0058 108.303 72.0249 108.401 72.0658 108.581C72.2151 109.254 72.6389 111.073 73.3376 113.399C73.3696 113.504 73.4017 113.612 73.4353 113.72C73.8138 114.958 74.2662 116.328 74.7946 117.737C74.9267 118.091 75.061 118.438 75.1974 118.779C75.2377 118.885 75.2795 118.99 75.322 119.093C75.9029 120.537 76.5047 121.86 77.1273 123.062Z" fill="#F5F5F5"/>
        <path d="M70.7433 113.904C70.7235 114.015 70.703 114.128 70.6818 114.243C71.6208 114.209 72.5492 114.033 73.4352 113.72C73.5428 113.682 73.6512 113.643 73.758 113.6C74.0725 113.476 74.3803 113.335 74.68 113.179C75.9443 112.519 77.0412 111.579 77.8867 110.43C77.7806 110.383 77.6767 110.337 77.5728 110.291C76.763 111.371 75.7212 112.256 74.5241 112.881C74.2433 113.027 73.9553 113.16 73.661 113.277C73.5527 113.32 73.4444 113.36 73.3375 113.399C72.5024 113.694 71.6282 113.864 70.7433 113.904Z" fill="#F5F5F5"/>
        <path d="M70.0994 119.515C70.0962 119.629 70.0945 119.742 70.0928 119.855C71.8729 120.108 73.6883 119.844 75.322 119.093C75.4272 119.044 75.5315 118.993 75.6366 118.94C75.6791 118.919 75.7208 118.898 75.7626 118.876C77.0872 118.183 78.2276 117.184 79.0893 115.962C79.9511 114.741 80.5094 113.332 80.718 111.851C80.6131 111.792 80.5075 111.736 80.4019 111.679C80.218 113.13 79.6859 114.516 78.8509 115.717C78.016 116.918 76.9029 117.9 75.6067 118.578C75.5754 118.594 75.5426 118.611 75.5105 118.626C75.4077 118.68 75.3033 118.731 75.1974 118.779C73.6059 119.515 71.8342 119.771 70.0994 119.515Z" fill="#F5F5F5"/>
        <path d="M70.3125 123.173C70.3345 123.302 70.3556 123.43 70.382 123.556C72.6241 124.37 75.0916 124.301 77.2848 123.363C77.393 123.316 77.5004 123.268 77.6078 123.215C77.7212 123.164 77.8337 123.107 77.9455 123.048C79.701 122.127 81.1217 120.677 82.0058 118.902C82.8899 117.128 83.1923 115.12 82.8702 113.164C82.743 113.077 82.6136 112.993 82.4834 112.907C82.8694 114.835 82.6208 116.836 81.7746 118.61C80.9285 120.384 79.5304 121.837 77.7896 122.75C77.6778 122.809 77.5645 122.864 77.4504 122.918C77.3437 122.968 77.2363 123.017 77.1273 123.062C74.9566 123.984 72.5121 124.024 70.3125 123.173Z" fill="#F5F5F5"/>
        <path d="M5.18987 78.1443H3.41049V79.6272H5.18987V78.1443Z" fill="#8E8E8E"/>
        <path d="M173.498 79.6272H169.992V78.1443H173.498V79.6272ZM166.485 79.6272H162.979V78.1443H166.485V79.6272ZM159.473 79.6272H155.966V78.1443H159.473V79.6272ZM152.46 79.6272H148.953V78.1443H152.46V79.6272ZM145.447 79.6272H141.941V78.1443H145.447V79.6272ZM138.434 79.6272H134.928V78.1443H138.434L138.434 79.6272ZM131.421 79.6272H127.915V78.1443H131.421V79.6272ZM124.408 79.6272H120.902V78.1443H124.408V79.6272ZM117.395 79.6272H113.889V78.1443H117.395V79.6272ZM110.383 79.6272H106.876V78.1443H110.383L110.383 79.6272ZM103.37 79.6272H99.8633V78.1443H103.37L103.37 79.6272ZM96.357 79.6272H92.8505V78.1443H96.357L96.357 79.6272ZM89.3441 79.6272H85.8377V78.1443H89.3442L89.3441 79.6272ZM82.3312 79.6272H78.8249V78.1443H82.3312L82.3312 79.6272ZM75.3183 79.6272H71.812V78.1443H75.3184L75.3183 79.6272ZM68.3055 79.6272H64.7991V78.1443H68.3056L68.3055 79.6272ZM61.2927 79.6272H57.7862V78.1443H61.2927L61.2927 79.6272ZM54.2798 79.6272H50.7734V78.1443H54.2799L54.2798 79.6272ZM47.2669 79.6272H43.7606V78.1443H47.267L47.2669 79.6272ZM40.2541 79.6272H36.7478V78.1443H40.2541L40.2541 79.6272ZM33.2412 79.6272H29.735V78.1443H33.2413L33.2412 79.6272ZM26.2284 79.6272H22.722V78.1443H26.2285L26.2284 79.6272ZM19.2156 79.6272H15.7092V78.1443H19.2157L19.2156 79.6272ZM12.2027 79.6272H8.69635V78.1443H12.2028L12.2027 79.6272Z" fill="#8E8E8E"/>
        <path d="M5.18987 40.4808H3.41049V41.9637H5.18987V40.4808Z" fill="#8E8E8E"/>
        <path d="M173.498 41.9637H169.992V40.4808H173.498V41.9637ZM166.485 41.9637H162.979V40.4808H166.485V41.9637ZM159.473 41.9637H155.966V40.4808H159.473V41.9637ZM152.46 41.9637H148.953V40.4808H152.46V41.9637ZM145.447 41.9637H141.941V40.4808H145.447V41.9637ZM138.434 41.9637H134.928V40.4808H138.434L138.434 41.9637ZM131.421 41.9637H127.915V40.4808H131.421V41.9637ZM124.408 41.9637H120.902V40.4808H124.408V41.9637ZM117.395 41.9637H113.889V40.4808H117.395V41.9637ZM110.383 41.9637H106.876V40.4808H110.383L110.383 41.9637ZM103.37 41.9637H99.8633V40.4808H103.37L103.37 41.9637ZM96.357 41.9637H92.8505V40.4808H96.357L96.357 41.9637ZM89.3441 41.9637H85.8377V40.4808H89.3442L89.3441 41.9637ZM82.3312 41.9637H78.8249V40.4808H82.3312L82.3312 41.9637ZM75.3183 41.9637H71.812V40.4808H75.3184L75.3183 41.9637ZM68.3055 41.9637H64.7991V40.4808H68.3056L68.3055 41.9637ZM61.2927 41.9637H57.7862V40.4808H61.2927L61.2927 41.9637ZM54.2798 41.9637H50.7734V40.4808H54.2799L54.2798 41.9637ZM47.2669 41.9637H43.7606V40.4808H47.267L47.2669 41.9637ZM40.2541 41.9637H36.7478V40.4808H40.2541L40.2541 41.9637ZM33.2412 41.9637H29.735V40.4808H33.2413L33.2412 41.9637ZM26.2284 41.9637H22.722V40.4808H26.2285L26.2284 41.9637ZM19.2156 41.9637H15.7092V40.4808H19.2157L19.2156 41.9637ZM12.2027 41.9637H8.69635V40.4808H12.2028L12.2027 41.9637Z" fill="#8E8E8E"/>
        <path d="M5.18987 2.81738H3.41049V4.3002H5.18987V2.81738Z" fill="#8E8E8E"/>
        <path d="M173.498 4.3002H169.992V2.81738H173.498V4.3002ZM166.485 4.3002H162.979V2.81738H166.485V4.3002ZM159.473 4.3002H155.966V2.81738H159.473V4.3002ZM152.46 4.3002H148.953V2.81738H152.46V4.3002ZM145.447 4.3002H141.941V2.81738H145.447V4.3002ZM138.434 4.3002H134.928V2.81738H138.434L138.434 4.3002ZM131.421 4.3002H127.915V2.81738H131.421V4.3002ZM124.408 4.3002H120.902V2.81738H124.408V4.3002ZM117.395 4.3002H113.889V2.81738H117.395V4.3002ZM110.383 4.3002H106.876V2.81738H110.383L110.383 4.3002ZM103.37 4.3002H99.8633V2.81738H103.37L103.37 4.3002ZM96.357 4.3002H92.8505V2.81738H96.357L96.357 4.3002ZM89.3441 4.3002H85.8377V2.81738H89.3442L89.3441 4.3002ZM82.3312 4.3002H78.8249V2.81738H82.3312L82.3312 4.3002ZM75.3183 4.3002H71.812V2.81738H75.3184L75.3183 4.3002ZM68.3055 4.3002H64.7991V2.81738H68.3056L68.3055 4.3002ZM61.2927 4.3002H57.7862V2.81738H61.2927L61.2927 4.3002ZM54.2798 4.3002H50.7734V2.81738H54.2799L54.2798 4.3002ZM47.2669 4.3002H43.7606V2.81738H47.267L47.2669 4.3002ZM40.2541 4.3002H36.7478V2.81738H40.2541L40.2541 4.3002ZM33.2412 4.3002H29.735V2.81738H33.2413L33.2412 4.3002ZM26.2284 4.3002H22.722V2.81738H26.2285L26.2284 4.3002ZM19.2156 4.3002H15.7092V2.81738H19.2157L19.2156 4.3002ZM12.2027 4.3002H8.69635V2.81738H12.2028L12.2027 4.3002Z" fill="#8E8E8E"/>
        <path d="M61.3886 78.5892H46.857V131.971H61.3886V78.5892Z" fill="#231C1C"/>
        <path d="M64.0577 78.5892H49.5261V131.971H64.0577V78.5892Z" fill="#0284C7"/>
        <path d="M95.7899 40.9257H81.2583V131.971H95.7899V40.9257Z" fill="#231C1C"/>
        <path d="M98.4589 40.9257H83.9273V131.971H98.4589V40.9257Z" fill="#0284C7"/>
        <path d="M130.191 3.26221H115.66V131.971H130.191V3.26221Z" fill="#231C1C"/>
        <path d="M132.86 3.26221H118.329V131.971H132.86V3.26221Z" fill="#0284C7"/>
        <path d="M3.55876 7.11751C5.5242 7.11751 7.11751 5.5242 7.11751 3.55876C7.11751 1.59331 5.5242 0 3.55876 0C1.59331 0 0 1.59331 0 3.55876C0 5.5242 1.59331 7.11751 3.55876 7.11751Z" fill="#8E8E8E"/>
        <path d="M3.55876 44.781C5.5242 44.781 7.11751 43.1877 7.11751 41.2223C7.11751 39.2568 5.5242 37.6635 3.55876 37.6635C1.59331 37.6635 0 39.2568 0 41.2223C0 43.1877 1.59331 44.781 3.55876 44.781Z" fill="#8E8E8E"/>
        <path d="M3.55876 82.4445C5.5242 82.4445 7.11751 80.8512 7.11751 78.8858C7.11751 76.9203 5.5242 75.327 3.55876 75.327C1.59331 75.327 0 76.9203 0 78.8858C0 80.8512 1.59331 82.4445 3.55876 82.4445Z" fill="#8E8E8E"/>
        <path d="M173.489 131.604H0V132.197H173.489V131.604Z" fill="#231C1C"/>
    </svg>

)