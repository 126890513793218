import {Header, SubmitButtons} from "./contactFormHelper";
import {animated, useSpring} from "@react-spring/web";
import React from "react";
import ParagraphInput from "../../inputs/paragraphInput";
import {paragraphValidation} from "../../validations/paragraphValidation";

/**
 * This function will render Your message section. In the section the user
 * will enter their message or inquiry.
 *
 * @param formActive true if this section is open
 * @param formAnimation the close and open animation
 * @param yourMessageHandler handler when the user clicks on next
 * @param inputHandler user input handler
 * @param errorAnimation error animation when the user click on next
 * @param error true if there is an error
 * @returns {React.JSX.Element} your message section
 * @constructor
 */
export const YourMessageRender = (formActive,
                                  formAnimation,
                                  yourMessageHandler,
                                  inputHandler,
                                  errorAnimation,
                                  error) => (
    <div className={`bg-stone-50 w-[100%] max-w-sm p-3 border border-stone-700 rounded
            flex flex-col gap-7 ${formActive ? 'shadow' : ''}`}>
        {Header('Your message', formActive)}
        <animated.div className='flex-col gap-4'
                      style={useSpring(formAnimation)}>
            <ParagraphInput label={'Your message'}
                            rows={3}
                            cols={5}
                            validations={paragraphValidation}
                            onComplete={(text) => inputHandler({message: text})}/>


            {SubmitButtons(yourMessageHandler,
                'Submit',
                error,
                errorAnimation.open,
                errorAnimation.close
            )}
        </animated.div>
    </div>
)