import {animated, useSpring} from "@react-spring/web";
import {Buttons, Header} from "./contactFormHelper";
import TextInput from "../../inputs/textInput";
import {emailValidation} from "../../validations/emailValidation";
import {phoneNumValidation} from "../../validations/phoneNumValidation";
import React from "react";

/**
 *  This function will render the contact information section. In this section
 *  the user will be asked to enter their email and phone number.
 *
 * @param formActive true if this section is open
 * @param formAnimation the close and open animation
 * @param contactInfoHandler handler when the user clicks on next
 * @param inputHandler user input handler
 * @param errorAnimation error animation when the user click on next
 * @param error true if there is an error
 * @returns {React.JSX.Element} contact form information section
 * @constructor
 */
export const ContactInfoRender = (formActive,
                                  formAnimation,
                                  contactInfoHandler,
                                  inputHandler,
                                  errorAnimation,
                                  error) => (
    <div className={`bg-stone-50 w-[100%] max-w-sm p-2 border border-stone-700 rounded
            flex flex-col gap-7  ${formActive ? 'shadow' : ''}`}>
        {Header('Contact information', formActive)}
        <animated.div className='flex-col gap-4'
                      style={useSpring(formAnimation)}>
            <TextInput label={'Your email'}
                       validations={emailValidation}
                       onComplete={(text) => inputHandler({email: text})}/>

            <TextInput label={'Your phone number'}
                       validations={phoneNumValidation}
                       onComplete={(text) => inputHandler({phoneNumber: text})}/>

            {Buttons(contactInfoHandler,
                'Next',
                error,
                errorAnimation.open,
                errorAnimation.close
            )}
        </animated.div>
    </div>
)