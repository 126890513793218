/**
 * {
 *     header: '',
 *     subHeader: '',
 *     body:''
 * }
 *
 * @type {[{header: string, body: string, subHeader: string},{header: string, body: string, subHeader: string},{header: string, body: string, subHeader: string},{header: string, body: string, subHeader: string}]}
 */
export const awardsList = [
    {
        header: 'DVLA Dynamics Contribution Award',
        subHeader: 'SEPTEMBER 2023',
        body: 'FOR CONTRIBUTION TO DYNAMICS SQUAD AND THE SDET COMMUNITY'
    },
    {
        header: 'DVLA Exceptional Contribution Award',
        subHeader: 'MAY 2022',
        body: 'FOR EXEMPLARY ATTITUDE AND BEHAVIOURS AND CONTRIBUTION TO REAPER AND THUNDER (INTERNAL SQUADS)'
    },
    {
        header: 'DVLA ITS Reward & Recognition Letter',
        subHeader: 'MARCH 2020',
        body: 'CONTRIBUTION TO THE TACHO TEAM WHILST WORKING TOWARDS THE DELIVERY OF THE AGENCY BUSINESS PLAN'
    },
    {
        header: 'College of Engineering - Dean\'s Letter',
        subHeader: 'MARCH 2017',
        body: 'RECOMMENDATION LETTER FROM THE DEAN OF ALFAISAL UNIVERSITY COLLEGE OF ENGINEERING'
    },
    {
        header: 'ACES - IT Manager\'s Letter',
        subHeader: 'AUGUST 2017',
        body: 'RECOMMENDATION LETTER FROM THE IT MANAGER AT ACES - KSA'
    },
]