import React from 'react';
import {useSpring, animated} from "@react-spring/web";
import {arrow} from '../icons/arrow';
import PropTypes from 'prop-types';

class GlassMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
        }

        this.menuOpen = this.menuOpen.bind(this);
        this.setMenu = this.setMenu.bind(this);
    }

    /**
     * This is a controller function for closing and opening the menu.
     * this function will set the isMenuOpen state
     *
     * @param isOpen boolean
     */
    setMenu = (isOpen) => {
        this.setState({isMenuOpen: isOpen});
    }

    /**
     * This function will render the menu. The menu items should be
     * pass on by props, as well as the button test. The first
     * element on the menu is the clos button to close the menu.
     *
     * When clicking on any item on the menu, the menu will call setMenu
     * to close menu, as well as calling the passed on handler from the props
     *
     * @returns {JSX.Element} menu
     */
    menuOpen = () => {
        const menuOpen = useSpring({
            from: {opacity: 0, zIndex: 0, y: -13, x: 100},
            to: {opacity: 1, zIndex: 100, y: 0, x: 100},
        })


        return (
            <animated.div className='absolute backdrop-blur bg-white/30 shadow text-[1rem]
            border-2 border-stone-100 rounded-md flex flex-col text-stone-600 items-center'
                          style={{...menuOpen}}>
                <span className='p-[0.1rem] pl-3 w-full bg-sky-200 hover:text-sky-600 rounded-md cursor-pointer
                font-bold flex items-center'
                      onClick={() => this.setMenu(false)}>
                   <span className='rotate-90 p-2'>{arrow(true)} </span>
                    <span>Close</span>
                </span>

                {this.props.items.map((item) => {
                    return (
                        <>
                            <hr className='w-11/12' key={`${item}-line`}/>
                            <span key={item}
                                  className='p-3 hover:bg-white/90 hover:text-sky-600
                                  rounded-md cursor-pointer w-full'
                                  onClick={() => {
                                      this.setMenu(false);
                                      this.props.onClick(item);
                                  }}>
                            {item}
                            </span>
                        </>
                    )
                })}

            </animated.div>
        );
    }

    render() {
        const {isMenuOpen} = this.state;

        return (
            <>
                <span className='flex items-center gap-2'>
                    <button className='text-sky-600 underline underline-offset-1'
                            title={'Dropdown Menu button'}
                            onClick={() => this.setMenu(!isMenuOpen)}>
                    {this.props.text}
                    </button>
                    <div className='pt-1' onClick={() => this.setMenu(!isMenuOpen)}>
                        {arrow(true)}
                    </div>
                </span>

                {isMenuOpen ? (
                    <this.menuOpen/>
                ) : null}
            </>
        )
    }
}

GlassMenu.propTypes = {
    text: PropTypes.string,
    items: PropTypes.array,
    onClick: PropTypes.func
};

export default GlassMenu;